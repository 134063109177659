import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

const Main = ({content, closePopup}) => {
    const { t } = useTranslation();
    return (
        <div className={`${content[0].Format === 1 ? 'main-popup':'main-popup-text'}`} style={content[0].Format === 1 ? {backgroundColor:'transparent'} : {}}>
            <div className='main-popup-header d-flex justify-content-between' style={{backgroundColor:'transparent',position:'relative'}}>
                {/* <p>{content[0].HeaderTitle}</p> */}
                <p className="modal-title">&nbsp;</p>
                {
                    content[0].RequireClose === 0 ? <></> : 
                    <button type="button" className="close popup-notice-close-btn" onClick={() => closePopup()} data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                }
            </div>
            {
                content[0].Format === 1 ?
                <div className='main-popup-content-with-image flex-column'>
                    <img className="img-fluid" src={process.env.REACT_APP_S3+"/"+content[0].ImageDesktopPath} />
                    <div className='popup-button main-popup-button-with-image'>
                    { content[0].RequireConfirm === 0 ? <></> :   <button className='btn btn-secondary mb-0' onClick={() => closePopup()}>{t('confirm')}</button>  }
                    </div>
                </div>
                :
                <div className='main-popup-content flex-column' style={{backgroundColor:'var(--color-14)'}}>
                    <div className='popup-text'>
                        { ReactHtmlParser(content[0].Content)}
                    </div>
                    <div className='popup-button'>
                    { content[0].RequireConfirm === 0 ? <></> : <button className='btn btn-secondary mb-0' onClick={() => closePopup()}>{t('confirm')}</button>  }
                    </div>
                </div>
            }
        </div>
    )
}

export default Main;