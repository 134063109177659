import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducers";
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage fo
import rootSaga from './sagas';

const inititalState = {};
const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["session", "errors", "sports", "banners", "promotions", "bonuses", "historyes", "jackpot", "payment", "rebate", "modal", "landingpage", "betconstruct"]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  inititalState,
  compose(
      applyMiddleware(sagaMiddleware)
  )
);

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export { store, persistor };
