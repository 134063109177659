import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { loadCasinoGames, setLiveCasinoSelectedProvider } from '../../../actions/casino';
import { getCategories } from '../../../api/casino';
import Title from './Title';
// import Filter from './Filter';
import Thumb from './Thumb';
import Loading from './Loading';
import asiaGamingLogo from '../../../assets/img/w365/live-casino/asia-gaming-logo.svg';
import evolutionLogo from '../../../assets/img/w365/live-casino/evolution-logo.svg';
import microGamingLogo from '../../../assets/img/w365/live-casino/microgaming-logo.svg';
import pragmaticPlayLogo from '../../../assets/img/w365/live-casino/pragmatic-play-logo.svg';
import botaLogo from '../../../assets/img/w365/live-casino/bota-casino-logo.svg';
import Error from "../../../errors";
import { postPlay, postSports, sportsClear } from "../../../actions/sports";
import BlackListedError from '../../common/BlackListedError';

const LiveCasino = (props) => {
    const { t } = props;
    const dispatch = useDispatch();
    const itemsPerPage = 18;
    let lang = [];
    const auth = useSelector(state => state.auth);
    const brandGameSetup = useSelector((state) => state.page.brandGameSetup);
    const selectedProvider = useSelector(state => state.casino.selectedProvider);
    const tabCategories = [
        {
            label: t('all'),
            value: 'all',
            translationCode: 'all live casino games'
        },
        {
            label: t('baccarat'),
            value: 'baccarat',
            translationCode: 'all baccarat games'
        },
        {
            label: t('blackjack'),
            value: 'blackjack',
            translationCode: 'all black jack games'
        },
        {
            label: t('dragon tiger'),
            value: 'dragon_tiger',
            translationCode: 'all dragon tiger games'
        },
        {
            label: t('game show'),
            value: 'game_show',
            translationCode: 'all game show games'
        },
        {
            label: t('roulette'),
            value: 'roulette',
            translationCode: 'all roulette games'
        },
        {
            label: t('live holdem'),
            value: 'poker',
            translationCode: 'all poker games'
        },
        {
            label: t('sicbo'),
            value: 'sicbo',
            translationCode: 'all sic bo games'
        },
        {
            label: t('fantan'),
            value: 'fantan',
            translationCode: 'all fan tan games'
        }
    ];

    if(localStorage.getItem('i18nextLng') === null) {
        lang = ['en'];
    } else {
        lang = localStorage.getItem('i18nextLng').match(/^[a-z]+/);
    }

    const [filterDefaults, setFilterDefaults] = useState({
        name: '',
        //provider: lang[0] === 'en' ? 'all providers' : '전체 게임사',
        //game: lang[0] === 'en' ? 'all games' : '전체 게임',
        category: lang[0] === 'en' ? 'all categories' : '전체 카테고리'
    });
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [games, setGames] = useState([]);
    const [filteredSlots, setFilteredSlots] = useState([]);
    const [displayList, setDisplayList] = useState([]);
    const [topPicks, setTopPicks] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState(filterDefaults);
    const [filterOptions, setFilterOptions] = useState({
        //providers: [filterDefaults.provider],
        //games: [filterDefaults.game, 'new', 'top', 'featured'],
        categories: [filterDefaults.category]
    });
    const [liveCasinoProviders, setLiveCasinoProviders] = useState([]);
    const [activeCategory, setActiveCategory] = useState(tabCategories[0]);
    const [activeProvider, setActiveProvider] = useState(null);

    const notFiltered = () => {
        return selectedFilters.name === filterDefaults.name &&
        selectedFilters.provider === filterDefaults.provider &&
        selectedFilters.game === filterDefaults.game &&
        selectedFilters.category === filterDefaults.category;
    }

    const sportsState = useSelector(state => state.sports);
    const playerState = useSelector(state => state.auth);
    const [success, setSuccess] = useState(true);
    const [launchCode, setLaunchCode] = useState("");
    const [blacklistedError, setBlacklistedError] = useState(false);
    const [errorStatusCode, setErrorStatusCode] = useState(200);
    const [selectBota, setSelectBota] = useState(false);

    useEffect(() => {
        dispatch(sportsClear());
        setSuccess(true);
    }, []);

    useEffect(() => {
        // if(launchCode === "") {
        //     if (localStorage.getItem("appToken")) {
        //         dispatch(postPlay({ providerID: 25, integrationID: 51 }));
        //     } else {
        //         dispatch(postSports({ providerID: 25, integrationID: 51}));
        //     }
        // }
    }, [playerState]);

    useEffect(() => {
        if(sportsState.result.success !== undefined) {
            setSuccess(sportsState.result.success);
        }
    }, [sportsState.result.success]);

    useEffect(() => {
        if(selectedProvider.providerID === 31) {
            if(selectBota) {
                if(sportsState.result.success) {
                    window.open(sportsState.result.url, '_blank');
                }
                setSelectBota(false);
            }
        }
    }, [sportsState]);

    useEffect(() => {

        if(sportsState.errors) {
            if(sportsState.errors.player_blacklisted || sportsState.errors.player_closed) {
                setSuccess(false)
                setBlacklistedError(true);
                setErrorStatusCode(sportsState.errors.status)
            }
            
        } else {
            setSuccess(true)
        }
    }, [sportsState.errors]);

    // const scrollListener = () => {
    //     const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    //     const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    //     const scrollValue = winScroll / height;

    //     if(scrollValue >= 1) {
    //         setLoadMoreLoading(true);

    //         setTimeout(() => {
    //             setLoadMoreLoading(false);
    //             document.getElementById('loadMore').click();
    //         }, 700);
    //     }
    // }

    useEffect(() => {
        getCategories({product: 2}).then(response => {
            const fetchedGames = response.data.data;

            setGames(fetchedGames);
            dispatch(loadCasinoGames(fetchedGames));
            setDisplayList(fetchedGames.slice(0, itemsPerPage));
            setLoading(false);
            setFilteredSlots(fetchedGames);
        });
    }, []);

    useEffect(() => {
        let increments = {
            providers: 0,
            categories: 0,
            topPicks: 0
        };
        let providers = [];
        let categories = [];
        let top_picks = [];
        let x = 0;

        if(games.length > 0) {
            for(x in games) {
                if(!providers.includes(games[x].Provider)) {
                    providers[increments.providers] = games[x].Provider;
                    increments.providers++;
                }

                if(!categories.includes(games[x].Type)) {
                    categories[increments.categories] = games[x].Type;
                    increments.categories++;
                }

                if(games[x].NewGame || games[x].Featured || games[x].MonthlyTopGame) {
                    top_picks[increments.topPicks] = games[x];
                    increments.topPicks++;
                }
            }
        }

        //let options = {...filterOptions};

        //options.providers = [...options.providers, ...providers];
        //options.categories = [...options.categories, ...categories];
        //setFilterOptions(options);
        setTopPicks(top_picks);
    }, [games]);

    useEffect(() => {
        window['slotsApplyFilterDropdownStyling']();
    }, [filterOptions]);

    useEffect( () => {
        if (brandGameSetup !== undefined && brandGameSetup.length > 0) {
            
            let x = 0;
            let providers = brandGameSetup.filter(el => el.ProductID === 2)

            providers.sort((a,b) => (a.ProviderName > b.ProviderName) ? 1 : ((b.ProviderName > a.ProviderName) ? -1 : 0));

            if (providers.length === 0) {
                window.location = '/'
            } else {
                for(x in providers) {
                    let image = '';

                    switch(providers[x].ProviderID) {
                        case 3:
                            image = asiaGamingLogo;
                        break;
                        case 9:
                            image = microGamingLogo;
                        break;
                        case 15:
                            image = evolutionLogo;
                        break;
                        case 29:
                            image = pragmaticPlayLogo;
                        break;
                        case 31:
                            image = botaLogo;
                        break;
                        default:
                            image = '';
                    }

                    providers[x].Image = image;
                }

                setLiveCasinoProviders(providers);
            }
        }
    },[brandGameSetup])

    const loadMore = () => {
        setLoadMoreLoading(true);

        setTimeout(() => {
            let gamesCopy = [];
            const newPage = page + 1;
    
            if(notFiltered()) {
                    gamesCopy = [...games];
            } else {
                gamesCopy = [...filteredSlots];
            }
    
            setDisplayList(gamesCopy.slice(0, itemsPerPage * newPage));
            setPage(newPage);
            setLoadMoreLoading(false);
        }, 700);
    }

    // const filter = (e, index) => {
    //     let selected_filters = {...selectedFilters};
    //     selected_filters[index] = e.target.value;
    //     setSelectedFilters(selected_filters);
    // }

    useEffect(() => {
        let gamesCopy = [...games];

        if(selectedFilters.name === filterDefaults.name) {
                setFilteredSlots([]);
                setDisplayList(gamesCopy);
                setPage(1);
        }

        const namePattern = new RegExp(selectedFilters.name, 'i');

        if(selectedFilters.name !== filterDefaults.name) {
            gamesCopy = gamesCopy.filter(game => namePattern.test(game.GameTypeName));
        }

        // if(selectedFilters.provider !== filterDefaults.provider) {
        //     gamesCopy = gamesCopy.filter(slot => slot.Provider === selectedFilters.provider);
        // }

        // if(selectedFilters.category !== filterDefaults.category) {
        //     gamesCopy = gamesCopy.filter(slot => slot.Type === selectedFilters.category);
        // }

        // if(selectedFilters.game !== filterDefaults.game) {
        //     let index = '';

        //     switch(selectedFilters.game) {
        //         case 'new':
        //             index = 'NewGame';
        //         break;
        //         case 'top':
        //             index = 'MonthlyTopGame';
        //         break;
        //         case 'featured':
        //             index = 'Featured';
        //         break;
        //     }

        //     gamesCopy = gamesCopy.filter(slot => slot[index] === 1);
        // }

        setFilteredSlots(gamesCopy);
        setDisplayList(gamesCopy.slice(0, itemsPerPage));
        window['generateTopPicksPrevNextButtons'](topPicks.length);
    }, [selectedFilters]);

    useEffect(() => {
        window['generateTopPicksPrevNextButtons'](topPicks.length);
    }, [topPicks]);

    useEffect(() => {
        let gamesCopy = [...games];

        if(activeCategory.value === 'all') {
            if(activeProvider !== null) {
                gamesCopy = gamesCopy.filter(game => game.ProviderID === activeProvider);
            }

            setDisplayList(gamesCopy.slice(0, itemsPerPage));
            window['generateTopPicksPrevNextButtons'](topPicks.length);
        } else {
            const namePattern = new RegExp(activeCategory.value, 'i');
            gamesCopy = gamesCopy.filter(game => namePattern.test(game.CategoryCode));

            if(activeProvider !== null) {
                gamesCopy = gamesCopy.filter(game => game.ProviderID === activeProvider);
            }

            setDisplayList(gamesCopy.slice(0, itemsPerPage));
        }

        setPage(1);
        setFilteredSlots(gamesCopy);
    }, [activeCategory, activeProvider]);

    const setSelectedProvider = (e, data) => {
        const token = localStorage.getItem('appToken');

        if(token !== null) {
            let lang = localStorage.getItem('i18nextLng').split('-');
    
            if(lang[0] === 'ko') {
                lang = lang[1].toLowerCase();
            } else {
                lang = lang[0];
            }
    
            if(auth.wallet.player.PlayerID === undefined) {
                document.getElementById('login-btn').click();
            } else {
                if(data.ProviderID === 31) {
                    const payload = {
                        brandID: 5,
                        currency: auth.wallet.player.currency.Abbreviation,
                        gameType: 'top_games',
                        gameID: 'top_games',
                        hashid: '',
                        integrationID: data.IntegrationID,
                        lang: lang,
                        launchType: 'html5',
                        playerID: auth.wallet.player.PlayerID,
                        product: 2,
                        providerID: data.ProviderID,
                        username: auth.wallet.username
                    };
                    dispatch(postPlay(payload));
                    dispatch(setLiveCasinoSelectedProvider(payload));
                    setSelectBota(true);
                } else {
                    const payload = {
                        brandID: 5,
                        currency: auth.wallet.player.currency.Abbreviation,
                        gameType: 'top_games',
                        gameID: 'top_games',
                        hashid: '',
                        integrationID: data.IntegrationID,
                        lang: lang,
                        launchType: 'html5',
                        playerID: auth.wallet.player.PlayerID,
                        product: 2,
                        provider: data.ProviderID,
                        username: auth.wallet.username
                    };
            
                    dispatch(setLiveCasinoSelectedProvider(payload));
                    window.open('/live-casino/play/gameLobby', '_blank');
                }
            }
        } else {
            if(data.ProviderID === activeProvider) {
                setActiveProvider(null);
            } else {
                setActiveProvider(data.ProviderID);
            }
        }

        e.preventDefault();
    }

    const selectActiveCategory = (e, category) => {
        setActiveCategory(category);
        e.preventDefault();
    }

    return (
        <Error>
            {
                success ? 
                <div className="slot-section main-container">
                    <section id="featured">
                        <a className="slide-img" tabIndex={0}><img src="/assets/img/w365/banners/live-casino.jpg" className="img-fluid" alt="" /></a>
                    </section>
                    <Container className="Slot-target live-casino-container" fluid>
                        {/* <Row className="mb-5">
                            <Col className="d-flex align-items-center"><h2 className="form-title page-title">{t('live casino')}</h2></Col>
                            <Col>
                                <form onSubmit={e => e.preventDefault()}>
                                    <Row className="live-casino-filter">
                                        <Col className="p-0 d-flex justify-content-end">
                                            <div className="searchbar">
                                                <div className="input-group form-sm form-2 search-filter">
                                                    <input onChange={e => filter(e, 'name')} className="form-control my-0 py-1 amber-border  custom-selectbox dark" type="text" placeholder={t('search for games')} aria-label="Search" />
                                                    <div className="input-group-append">
                                                        <button className="search-btn"> <i className="fas fa-search text-grey"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row> */}
                        <Row className="my-5">
                            <Col lg="12">
                                <div className="tabbed-list">
                                    <div className={`inner${liveCasinoProviders.length > 9 ? ' more' : ''}`}>
                                        {liveCasinoProviders.sort((a, b) => {
                                            return (a.SortOrder < b.SortOrder) ? -1 : 1 
                                        }).map(p => <div key={`provider-${p.ProviderName}`} className={`provider-logo ${p.ProviderName.replace(' ', '').toLowerCase()}`} style={{height: '38px'}}><a href="#" className={activeProvider === p.ProviderID ? 'active' : ''} onClick={(e) => setSelectedProvider(e,p)} style={{padding: 0}}><img src={p.Image} alt="" style={{height: '100%'}} /></a></div>)}
                                    </div>
                                </div>
                            </Col>
                            <Col lg="12">
                                <div className={`tabbed-list${tabCategories.length > 9 ? ' py-0' : ''}`}>
                                    <div className={`inner text${tabCategories.length > 9 ? ' more' : ''}`}>
                                        {tabCategories.map(category => <div key={`tabCategory-${category.value}`}><a onClick={(e) => selectActiveCategory(e, category)} href="#" className={category.value === activeCategory.value ? 'active' : ''}>{category.label}</a></div>)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {activeCategory.value === 'all' && activeProvider === null ?
                    <section className="mb-5" style={{backgroundColor:'var(--color-48)',padding:'30px 0'}}>
                        <Container className="Slot-target live-casino-container" fluid>
                            <Row>
                                <Col lg="6"><h2 className="form-title ml-2" style={{marginTop:0,marginBottom:'0.5rem'}}>{t('top picks')}</h2></Col>
                                <Col lg="6" className="d-flex align-items-center">
                                    <div className="d-flex justify-content-end w-100 mr-2">
                                        <div>
                                            <img className="top-picks-button d-none" src="/assets/img/toppicks-arrow-left.svg" alt="" />&nbsp;&nbsp;
                                            <img className="top-picks-button d-none" src="/assets/img/toppicks-arrow-right.svg" alt="" />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{overflow:'hidden'}}>
                                        <div className="d-flex w-100 top-picks">
                                            {loading ? <Loading /> : <>{topPicks.length > 0 ? topPicks.map(topPick => <Thumb key={topPick.Name} {...topPick} />) : <Col>{t('nothing to display')}</Col>}</>}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section> : null}
                    <section className="slot-container">
                        <Container className="Slot-target live-casino-container" fluid>
                            <Row>
                                <Col><h2 className="form-title ml-2" style={{marginTop:0,marginBottom:'0.5rem'}}>{t(activeCategory.translationCode)}</h2></Col>
                            </Row>
                            <Row style={{margin:'0px 0px 50px 0px'}}>
                                {loading ? <Loading /> : <>{displayList.length > 0 ? displayList.map(slot => slot !== null ? <Thumb key={slot.Name} {...slot} /> : null) : <Col>{t('nothing to display')}</Col>}</>}
                            </Row>
                            <Loading display={loadMoreLoading ? 'block' : 'none'} />
                            {/* {!loadMoreLoading && ((notFiltered() && displayList.length < games.length) || (!notFiltered() && displayList.length < filteredSlots.length)) ? <div className="d-flex justify-content-center"><button onClick={loadMore} id="loadMore" className="btn btn-success btn-sm">{t('load more')}</button></div> : null} */}
                            {!loadMoreLoading && displayList.length < filteredSlots.length ? <div className="d-flex justify-content-center"><button onClick={loadMore} id="loadMore" className="btn btn-secondary load-more-btn btn-sm">{t('load more')}</button></div> : null}
                        </Container>
                    </section>
                </div>
                : 
                <>
                    <div className="blacklisted blacklisted-error"><BlackListedError errorStatusCode={errorStatusCode}/></div>
                </>
            }
        </Error>
    );
}

export default withTranslation()(LiveCasino);
