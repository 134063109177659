import React, { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useSelector } from 'react-redux';
import { getBonusHistory, setHistoryPage } from '../../../../actions/bonus';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paginate } from '../../../common';
import { displayAmount } from '../../../../helpers';
import { player } from '../../../../selectors/auth';
import ReactPaginate from "react-paginate";
import ReactHtmlParser from "react-html-parser";
import Providers from '../modals/Providers';

const Content = () => {
    const dispatch = useDispatch();
    const { bonuses } = useSelector(state => state.historyes);
    const { currency: { Abbreviation } } = useSelector(player);
    const options = { ...bonuses.meta, ...bonuses.links }

    const [showModal, toggleModal] = useState(false);
    const [playerBonusList, setPlayerBonusList] = useState([]);
    const [providerList, setProviderList] = useState([]);

    const handleToggleModal = (provider) => {
        toggleModal(!showModal);
        setProviderList(provider);
    }

    const handleClose = () => {
        toggleModal(!showModal);
        setProviderList([]);
    }

    useEffect(() => {
        if(bonuses.data.length !== 0){
            bonuses.data.map( (promotion, i) =>  {  
                let products = [...new Map(promotion.Products.map(item =>
                  [item.Data['ID'], item])).values()];
                  bonuses.data[i].FilteredProduct = products;
              });
              setPlayerBonusList(bonuses.data)
        } else {
            setPlayerBonusList(bonuses.data)
        }
    },[bonuses])

    useEffect(() => {
        dispatch(getBonusHistory());

        return () => {
            dispatch(setHistoryPage({page: 1}));
        }
    }, []);

    const handlePageClick = (e) => {
        dispatch(setHistoryPage({page: e.selected + 1}))
    }

    const { t } = useTranslation();

    return (
        <div className="row mt-4">
            <Providers show={showModal} handleClose={handleClose} provider={providerList}/>
            <div className="col-lg-12">
                <table className="table table-striped table-dark">
                    <thead>
                    <tr>
                        <th scope="col">{t('date')}</th>
                        <th scope="col">{t('subscription id')}</th>
                        <th scope="col">{t('bonus')} / {t('coupon details')}</th>
                        {/* <th scope="col">{t('provider')}</th> */}
                        <th scope="col" className="txt-align-right">{t('amount')}</th>
                        <th scope="col">{t('date completed')}</th>
                        <th scope="col">{t('goal')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        bonuses.data.map((bonus, key) => {
                            return (
                                <>
                                <tr key={key}>
                                    <td className="no-border">{bonus.Date}</td>
                                    <td className="no-border">{bonus.SubscriptionID}</td>
                                    <td className="no-border">
                                        <div>{bonus.Code}</div>
                                        <div className="subscription-type mr-2">{t(bonus.Type.toLowerCase())}</div>
                                        { bonus.MinimumOdds ? <div className="min-odds mr-2"><span>{t('min odds')+': '}</span><span className='value'>{bonus.MinimumOdds}</span></div> : <></>}
                                        <div className="bonus-providers" onClick={() => handleToggleModal(bonus)}>{t('providers')+ReactHtmlParser('&nbsp;&nbsp;')+'>'}</div>
                                    </td>
                                    {/* <td>{bonus.ProviderName}</td> */}
                                    <td className="txt-align-right no-border">{Abbreviation} {displayAmount(bonus.WageringGoal)}</td>
                                    <td className="no-border">
                                        {/* { bonus.Status === "Complete" ?
                                                    <span className="green">
                                                        {bonus.DateCompleted}
                                                    </span>
                                                        :
                                                    <span className={classname("",{
                                                        "red":
                                                        [
                                                            "Expired",
                                                            "Complete",
                                                            "Forfeited",
                                                            "Force To Serve",
                                                            "Player Canceled",
                                                            "System Cancelled"
                                                        ].
                                                        includes(bonus.Status)
                                                    })}>
                                                    {t(bonus.Status.toLowerCase())}
                                                </span>
                                        } */}
                                        { bonus.Status === "Complete" ? bonus.DateCompleted : <div className="force-to-serve">{t(bonus.Status.toLowerCase())}</div> }
                                    </td>
                                    <td width="30%" className="no-border">
                                        { bonus.SubTypeID === 15 ?
                                            <></>
                                            :
                                            <div className="progress progress-striped active">
                                                <p className="raised"><strong>{t('current')}: {displayAmount(bonus.WageringAmount)}</strong></p>
                                                <p className="goal"><strong>{t('goal')}: {displayAmount(bonus.WageringGoal)}</strong></p>
                                                <div className="progress-bar barColor progress-bar progress-bar-animated" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: `${bonus.WageringAmount/bonus.WageringGoal*100}%`}}>
                                                    <span className="sr-only">0% {t('complete')}</span>
                                                </div>
                                            </div>
                                        }
                                    </td>
                                </tr>
                                {/* <tr key={key} className={`${key % 2 === 0 ? "tableBG-1":"tableBG-2"} `}>
                                    <td colSpan={2} className="no-border"><span>{t('wager requirements')}</span></td>
                                    <td colSpan={2} className="no-border">
                                        <div className="progress progress-striped active">
                                            <p className="raised"><strong>{t('current')}: {displayAmount(bonus.WageringAmount)}</strong></p>
                                            <p className="goal"><strong>{t('goal')}: {displayAmount(bonus.WageringGoal)}</strong></p>
                                            <div className="progress-bar barColor progress-bar progress-bar-animated" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: `${bonus.WageringAmount/bonus.WageringGoal*100}%`}}>
                                                <span className="sr-only">0% {t('complete')}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center no-border"></td>
                                </tr> */}
                                </>
                            )

                        })
                    }
                    <tr className={classname("",{
                            "d-none": bonuses.data.length > 0
                        })}>
                            <td colSpan="12" className="text-center">
                                <h4>{t('no results found')}</h4>
                            </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {/* <Paginate options={options} selectPage={(page) => {
                dispatch(setHistoryPage({page}));
            }
            }/> */}
            <div className="col-md-12 pagination-col mt-3">
                <div className="pagination-box">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        initialPage={0}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={3}
                        pageCount={bonuses.meta.last_page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination-w365"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active pagination-a"
                    />
                </div>
            </div>
        </div>
    );
};

export default Content;
