import React from 'react';
import { withTranslation } from 'react-i18next';

const RollingBonus = (props) => {
    const { t } = props;

    return (
        <div id="vip-club-rebate-table">
            <h2 className="form-title">sports betting rebate (including e-sports)</h2>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits">
                    <thead>
                        <tr>
                            <th colSpan="9" className="border-top-width-0 border-left-width-0 border-right-width-0"><span className="bold-text">BTI Sports Rebate</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan="2" width="20%" className="bold-text border-left-width-0">MULTI</td>
                            <td colSpan="2" className="bold-text">1x</td>
                            <td colSpan="2" className="bold-text">2x</td>
                            <td colSpan="2" className="bold-text border-right-width-0">3x</td>
                        </tr>
                        <tr>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td className="border-right-width-0">{'>=Odds'}</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bronze bold-text border-left-width-0">Bronze</td>
                            <td colSpan={6} className="border-right-width-0">Welcome Bonus</td>
                        </tr>
                        <tr className="level-color">
                            <td className="silver bold-text border-left-width-0">Silver</td>
                            <td>0.3%</td>
                            <td>1.65</td>
                            <td>0.5%</td>
                            <td>3.30</td>
                            <td>1.5%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="gold bold-text border-left-width-0">Gold</td>
                            <td>0.5%</td>
                            <td>1.65</td>
                            <td>0.7%</td>
                            <td>3.30</td>
                            <td>2.0%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="diamond bold-text border-left-width-0 border-bottom-width-0">Diamond</td>
                            <td className="border-bottom-width-0">0.7%</td>
                            <td className="border-bottom-width-0">1.65</td>
                            <td className="border-bottom-width-0">1.0%</td>
                            <td className="border-bottom-width-0">3.30</td>
                            <td className="border-bottom-width-0">2.5%</td>
                            <td className="border-bottom-width-0 border-right-width-0">5.50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits">
                    <thead>
                        <tr>
                            <th colSpan="9" className="border-top-width-0 border-left-width-0 border-right-width-0"><span className="bold-text">BRD Sports Rebate</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan="2" width="20%" className="bold-text border-left-width-0">MULTI</td>
                            <td colSpan="2" className="bold-text">1x</td>
                            <td colSpan="2" className="bold-text">2x</td>
                            <td colSpan="2" className="bold-text border-right-width-0">3x</td>
                        </tr>
                        <tr>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td className="border-right-width-0">{'>=Odds'}</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bronze bold-text border-left-width-0">Bronze</td>
                            <td colSpan={6} className="border-right-width-0">Welcome Bonus</td>
                        </tr>
                        <tr className="level-color">
                            <td className="silver bold-text border-left-width-0">Silver</td>
                            <td>0.5%</td>
                            <td>1.65</td>
                            <td>1.0%</td>
                            <td>3.30</td>
                            <td>2.5%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="gold bold-text border-left-width-0">Gold</td>
                            <td>0.7%</td>
                            <td>1.65</td>
                            <td>1.2%</td>
                            <td>3.30</td>
                            <td>3.5%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="diamond bold-text border-left-width-0 border-bottom-width-0">Diamond</td>
                            <td className="border-bottom-width-0">1.0%</td>
                            <td className="border-bottom-width-0">1.65</td>
                            <td className="border-bottom-width-0">1.5%</td>
                            <td className="border-bottom-width-0">3.30</td>
                            <td className="border-bottom-width-0">4.5%</td>
                            <td className="border-bottom-width-0 border-right-width-0">5.50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits">
                    <thead>
                        <tr>
                            <th colSpan="9" className="border-top-width-0 border-left-width-0 border-right-width-0"><span className="bold-text">E Sports Rebate</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td rowSpan="2" width="20%" className="bold-text border-left-width-0">MULTI</td>
                            <td colSpan="2" className="bold-text">1x</td>
                            <td colSpan="2" className="bold-text">2x</td>
                            <td colSpan="2" className="bold-text border-right-width-0">3x</td>
                        </tr>
                        <tr>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td>{'>=Odds'}</td>
                            <td>Rebate%</td>
                            <td className="border-right-width-0">{'>=Odds'}</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bronze bold-text border-left-width-0">Bronze</td>
                            <td colSpan={6} className="border-right-width-0">Welcome Bonus</td>
                        </tr>
                        <tr className="level-color">
                            <td className="silver bold-text border-left-width-0">Silver</td>
                            <td>0.5%</td>
                            <td>1.65</td>
                            <td>1.0%</td>
                            <td>3.30</td>
                            <td>2.5%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="gold bold-text border-left-width-0">Gold</td>
                            <td>0.7%</td>
                            <td>1.65</td>
                            <td>1.2%</td>
                            <td>3.30</td>
                            <td>3.5%</td>
                            <td className="border-right-width-0">5.50</td>
                        </tr>
                        <tr className="level-color">
                            <td className="diamond bold-text border-left-width-0 border-bottom-width-0">Diamond</td>
                            <td className="border-bottom-width-0">1.0%</td>
                            <td className="border-bottom-width-0">1.65</td>
                            <td className="border-bottom-width-0">1.5%</td>
                            <td className="border-bottom-width-0">3.30</td>
                            <td className="border-bottom-width-0">4.5%</td>
                            <td className="border-right-width-0 border-bottom-width-0">5.50</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits">
                    <thead>
                        <tr>
                            <th colSpan="9" className="border-top-width-0 border-left-width-0 border-right-width-0"><span className="bold-text">Other games Rebate</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="20%" className="font-bold border-left-width-0">VIP</td>
                            <td>Live Casino</td>
                            <td>TV Casino</td>
                            <td>Slots</td>
                            <td>Virtual Sports</td>
                            <td>Powerball</td>
                            <td className="border-right-width-0">Minigames</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bronze bold-text border-left-width-0">Bronze</td>
                            <td colSpan={6} className="border-right-width-0">Welcome Bonus</td>
                        </tr>
                        <tr className="level-color">
                            <td className="silver bold-text border-left-width-0">Silver</td>
                            <td>0.2%</td>
                            <td>0.2%</td>
                            <td>0.3%</td>
                            <td>0.3%</td>
                            <td>0.2%</td>
                            <td className="border-right-width-0">0.2%</td>
                        </tr>
                        <tr className="level-color">
                            <td className="gold bold-text border-left-width-0">Gold</td>
                            <td>0.5%</td>
                            <td>0.5%</td>
                            <td>0.5%</td>
                            <td>0.5%</td>
                            <td>0.5%</td>
                            <td className="border-right-width-0">0.5%</td>
                        </tr>
                        <tr className="level-color">
                            <td className="diamond bold-text border-left-width-0  border-bottom-width-0">Diamond</td>
                            <td className="border-bottom-width-0">0.7%</td>
                            <td className="border-bottom-width-0">0.7%</td>
                            <td className="border-bottom-width-0">1.0%</td>
                            <td className="border-bottom-width-0">1.0%</td>
                            <td className="border-bottom-width-0">0.7%</td>
                            <td className="border-bottom-width-0 border-right-width-0">0.7%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 className="form-title">VIP LEVELS</h2>
            <div class="d-flex justify-content-center mb-5">
                <div class="vip-levels"><img src="/assets/img/vip/chip-bronze.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-silver.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-gold.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-diamond.png" alt="" class="img-fluid" /></div>
            </div>
            <div className="mb-5">
                <h2 className="form-title">GENERAL TERMS & CONDITIONS</h2>
                <ol id="vip-tnc">
                    <li>Daebakbet new experience bonus 100% 50,000 + 50,000 paid!</li>
                    <li>Welcome bonus 50% up to 300,000 / 20% up to 500,000</li>
                </ol>
            </div>
        </div>
    );
}

export default withTranslation()(RollingBonus);
