import React from 'react';
import { withTranslation } from 'react-i18next';

const BirthdayBonus = (props) => {
    const { t } = props;

    return (
        <div id="vip-club-bonus-table">
            <h2 className="form-title">WEEKLY CASHBACK / DEPOSIT BONUS AMOUNT</h2>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits vip-club-bonus-table">
                    <thead>
                        <tr>
                            <th width="20%" className="bold-small-text gray-text border-top-width-0 border-left-width-0">DEPOSIT BONUS (Casino - Reload)</th>
                            <th className="bold-text bronze border-top-width-0">Bronze</th>
                            <th className="bold-text silver border-top-width-0">Silver</th>
                            <th className="bold-text gold border-top-width-0">Gold</th>
                            <th className="bold-text diamond border-top-width-0 border-right-width-0">Diamond</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="level-color">
                            <td className="bold">LIVE CASINO</td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="3" className="bonus">FTD Bonus</th>
                                        </tr>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">20%</td>
                                            <td className="border-right">100,000</td>
                                            <td>X5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" style={{height:'149px'}}>
                                                <div className="option">Option 2</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">200,000</td>
                                            <td className="border-bottom">x5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">5%</td>
                                            <td className="border-right">100,000</td>
                                            <td>x3</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 3</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">500,000</td>
                                            <td className="border-bottom">x8</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">5%</td>
                                            <td className="border-right">250,000</td>
                                            <td className="border-right">x5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 3</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">1,000,000</td>
                                            <td className="border-bottom">x8</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">5%</td>
                                            <td className="border-right">500,000</td>
                                            <td>x5</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 3</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">TV CASINO</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">SLOT</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">POWERBALL</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">ViRTUAL SPORTS</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">MINIGAMES</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits vip-club-bonus-table">
                    <tbody>
                        <tr>
                            <td width="20%" className="bold-small-text">DEPOSIT BONUS(Sports - Weekend)</td>
                            <td className="bold-text bronze">Bronze</td>
                            <td className="bold-text silver">Silver</td>
                            <td className="bold-text gold">Gold</td>
                            <td className="bold-text diamond">Diamond</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold">BTI</td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="3" className="bonus">FTD Bonus</th>
                                        </tr>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">20%</td>
                                            <td className="border-right">100,000</td>
                                            <td>x3</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3" style={{height:'269px'}}>
                                                <div className="option">Option 2</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">300,000</td>
                                            <td className="border-bottom">x5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">8%</td>
                                            <td className="border-right border-bottom">150,000</td>
                                            <td className="border-bottom">x3</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 3</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">5%</td>
                                            <td className="border-right border-bottom">100,000</td>
                                            <td>x3</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 4</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">500,000</td>
                                            <td className="border-bottom">x8</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">8%</td>
                                            <td className="border-right border-bottom">300,000</td>
                                            <td className="border-bottom">x5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 3</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right">5%</td>
                                            <td className="border-right">200,000</td>
                                            <td>x3</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 4</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td rowSpan="6" className="no-padding vertical-top">
                                <div className="option m-1">Option 1</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">10%</td>
                                            <td className="border-right border-bottom">1,000,000</td>
                                            <td className="border-bottom">x8</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 2</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">8%</td>
                                            <td className="border-right border-bottom">500,000</td>
                                            <td className="border-bottom">x5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="option m-1">Option 3</div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="bonus-percent border-right">Bonus %</th>
                                            <th className="bonus-percent border-right">Max Amount</th>
                                            <th className="bonus-percent">Rollover</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="border-right border-bottom">5%</td>
                                            <td className="border-right border-bottom">300,000</td>
                                            <td className="border-bottom">x3</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="3">
                                                <div className="option">Option 4</div>
                                                <div className="bonus">No Bonus</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">BETRADAR</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase">E-SPORTS</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 className="form-title">VIP LEVELS</h2>
            <div class="d-flex justify-content-center mb-5">
                <div class="vip-levels"><img src="/assets/img/vip/chip-bronze.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-silver.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-gold.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-diamond.png" alt="" class="img-fluid" /></div>
            </div>
            <div className="mb-5">
                <h2 className="form-title">GENERAL TERMS & CONDITIONS</h2>
                <ol id="vip-tnc">
                    <li>Daebakbet new experience bonus 100% 50,000 + 50,000 paid!</li>
                    <li>Welcome bonus 50% up to 300,000 / 20% up to 500,000</li>
                </ol>
            </div>
        </div>
    );
}

export default withTranslation()(BirthdayBonus);
