import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
    getBonusHistory,
} from "../api/bonus"

import {
    SET_BONUS_HISTORY_PAGE,
    GET_BONUS_HISTORY,
    BONUS_HISTORY_SUCCESS,
    BONUS_HISTORY_ERRORS,
} from "../actions/types";

import { historyes } from "../selectors/bonus";

export {
    historyWatcher
};

function* historyWatcher() {
    yield takeLatest(SET_BONUS_HISTORY_PAGE, fetchBonusHistory);
    yield takeLatest(GET_BONUS_HISTORY, fetchBonusHistory);
}


function* fetchBonusHistory(action) {
    const { selectedFilter, page } = yield select(historyes);
    const test = yield select(historyes);
    try {
        const result = yield call(getBonusHistory, {
            ...page,
            date: selectedFilter.date,
            type: 0,
            subtype: selectedFilter.type,
            lang: window.localStorage.i18nextLng.slice(0, 2),
            provider: action.provider,
            per_page: 5
        });
        yield put({
            type: BONUS_HISTORY_SUCCESS,
            payload: result.data
        });
    }catch(err){
        yield put({
            type: BONUS_HISTORY_ERRORS,
            payload: err.response
        });
    }
}



