import React from 'react';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();
        return (
        <div className="container-fluid min-h-screen">
            <div className="row">
                <div className="col">
                    <h2 className="form-title page-title2">{t('contact us')}</h2>
                    <div className="box-wrapper">
                        <h2 className="daebak-section-title-2 mt-0">{t('contact us service philosophy')}</h2>
                        <p className='mb-2'>{t('contact us description 1')}</p>
                        <div className="row px-3 pt3">
                            <div className="col-lg-3">
                                <div className="row mb-3">
                                    <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/24-7-cs.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">{t('24/7 online')}</span><br /><span className="font-bold">{t('customer service')}</span></p></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/cs-email.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">{t('customer service email')}</span><br /><span className="font-bold">{t('daebak email')}</span></p></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="row">
                                    {/* <div className="d-flex">
                                        <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/telegram.svg" alt="" /></div>
                                        <div><p className="m-0"><span className="opacity60">{t('telegram')}</span><br /><span className="font-bold">{t('cs-telegram')}</span></p></div>
                                    </div> */}
                                    {/* <div>
                                        <div className="qr-wrapper">
                                            <img src="/assets/img/daebak/contact-us/qr.png" alt="" />
                                        </div>
                                        <div className="mt-2 daebak-section-desc">
                                            <div>QR코드 클릭시 텔레그램으로</div>
                                            <div>자동연결 됩니다.</div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* <div className="col-lg-3">
                                <div className="d-flex">
                                    <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/cs-email.svg" alt="" /></div>
                                    <div><p className="m-0"><span className="opacity60">Customer Service E-mail Address</span><br /><span className="font-bold">{t('cs support email')}</span></p></div>
                                </div>
                            </div> */}
                            {/* <div className="col-lg-3">
                                <div className="d-flex">
                                    <div className="contact-us-icon"><img src="/assets/img/w365/icons/contact-us/affiliate-email.svg" alt="" /></div>
                                    <div><p className="m-0"><span className="opacity60">Affiliate E-mail Address</span><br /><span className="font-bold">{t('cs support affiliate email')}</span></p></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;
