import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getPage } from "../../../actions/page";
import RollingBonus from './benefits-table/RollingBonus';
import BirthdayBonus from './benefits-table/BirthdayBonus';
import DailyCashback from './benefits-table/DailyCashback';

const Vip = (props) => {
    const dispatch = useDispatch();
    const { page } = useSelector(state => state.page);
    const { t } = props;
    const tableIds = ['vip-club-rebate-table', 'vip-club-cashback-table', 'vip-club-bonus-table', 'vip-club-levelup-conditions'];
    const [selectedPrivilege, setSelectedPrivilege] = useState(tableIds[0]);
    const langStorage = localStorage.getItem('i18nextLng');
    const lang = langStorage !== null ? langStorage.split('-')[0] : 'en';
    let dynamicTitle = t('');

    useEffect(() => {
        dispatch(getPage({slug: '/vip-club-desktop'}));
    }, []);

    const selectPrivilege = (id) => {
        setSelectedPrivilege(id);

        tableIds.forEach(item => {
            if(document.getElementById(item) !== null) {
                document.getElementById(item).style.display = (item === id) ? 'block' : 'none';
            }
        });
    }

    return (
        <>
            <section id="featured">
                <div>
                    <div className="slide-content slider">
                        <div style={{width: "100%", display: "inline-block"}}>
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={`/assets/img/w365/vip/banner-${lang}.jpg`} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <h2 className="form-title my-5">{t('vip privilege')}</h2>
                            <div className="d-flex justify-content-between">
                                <div className={`vip-privileges${selectedPrivilege === tableIds[0] ? ' selected' : ''}`} onClick={() => selectPrivilege(tableIds[0])}>
                                    <img src="/assets/img/daebak/vip/birthday.jpg" alt="" className="img-fluid" />
                                    <div className="mt-3 mb-1 vip-privileges-title">{t('rebate')}</div>
                                    <div className="vip-privileges-description">{t('rebate vip privilege description')}</div>
                                </div>
                                <div className={`vip-privileges${selectedPrivilege === tableIds[2] ? ' selected' : ''}`} onClick={() => selectPrivilege(tableIds[2])}>
                                    <img src="/assets/img/daebak/vip/deposit-bonus.jpg" alt="" className="img-fluid" />
                                    <div className="mt-3 mb-1 vip-privileges-title">{t('bonus')}</div>
                                    <div className="vip-privileges-description">{t('bonus vip privilege description')}</div>
                                </div>
                                <div className={`vip-privileges${selectedPrivilege === tableIds[1] ? ' selected' : ''}`} onClick={() => selectPrivilege(tableIds[1])}>
                                    <img src="/assets/img/daebak/vip/daily-cashback.jpg" alt="" className="img-fluid" />
                                    <div className="mt-3 mb-1 vip-privileges-title">{t('cashback')}</div>
                                    <div className="vip-privileges-description">{t('cashback vip privilege description')}</div>
                                </div>
                                <div id="vip-level-conditions" className={`vip-privileges${selectedPrivilege === tableIds[3] ? ' selected' : ''}`} onClick={() => selectPrivilege(tableIds[3])}>
                                    <img src="/assets/img/daebak/vip/vip-level.jpg" alt="" className="img-fluid" />
                                    <div className="mt-3 mb-1 vip-privileges-title">{t('vip level up conditions')}</div>
                                    <div className="vip-privileges-description">{t('level up conditions vip privilege description')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-fluid">
                    <div dangerouslySetInnerHTML={{__html: page.Content}} />
                </div>
            </section>
        </>
    );
}

export default withTranslation()(Vip);
