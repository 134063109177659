import React, { Component } from "react";
import classnames from "classnames";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { getPlayer, getWallet } from "../../../actions/player";
import { getFavouriteProviders } from '../../../actions/provider';
import { displayAmount,realAmount,totalAmount,thousandAmount } from "../../../helpers";
import { Loader } from "../../common";
import Intersect from "../../../assets/img/logo/wallet/Intersect.png";
import Locked from "../../../assets/img/logo/wallet/joaAmtLocked.svg";
import Transfer from "../../pages/Transfer";
import { resetBonus } from '../../../actions/bonus';
import{ resetTransfer } from '../../../actions/payment';
import { selectProvider1 , selectProvider2 } from "../../../actions/modal";
import { getProviders } from "../../../actions/casino";

class Provider extends Component {
    constructor(props) {
        super(props);
        this.state = {
           providers: [],
           products: [],
           provz: [],
           wallet: {},
           trigger:0,
           selected: "99",
           inactive: [{ID:14}],
           mainwallet:0
        };
    }

    componentDidMount() {
        // this.props.getProviders({transfer:true})
    }

    componentWillReceiveProps(nextProps){
        let providers = [] ;

        this.setState({wallet: nextProps.wallet})
        nextProps.products.map((product, k) => (
            product.Providers.map((provider,j) => {
                providers.push(provider)
            })
        ))

        if (this.state.trigger === 0) {
            let result = [];
            providers.map((provider) => {
                 provider.Shared = this.shared(providers,provider.ID) > 1 ? 1:0
                 provider.Products = this.product(nextProps.products,provider.ID)
                 provider.PendingBet = this.bet(nextProps.providers,provider.ID)
                 result.push(provider)
            })

            result = result.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.ID === thing.ID
                ))
            )

            this.state.inactive.map((provider, k) => {
                result = result.filter(function( obj ) {
                    return obj.ID !== provider.ID ;
                });
            })

            let final = this.groupBy(result);
            this.setState({providers: final})
        }
        this.setState({products: nextProps.products, provz: nextProps.providers, mainwallet:nextProps.walletz })
    }

    groupBy = (array) => {
        let row1 = [], row2 = [], row3 = [], row4 = [], row5 = [], row6 = [], row7 = [], final = [];

            array.map((product, k) => {
                if (k < 3) { row1.push(product) }
                if (k >= 3 && k < 6)  { row2.push(product)}
                if (k >= 6 && k < 9)  { row3.push(product)}
                if (k >= 9 && k < 12) {row4.push(product)}
                if (k >= 12 && k < 15) {row5.push(product)}
                if (k >= 15 && k < 18) { row6.push(product)}
                if (k >= 18 && k < 21) { row7.push(product)}
            })
            final.push(row1, row2, row3, row4, row5, row6, row7)

        return final;
    }

    shared = (providers,id) => {
        var occurrences = providers.filter(function(val) {
            return val.ID === id;
        }).length;
        return occurrences;
    }

    product = (products,id) => {
        let result = [];
        products.map((product, k) => {
            let x = product.Providers.filter((val) => { return val.ID === id; });
            if (x.length > 0) {
                 result.push(product.Name)
            }
        })
        return result;
    }

    bet = (providers,id) => {
        let x = providers.filter((val) => { return val.ID === id; });
        if (x.length > 0) {
             return x[0].HasPendingBets.isSubscribed
        }
    }


    onChange = (event) =>{
        this.setState({selected: event.target.value});
        let {products ,provz} = this.state
        let search =  products.filter((val) => { return val.ID === parseInt(event.target.value) });

        let providers = []; let filter = [];
        products.map((product, k) => (
            product.Providers.map((provider,j) => {
                providers.push(provider)
            })
        ))

        if (parseInt(event.target.value)  === 99) {
            filter = providers;
        }

        if (parseInt(event.target.value) !== 99) {
            filter = search.length > 0 ? search[0].Providers : [];
        }

        let result = []
         filter.map((provider) => {
             provider.Shared = this.shared(providers,provider.ID) > 1 ? 1:0
             provider.Products = this.product(products,provider.ID)
             provider.PendingBet = this.bet(provz,provider.ID)
             result.push(provider)
        })


        result = result.filter((thing, index, self) =>
            index === self.findIndex((t) => (
                t.ID === thing.ID
            ))
        )


        this.state.inactive.map((provider, k) => {
            result = result.filter(function( obj ) {
                return obj.ID !== provider.ID ;
            });
        })

        let final = this.groupBy(result);

        this.setState({providers:final})

     }

    selectModalProvider = (provider) => {
        this.props.select(provider)
    }

    shouldLoad  (id) {
        return this.state.wallet[id] ;
    }

    transferToMain = (provider) => {
        this.props.select(provider)
    }

    checkAllBalance(){
        const { wallet } = this.state;
        delete wallet[0]; //special case for transfer > main wallet
        delete wallet[undefined]; //special case for transfer > main wallet
        return (this.objectLen(this.props.providerz) <= this.objectLen(wallet));
    }

    objectLen(obj){
        return Object.keys(obj).length;
    }

    render() {
        const { t } = this.props;
        const {  providers } = this.state;
        const deposit = window.location.pathname.includes("deposit");
        const transfer = window.location.pathname.includes("transfer");
        const withdrawal = window.location.pathname.includes("withdrawal");
        const rebate = window.location.pathname.includes("rebate");

        return (
            <>
            {
                deposit ?
                <div class="col-md-7">
                    <div class="accountInfo contentbox mb-7">
                        <p class="title">{t('payment_description_heading_localbank')}</p><br/>
                        {ReactHtmlParser(t('payment_description_content_localbank'))}
                    </div>
                </div>
                :
                <div className="col-md-7">
                    <div className="accountInfo mb-5">
                        <div className="form-group row">

                            <div class="col-md-12 ">
                              {
                                withdrawal ? '' : <label class="col-form-label">{t('products')}</label>
                              }
                            </div>

                            <div className="col-md-12 ">
                                <p class="wallet-text">{t('total balance')}:
                                    <span className={classnames("allGameWallet",{"d-none": !this.checkAllBalance() })} style={{fontSize: '19px', marginLeft: '15px', fontWeight: 'bold'}}> ₩ {displayAmount(this.state.mainwallet)}</span>
                                    <span className={classnames("",{"d-none":  this.checkAllBalance() })} style={{ marginLeft: '15px'}}><Loader hide={ this.checkAllBalance()} style={{ width: "20px" }}/></span>
                                </p>
                            </div>
                        </div>
                        {
                        withdrawal ? '' :

                        <div className="row no-gutters mt-2 mb-3">
                        {
                            providers.map((provider,j) => (
                                    <React.Fragment>
                                        {
                                            provider.map((prov,k) => (
                                                <div className="col myCol">
                                                    <div className="cardProvider">
                                                        <div className="card">
                                                            {
                                                                prov.PendingBet ?
                                                                <div>
                                                                    <img src={Locked} class="img-fluid lockedIcon" />
                                                                </div> : ""

                                                            }
                                                            <img src={prov.Logo} class="img-fluid walletLogo" />

                                                            {
                                                                 prov.UnderMaintenance ?  <span className="provider-type provAmount"><b>{t("not available")}</b></span>

                                                                :

                                                                <h6 className="logoAmount">
                                                                    <span className={classnames("",{ "d-none": ! this.shouldLoad(prov.ID)}) }>
                                                                        {this.state.wallet[prov.ID] ? this.state.wallet[prov.ID].currencyCode : 'KRW'} {this.state.wallet[prov.ID] ? displayAmount(this.state.wallet[prov.ID].balance) : displayAmount(0)}
                                                                     </span>

                                                                    <span className={classnames("",{ "d-none": this.shouldLoad(prov.ID)}) }>
                                                                        <Loader hide={this.shouldLoad(prov.ID)} style={{ width: "15px" }} />
                                                                    </span>
                                                                </h6>
                                                            }



                                                            <small className="cardDesc">{t(prov.Products[0].toLowerCase())}</small>
                                                            {
                                                                prov.PendingBet && (deposit ) ?

                                                                    <div class="cardTransfer">
                                                                        <a href="#" class="amountLocked">{t('amount locked')}</a>
                                                                    </div>

                                                                : deposit ?

                                                                    <div className="cardTransfer">
                                                                        <a  className="transferBtn" onClick = { () => this.selectModalProvider(prov)} >{t('deposit to')}</a>
                                                                    </div>
                                                                : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            ))
                                        }

                                        {
                                            provider.length === 1 ? <React.Fragment><div className="col myCol"> </div><div className="col myCol"> </div></React.Fragment> : ""
                                        }
                                        {
                                            provider.length === 2 ? <React.Fragment><div className="col myCol"> </div></React.Fragment> : ""
                                        }
                                    </React.Fragment>
                            ))
                        }
                        </div>
                        }

                        {
                            transfer ?
                            <p>{t("caution to transfer")}</p>
                            : withdrawal ?
                            <p style={{color: 'var(--color-13)', fontSize: '15px', fontWeight: 'bold', textTransform: 'uppercase'}}>{ReactHtmlParser(t("caution to withdrawal"))}</p>
                            : ""
                        }
                        {
                            transfer ?
                            <p>{t("caution to transfer text")}</p>
                            : withdrawal ?
                            <p>{ReactHtmlParser(t("caution to withdrawal text"))}</p>
                            : ""
                        }

                        {
                            rebate ?
                            <p>{t('caution to rebate transfer')}</p> : ''
                        }

                        {
                            rebate ?
                            <p>{t('caution to rebate text')}</p> : ''
                        }
                    </div>

                </div>
            }
            </>
        );
    }
}



const mapStateToProps = (state) => ({
    wallet: state.player.wallet,
    bonus: state.auth.wallet.bonus,
    products: state.products.result,
    provider1:  state.payment.provider1,
    provider2:  state.payment.provider2,
    providers: state.casino.providers,
    providerz: state.providers.providers,
    walletz: state.auth.wallet.wallet
});

export default connect(
    mapStateToProps,
    {  getPlayer,getWallet, resetBonus, resetTransfer, selectProvider1, selectProvider2, getProviders }
)(withRouter(withTranslation() (Provider)));
