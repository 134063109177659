import {
    CASHBACK_SUCCESS,
    CASHBACK_ERRORS,
} from "../actions/types";

const initialState = {
    cashback: {
        result: false,
        errors: false
    }
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CASHBACK_SUCCESS:
            return {
                ...state,
                cashback: action.payload,
            };
        case CASHBACK_ERRORS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
