import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "react-redux";
import classnames from "classnames"

import Modal from "react-bootstrap/Modal";

import { loginUser } from "../../../../actions/authentication";
// import { checkCanRegister } from "../../../../actions/registration";

const Login = ({ show, handleClose, handleComingSoonOpen }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [validated, setValidated] = useState(false);
    const [isLoginClicked, setIsLoginClicked] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [loginName, setLoginName] = useState("");
    const [loginPassword, setLoginPassword] = useState("");

    const loginForm = useRef(null);
    const errors = useSelector((state) => state.auth.errors.errors);
    const register = useSelector((state) => state.register.canRegister);
    const  [canRegister, setCanRegister] = useState(false);

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        setIsLoginClicked(true);
        if (loginForm.current.checkValidity()) {

            const user = {
                login_name: loginName,
                password: loginPassword,
                deviceUrl: window.location.href,
                domain: window.location.hostname === 'localhost' ? '15bet.com' : window.location.hostname
            };

            dispatch(loginUser({ user }));
            setValidated(false);
        }
    };

    const togglePassword = () => {
        setIsShown(!isShown);
    }

    // useEffect(() => {
    //     dispatch(checkCanRegister({domain:window.location.hostname}));
    // }, []);

     useEffect(() => {
        if (register !== undefined) {
            setCanRegister(register.CanSignUp)
        }
    }, [register]);

    const formatProblemText = () => {
        let problemText = t('problem logging in');
        let problemTextEmail = problemText.match(/[a-z0-9_\.]+@[a-z0-9-_\.]+\.[a-z]{2,4}/i);
    
        if(problemTextEmail !== null && problemTextEmail.length > 0) {
            problemText = problemText.replace(/<span>[a-z0-9_\.]+@[a-z0-9-_\.]+\.[a-z]{2,4}<\/span>/i, '<a href="mailto:'+problemTextEmail+'">'+problemTextEmail+'</a>');
        }

        return problemText;
    }

    return (
        <div
            style={{
                display: show ? "block" : "none"
            }}
            id="LoginModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="LoginModal"
            aria-hidden="true"
        >
            <Modal centered show={show} onHide={handleClose}>
                <Modal.Header>
                    <p className="modal-title text-center" id="LoginModal">
                        {t("login register")}
                    </p>
                    <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body className="mt-4 mb-5">
                    <div className="col text-center">
                        <h6 style={{fontSize:'15px'}}>{t('members login')}</h6>
                    </div>
                    <form
                        className={classnames({"needs-validation was-validated": validated})}
                        noValidate
                        method="post"
                        onSubmit={onSubmit.bind(this)}
                        ref={(ref) => (loginForm.current = ref)}
                    >
                        <div class="form-group">
                            <label for="partnerID" class="sr-only">{t('id')}</label>
                            <div class="input-group">
                                <input
                                    type="text"
                                    className={`form-control form-control-md dark inputPlaceholder ${
                                        errors &&
                                        errors.login_name &&
                                        isLoginClicked
                                            ? "is-invalid"
                                            : ""
                                    } `}
                                    id="login_name"
                                    placeholder={t("id")}
                                    onChange={e => setLoginName(e.target.value)}
                                    name="login_name"
                                    required
                                />
                                <div class="invalid-tooltip">
                                    {
                                        errors &&
                                        errors.login_name &&
                                        isLoginClicked
                                            ? t(errors.login_name)
                                        : t("this field is required")
                                    }
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="partnerPassword" class="sr-only">{t('password')}</label>
                            <div class="input-group login_show_hide_password">
                                <input
                                    type={!isShown ? "password" : "text"}
                                    className="form-control form-control-md dark"
                                    id="partnerPassword"
                                    placeholder={t("password")}
                                    name="password"
                                    onChange={e => setLoginPassword(e.target.value)}
                                    required
                                />
                                {
                                    isLoginClicked ?
                                    <div
                                        className={classnames("invalid-tooltip", {
                                            "d-block":
                                                errors &&
                                                errors.password &&
                                                isLoginClicked
                                        })}
                                    >
                                        {errors &&
                                        errors.password &&
                                        isLoginClicked
                                            ? ""
                                            : t("this field is required")}
                                    </div>
                                    : 
                                    ""

                                }
                                <div class="input-group-addon">
                                    <a onClick={() => {togglePassword()}}>
                                        <i
                                            className={classnames("fa", {
                                                "fa-eye-slash": !isShown,
                                                "fa-eye": isShown
                                            })}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="btn-container">
                            <button type="submit" class="btn btn-secondary" style={{width: "100%"}}>{t("login")}</button>
                            
                            {/*<Link to="#" className="btn btn-dark" onClick={handleComingSoonOpen}>{t('register')}</Link>  */}
                            {
                                canRegister ? <><p class="or">{t('or')}</p><Link to="/register" className="btn btn-success" onClick={handleClose}> {t('register')}</Link></> : ''
                            }
                        </div>
                        <div className="notetxt mt-4">
                            <p dangerouslySetInnerHTML={{__html: formatProblemText()}}></p>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Login;
