import Home from "./components/pages/Home";
import Main from "./components/pages/Main";
import Register from "./components/pages/Register";
import Promotion from "./components/pages/Promotion";
import Partner from "./components/pages/Partner";
import PartnerRegister from "./components/pages/PartnerRegister";
import PromotionContent from "./components/pages/Promotion/Content.js";
import RewardContent from "./components/pages/Promotion/RewardContent.js";
import DailyRebate from "./components/pages/Promotion/DailyRebate.js";
import WeeklyCashback from "./components/pages/Promotion/WeeklyCashback.js";
import Cashout from "./components/pages/Promotion/Cashout.js";
import MyAccount from "./components/pages/MyAccount";
import Banking from "./components/pages/Banking";
import Vip from "./components/pages/Vip";
import BTI from "./components/pages/BTI";
import DS from "./components/pages/DS";
import Powerball from "./components/pages/Powerball";
import Betradar from "./components/pages/Betradar";
import Esports from "./components/pages/Esports";
import TronRecharge from './components/pages/TronRecharge';
import Slots from './components/pages/Slots';
import Play from "./components/pages/Slots/Play";
import LiveCasino from './components/pages/LiveCasino';
import LiveCasinoPlay from "./components/pages/LiveCasino/Play";
import ContactUs from './components/pages/ContactUs';
// import PrivacyPolicy from './components/pages/PrivacyPolicy';
// import ResponsibleGaming from "./components/pages/ResponsibleGaming";
import MiniGame from './components/pages/MiniGame';
import BetConstruct from "./components/pages/BetConstruct";
import BT1 from "./components/pages/BT1";
import Poker from "./components/pages/Poker";

//
const routes = [
    {
        path: "/",
        exact: true,
        name: "Main",
        component: Main,
        auth: false,
        noHeader: true,
    },
    {
        path: "/bti-sports",
        exact: true,
        name: "Home",
        component: Home,
        auth: false
    },
    {
        path: "/bt1-sports",
        exact: true,
        name: "BT1",
        component: BT1,
        auth: false
    },
    {
        path: "/register",
        exact: true,
        name: "Register",
        component: Register,
        auth: false
    },
    {
        path: "/partners",
        exact: true,
        name: "Partner",
        component: Partner,
        auth: false
    },
    {
        path: "/partner/register",
        exact: true,
        name: "Partner Registration",
        component: PartnerRegister,
        auth: false
    },
    {
        path: "/partners/:slug",
        exact: true,
        name: "Partners Slug",
        component: Partner,
        auth: false
    },
    {
        path: "/promotions",
        exact: true,
        name: "Promotions",
        component: Promotion,
        auth: false
    },
    // {
    //     path: "/bonus",
    //     exact: true,
    //     name: "Bonus",
    //     component: Promotion,
    //     auth: false
    // },
    // {
    //     path: "/rebate",
    //     exact: true,
    //     name: "Rebate",
    //     component: Promotion,
    //     auth: false
    // },
    // {
    //     path: "/reward",
    //     exact: true,
    //     name: "Reward",
    //     component: Promotion,
    //     auth: false
    // },
    {
        path: "/promotions/:slug",
        exact: true,
        name: "Promotion Slug",
        component: Promotion,
        auth: false
    },
    // {
    //     path: "/bonus/:product",
    //     exact: true,
    //     name: "Bonus Archive",
    //     component: Promotion,
    //     auth: false
    // },
    // {
    //     path: "/rebate/:product",
    //     exact: true,
    //     name: "Rebate Archive",
    //     component: Promotion,
    //     auth: false
    // },
    // {
    //     path: "/promotion/:id",
    //     exact: true,
    //     name: "Promotion Content",
    //     component: PromotionContent,
    //     auth: false
    // },
    // {
    //     path: "/reward/:content",
    //     exact: true,
    //     name: "Reward Content",
    //     component: RewardContent,
    //     auth: false
    // },
    // {
    //     path: "/reward/daily-rebate",
    //     exact: true,
    //     name: "Daily Rebate",
    //     component: DailyRebate,
    //     auth: false
    // },
    // {
    //     path: "/reward/weekly-cashback",
    //     exact: true,
    //     name: "Weekly Cashback",
    //     component: WeeklyCashback,
    //     auth: false
    // },
    // {
    //     path: "/reward/cash-out",
    //     exact: true,
    //     name: "Cashout",
    //     component: Cashout,
    //     auth: false
    // },
    // {
    //     path: "/live-sports",
    //     exact: true,
    //     name: "BTI",
    //     component: BTI,
    //     auth: false
    // },
    {
        path: "/myaccount/:any/:id?",
        exact: true,
        name: "My Account",
        component: MyAccount,
        auth: true
    },
    {
        path: "/myaccount/:any/:id/:promotion?",
        exact: true,
        name: "My Account",
        component: MyAccount,
        auth: true
    },
    {
        path: "/banking",
        exact: true,
        name: "Banking",
        component: Banking,
        auth: false
    },
    // {
    //     path: "/powerball",
    //     exact: true,
    //     name: "Powerball",
    //     component: Powerball,
    //     auth: false
    // },
    {
        path: "/vip",
        exact: true,
        name: "VIP",
        component: Vip,
        auth: false
    },
    // {   
    //     path: "/live-sports",
    //     exact: true,
    //     name: "Betradar",
    //     component: Betradar,
    //     auth: false
    // },
    // {
    //     path: "/e-sports",
    //     exact: true,
    //     name: "Betradar Esports",
    //     component: Esports,
    //     auth: false
    // },
    {
        path: "/tron-recharge",
        exact: true,
        name: "Tron Recharge",
        component: TronRecharge,
        auth: true
    },
    {
        path: "/slots",
        exact: true,
        name: "Slots",
        component: Slots,
        auth: false
    },
    {
        path: "/slots/:provider_id/:game_code",
        exact: true,
        name: "Slots",
        component: Play,
        auth: true
    },
    {
        path: "/live-casino",
        exact: true,
        name: "Live Casino",
        component: LiveCasino,
        auth: false
    },
    {
        path: "/live-casino/play/:game_type_code",
        exact: true,
        name: "Live Casino Play",
        component: LiveCasinoPlay,
        auth: true
    },
    {
        path: "/contact-us",
        exact: true,
        name: "Contact Us",
        component: ContactUs,
        auth: false
    },
    // {
    //     path: "/privacy-policy",
    //     exact: true,
    //     name: "Privacy Policy",
    //     component: PrivacyPolicy,
    //     auth: false
    // },
    // {
    //     path: "/responsible-gaming",
    //     exact: true,
    //     name: "Responsible Gaming",
    //     component: ResponsibleGaming,
    //     auth: false
    // },
    {
        path: "/betconstruct",
        exact: true,
        name: "BetConstruct",
        component: BetConstruct,
        auth: false
    },
    // {
    //     path: "/minigame/cockfighting",
    //     exact: true,
    //     name: "Mini Game Cockfighting",
    //     component: MiniGame,
    //     auth: false
    // }
    {
        path: "/poker",
        exact: true,
        name: "Poker",
        component: Poker,
        auth: false
    },
];

export default routes;
