import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import classNames from "classnames";

import {
    readMessage,
    getMessages,
    getAllMessages
} from "../../../actions/player";

import {
    getUnreadMessage,
} from "../../../actions/authentication";

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            message: null
        };
        this.navigatePage = this.navigatePage.bind(this);
    }

    componentDidMount() {
        this.props.getMessages();
        this.props.getUnreadMessage();
    }

    componentWillUnmount(){
        this.props.getUnreadMessage();
    }

    componentWillReceiveProps(nextProps) {
        const messages = nextProps.player.messages.data;

        const message = messages.find(
            (message) =>
                message.MessageID === parseInt(nextProps.match.params.id)
        );
        const current_page = messages.findIndex(
            (message) =>
                message.MessageID === parseInt(nextProps.match.params.id)
        );
        const last_page = messages.length - 1;
        this.setState({ messages, message, current_page, last_page });
    
        if (!message) {
            this.props.history.push("/myaccount/messages");
        } else {
            if (message.Status === "Read") {
                this.props.readMessage(message);
                this.props.getUnreadMessage(message);
            }
        }
    }

    navigatePage(page) {
        const message = this.state.messages[page];
        const id = message.MessageID;
        this.props.history.push(`/myaccount/messages/${id}`);
        this.setState({ message, current_page: page });
    }

    backToMessages() {
        this.props.history.push("/myaccount/messages");
        this.props.getMessages();
        this.props.getUnreadMessage();
    }

    render() {
        let { message, current_page, last_page } = this.state;
        const { t } = this.props;
        if (!message) {
            return <></>;
        }
        return (
            <React.Fragment>
                <div className="col-md-12 message_nav">
                    <div className="row mb-5">
                        <div className="col-md-6 text-left">
                            <a
                                onClick={this.backToMessages.bind(this)}
                                className="btn btn-success close_message"
                            >
                                <i className="fas fa-arrow-left"></i>{" "}{t("back to messages")}
                            </a>
                        </div>
                        <div className="col-md-6 text-right">
                            <a
                                className={classNames(
                                    "btn prev_message message_id_0_link btn-success",
                                    {
                                        disabled: current_page === 0
                                    }
                                )}
                                onClick={() => {
                                    this.navigatePage(current_page - 1);
                                }}
                            >
                                <i className="fas fa-arrow-left"></i>{" "}{t("previous")}
                            </a>
                            <a
                                className={classNames(
                                    "btn next_message message_id_2_link btn-success",
                                    {
                                        disabled: current_page === last_page
                                    }
                                )}
                                onClick={() => {
                                    this.navigatePage(current_page + 1);
                                }}
                            >
                                <i className="fas fa-arrow-right"></i>{" "}{t("next")}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="message-section col-md-12">
                    <p className="message_title highlighted-text">{message.Subject}</p>
                    <div className="row m-0">
                        {message.Type === 'Notice' ?
                        <div className="col-md-12 convoBox">
                            <p className="message_date">{message.DateCreated}</p>
                            <p className="message-text" dangerouslySetInnerHTML={{__html: message.Message}} />
                        </div>
                        : message.Messages.map((msg, index) => {
                            return (
                                <div className="col-md-12 convoBox" key={`${message.MessageID}${index}`}>
                                    <p className="message_date">{msg.DateCreated}</p>
                                    {msg.Sender === 'Customer Service' ? 
                                        <p className="message_name">{t('customer service')}</p> 
                                    : 
                                        <p className="message_name2">{msg.Sender}</p>}
                                    <p className="message-text" dangerouslySetInnerHTML={{__html: msg.Message}} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

Message.propTypes = {};

const mapStateToProps = (state) => ({
    player: state.player
});

export default connect(
    mapStateToProps,
    { getAllMessages, getMessages, readMessage,getUnreadMessage }
)(withTranslation()(withRouter(Message)));
