import React, { useEffect, useState, Component } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook";
import { getPage } from "../../actions/page";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from "react-accessible-accordion";
import { Card, useAccordionToggle } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CgSearch } from "react-icons/cg";
import backIcon from "../../assets/img/back-icon.svg";
import loaderSlow from "../../assets/img/loader-slow.gif";

const Content = ({ slug }) => {
    const pagesWithHeading = [
        "/sports-betting-game-rules",
        "/live-betting-game-rules",
        "/prematch-betting-game-rules",
        "/betradar-rules",
        "/betconstruct-rules"
    ];
    const pageHeadingText = {
        "/sports-betting-game-rules": "rules",
        "/live-betting-game-rules": "betting rules",
        "/prematch-betting-game-rules": "pre-match betting game rules",
        "/betradar-rules": "betradar rules",
        "/betconstruct-rules": "betconstruct rules"
    };
    const dispatch = useDispatch();
    const lang = localStorage.getItem("i18nextLng") || 'en-US';
    const { page, loading } = useSelector(state => state.page);
    const [expanded, setExpanded] = useState([]);
    const [search, setSearch] = useState("");
    const [accordionData, setAccordionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const vipClubSlugs = ['/vip-club-desktop', '/vip-club-mobile', '/vip-club'];

        if(vipClubSlugs.includes(window.location.pathname)) {
            window.location.href = '/vip';
        }
    }, []);

    useEffect(() => {
        dispatch(getPage({ slug: slug }));
    }, [slug]);

    useEffect(() => {
        window.changePlusMinusAccordion();
        window.changeAccordionToPlus();
    }, [filteredData]);

    useEffect(() => {
        if (accordionData.length !== 0) {
            setFilteredData(accordionData);
        }
    }, [accordionData]);

    useEffect(() => {
        if (filteredData.length !== 0) {
            setExpanded(filteredData.map(s => false));
        }
    }, [filteredData]);

    useEffect(() => {
        if (accordionData.length !== 0) {
            if (search === "") {
                setFilteredData(accordionData);
            } else {
                const filtered = accordionData.filter(data =>
                    data.title.toLowerCase().includes(search.toLowerCase())
                );
                setFilteredData(filtered);
            }
        }
    }, [search]);

    useEffect(() => {
        if (page.Accordion !== undefined) {
            setAccordionData(page.Accordion);
        }
    }, [page]);

    const onChange = event => {
        setSearch(event.target.value);
    };

    const toggleExpand = e => {
        const ex = {
            ...expanded,
            [e]: !expanded[e]
        };
        setExpanded(ex);
    };

    const content = page.Content;
    const accordions = page.Accordion;
    let accordionDataMain = "";

    const { t, i18n } = useTranslation();

    if (content === undefined || content === null) {
        return <NotFound />;
    }

    if (accordions) {
        accordionDataMain = (
            <Accordion
                allowMultipleExpanded={true}
                allowZeroExpanded={true}
                preExpanded={[0]}
                id="live-betting-game-rules"
            >
                {accordions.map((accordion, key) => {
                    return (
                        <Card className="accordion-wrapper">
                            <AccordionItem key={key}>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        <Card.Header className="accordion-header">
                                            <h5 className="mb-0">
                                                <a className="btn a-faq wrap-faq collapsed">
                                                    {accordion.title}{" "}
                                                    <i class="fa fa-plus i-faq"></i>
                                                </a>
                                            </h5>
                                        </Card.Header>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <Card.Body className="accordion-body">
                                        <div
                                            className={lang}
                                            dangerouslySetInnerHTML={{
                                                __html: accordion.content
                                            }}
                                        />
                                    </Card.Body>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Card>
                    );
                })}
            </Accordion>
        );
    }

    return (
        <>
            {!loading ? (
                <>
                    {slug === "/frequently-asked-questions" ? (
                        <div className="container default-top-space faq-container">
                            <div class="row">
                                <div class="column col-w365-faq">
                                    <div class="row">
                                        <h2 className="form-title page-title2">
                                            FAQS
                                        </h2>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: content
                                            }}
                                        />
                                    </div>
                                    <div class="row">
                                        <div className="searchbar searchbar-w365">
                                            <div className="faq-search-wrapper">
                                                <input
                                                    onChange={onChange}
                                                    className="form-control my-0 py-1 amber-border custom-select-faq dark shadow-none"
                                                    type="text"
                                                    placeholder={t(
                                                        "search for keywords"
                                                    )}
                                                    aria-label="Search"
                                                />
                                                <CgSearch className="faq-search-icon" />
                                            </div>
                                        </div>
                                        {filteredData.length > 0 ? (
                                            <div class="pb-5 minHeight">
                                                <Accordion
                                                    allowMultipleExpanded={true}
                                                    allowZeroExpanded={true}
                                                    preExpanded={[0]}
                                                    id="live-betting-game-rules"
                                                >
                                                    {filteredData.map(
                                                        (accordion, key) => {
                                                            return (
                                                                <Card className="accordion-wrapper">
                                                                    <AccordionItem
                                                                        key={
                                                                            key
                                                                        }
                                                                        uuid={
                                                                            key
                                                                        }
                                                                        dangerouslySetExpanded={
                                                                            expanded[
                                                                                key
                                                                            ]
                                                                        }
                                                                        onClick={() =>
                                                                            toggleExpand(
                                                                                key
                                                                            )
                                                                        }
                                                                    >
                                                                        <AccordionItemHeading>
                                                                            <AccordionItemButton>
                                                                                <Card.Header className="accordion-header">
                                                                                    <h5 className="mb-0">
                                                                                        <a className="btn a-faq wrap-faq collapsed">
                                                                                            {
                                                                                                accordion.title
                                                                                            }{" "}
                                                                                            <i class="fa fa-plus i-faq"></i>
                                                                                        </a>
                                                                                    </h5>
                                                                                </Card.Header>
                                                                            </AccordionItemButton>
                                                                        </AccordionItemHeading>
                                                                        <AccordionItemPanel>
                                                                            <Card.Body className="accordion-body">
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                            accordion.content
                                                                                    }}
                                                                                />
                                                                            </Card.Body>
                                                                        </AccordionItemPanel>
                                                                    </AccordionItem>
                                                                </Card>
                                                            );
                                                        }
                                                    )}
                                                </Accordion>
                                            </div>
                                        ) : (
                                            <div class="pb-5 minHeight"></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0">
                                <div>
                                    <img src="assets/img/model-faq.png" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="container-fluid">
                            {pagesWithHeading.includes(
                                window.location.pathname
                            ) ? (
                                <div className="row">
                                    <div className="col">
                                        <h2 className="form-title mt-5 font-25px">
                                            {t(
                                                pageHeadingText[
                                                    window.location.pathname
                                                ]
                                            )}
                                        </h2>
                                    </div>
                                    {window.location.pathname !==
                                    pagesWithHeading[0] ? (
                                        <div className="col">
                                            <div className="mt-5 back-link">
                                                <a
                                                    href={
                                                        window.location
                                                            .pathname ===
                                                            "/live-betting-game-rules" ||
                                                        window.location
                                                            .pathname ===
                                                            "/prematch-betting-game-rules"
                                                            ? "/betradar-rules"
                                                            : "/sports-betting-game-rules"
                                                    }
                                                >
                                                    <img
                                                        src={backIcon}
                                                        alt=""
                                                    />
                                                    <span className="ml-2">
                                                        {t("back")}
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            <div className="row">
                                <div className="col">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: content
                                        }}
                                    />

                                    <div class="pb-5 minHeight">
                                        {accordions && accordionDataMain}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <div className="loading-wrapper">
                    <img src={loaderSlow} alt="loading" />
                </div>
            )}
        </>
    );
};

class NotFound extends Component {
    render() {
        return (
            <div className="container-fluid not-found">
                <div className="not-found-text">
                    <h1 className="display-4">
                        <span className="text-danger">404</span> Page Not Found
                    </h1>
                    <p className="lead"> Sorry, that page does not exist </p>
                </div>
            </div>
        );
    }
}

class CMS extends Component {
    componentDidMount() {
        var elem = document.getElementById("main-wrap");
        elem.classList.add("tnc");
    }

    componentWillUnmount = () => {
        var elem = document.getElementById("main-wrap");
        elem.classList.remove("tnc");
    };

    render() {
        return <Content slug={this.props.location.pathname} />;
    }
}

export default CMS;
