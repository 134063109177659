import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from "react-html-parser";
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { player } from '../../../../selectors/auth';
import { displayAmount, realAmount } from '../../../../helpers';
import BtnLoader from '../../../common/BtnLoader';
import SuccessAlert from "../../../common/SuccessAlert";
import DangerAlert from "../../../common/DangerAlert";
import { toast } from 'react-toastify';
import LiveChatIcon from '../../../../assets/img/deposit-error/live_chat.svg'
import KakaoIcon from '../../../../assets/img/deposit-error/kakao_talk.svg'
import EmailIcon from '../../../../assets/img/deposit-error/email.svg'
import HotlineIcon from '../../../../assets/img/deposit-error/hotline.svg'

const ConfirmDeposit = ({ show, amount, bankAccountName, handleClose, onSubmit, success, errors, propsSuccess, phone, submitDisabled, resendSMS, isBlacklisted, isClosed, isError400, ongoingSubscription, paymentOption, assignBankInfo}) => {
    const { t } = useTranslation();
    const displayError400Msg = useCallback(() => {
        if(errors && errors.status === 400){
            if(errors.message && errors.message.includes('max claim limit')){
                return t('max claim limit');
            }else{
                return t('deposit request was unsuccessful');
            }
        }
    })

    return (
        <React.Fragment>
            <div
                style={{
                    display: show ? "block" : "none"
                }}
                id="DepositModal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="DepositModal" 
                aria-modal="true">
                <Modal
                    centered
                    show={show}
                    //onHide={handleClose}
                    
                    className={classname("modal-confirm modal-deposit", {
                })}
                    >
                    <Modal.Header>
                        <h5 className="modal-title-deposit modal-header-deposit" id="LoginModal">
                            {
                                success ? 
                                    t('deposit request sent')
                                    : (errors && errors.bank === "No bank") || isBlacklisted || isClosed || isError400 || ongoingSubscription ?
                                    t('deposit request failed')
                                    :
                                    t('check the deposit information')
                            }
                        </h5>
                        {
                            success ? 
                            paymentOption === 5?
                            // assignBankInfo !== undefined ?
                            <button type="button" className="close" onClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            // : 
                            // <></>
                            :
                            <button type="button" className="close" onClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            :
                            <button type="button" className="close" onClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        }
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className='row'>
                        {
                            success ?
                                paymentOption === 5 ?
                                <div className="p-2 col-12 wincoin-text">{ReactHtmlParser(t('wincoin_payment_msg'))}</div>
                                :
                                <div className="col-12 accountInfo  mb-12" id="deposit-message">
                                    <div className="depSentContent">
                                        <div className="depSent">
                                            <SuccessAlert translationCode="deposit success message" />
                                        </div>

                                        <div className="p-1 col-12">
                                            <div className='row'>
                                                    <div className="mb-2 col-3 colgray" style={{ fontWeight: 'bold' }} >{t('transaction id')}:</div>
                                                    <div className="mb-2" style={{ fontWeight: '700' }}>{propsSuccess}</div>
                                            </div>

                                                <div className='row' style={{ marginTop: '7px' }}>
                                                <div className="mb-2 col-12" >{t('deposit request submitted wait for review')}</div> 
                                            </div>

                                            <div className='row'>
                                                    <div className="mb-2 col-12" style={{ marginTop: '10px' }} >{t('account details has been sent to your')}</div>
                                            </div>

                                                <div className='row' style={{ marginTop: '7px' }}>
                                                    <div className="mb-2 col-3 colgray" style={{ fontWeight: 'bold' }} >{t('mobile number')}:</div>
                                                <div className="mb-2 ">{phone}</div> 
                                            </div>

                                                <div className='row' style={{ marginTop: '7px' }}>
                                                    <div className="mb-2 col-12" >{t('didnt received anything click')} <a className="requestSentResend" style={{ color: 'var(--color-13)' }} href="#" onClick={resendSMS}>{t('resend')}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    : (errors && errors.bank === "No bank") || isBlacklisted || isClosed || isError400 || ongoingSubscription ?
                            <div class="col-md-12 accountInfo mb-12" id="deposit-message">
                            <div class="depSentContent">
                                {/* <DangerAlert translationCode='deposit request failed' /> */}

                                <div className="p-1 col-12">
                                    <div id='containerForm'>
                                                        <div className='row' style={{ marginTop: '0px' }}>
                                            <div className="mb-2 col-12" >{ongoingSubscription ? t('pending subscription') : displayError400Msg()}</div>
                                        </div>
                                                        <div className='row' style={{ marginTop: '7px', marginBottom: '15px' }}>
                                                            <div className="mb-2 col-3 colgray error-code-deposit">{t('error code')}:</div>
                                            <div className="mb-2 ">{errors.status}</div> 
                                        </div>
                                                        <h3 class="req-help-deposit requestFailedTitle">{t('need help')}</h3>
                                        <p class="requestFailedSub cs-support-deposit"><span >{t('our cs support is available 24 hours')}</span></p>
                                    </div>
                                </div>
                                
                                <div>
                                                    <div class="row no-gutters" style={{ marginTop: '80px'}}>
                                        <div class="col-md-3">
                                                            <div style={{ textAlign: 'center' }}>
                                                <div className='icon-bg-deposit' >
                                                <svg class="icon-deposit" width="38" height="38"viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg"  style={{ width: '23px' }}>
                                                <path d="M45.7895 30.4199C45.3947 29.3673 44.2105 28.9726 43.2895 29.3673C42.2368 29.762 41.8421 30.9462 42.2368 31.8673L44.6053 37.5252L37.7632 34.4989C37.2368 34.2357 36.7105 34.2357 36.1842 34.4989C33.8158 35.5515 31.3158 35.9462 28.8158 35.9462C18.4211 35.9462 11.3158 27.7883 11.3158 20.1568C11.3158 11.4726 19.2105 4.36729 28.8158 4.36729C38.4211 4.36729 46.0526 11.4726 46.0526 20.1568C46.0526 20.8147 46.0526 21.6041 45.9211 22.3936C45.7895 23.4462 46.4474 24.4989 47.5 24.6304C48.5526 24.762 49.6053 24.1041 49.7368 23.0515C49.8684 22.1304 50 21.0778 50 20.2883C50 15.0252 47.7632 10.0252 43.6842 6.2094C39.6053 2.39361 34.3421 0.419922 28.5526 0.419922C22.7632 0.419922 17.5 2.39361 13.5526 6.07782C9.86842 9.36729 7.76316 13.7094 7.23684 18.4462C2.76316 21.4726 0 26.341 0 31.6041C0 34.8936 1.05263 37.9199 3.02632 40.6831L0.131579 47.7883C-0.131579 48.5778 0 49.3673 0.526316 49.8936C0.921053 50.2883 1.44737 50.4199 1.84211 50.4199C2.10526 50.4199 2.36842 50.4199 2.63158 50.2883L10.7895 46.6041C12.8947 47.3936 15 47.7883 17.3684 47.7883H17.5C20.7895 47.7883 23.9474 46.8673 26.7105 45.2883C29.2105 43.841 31.1842 41.8673 32.6316 39.4989C34.0789 39.2357 35.3947 38.841 36.7105 38.3147L47.2368 42.9199C47.5 43.0515 47.7632 43.0515 48.0263 43.0515C48.5526 43.0515 48.9474 42.9199 49.3421 42.5252C49.8684 41.9989 50.1316 41.0778 49.7368 40.4199L45.7895 30.4199ZM17.5 43.841C15.3947 43.841 13.5526 43.4462 11.7105 42.6568C11.1842 42.3936 10.6579 42.3936 10.1316 42.6568L5.52632 44.6304L6.97368 40.9462C7.23684 40.2883 7.10526 39.4989 6.71053 38.9726C4.86842 36.9989 3.94737 34.3673 3.94737 31.6041C3.94737 28.4462 5.26316 25.5515 7.5 23.3147C8.15789 27.262 10.2632 30.9462 13.2895 33.841C17.1053 37.5252 22.1053 39.6304 27.5 39.8936C25 42.3936 21.3158 43.841 17.5 43.841Z" fill="#8EB50D"/>
                                                <path d="M28.5526 22.262C29.6053 22.262 30.5263 21.341 30.5263 20.2883C30.5263 19.2357 29.6053 18.3147 28.5526 18.3147C27.5 18.3147 26.5789 19.2357 26.5789 20.2883C26.5789 21.341 27.5 22.262 28.5526 22.262Z" fill="#8EB50D"/>
                                                <path d="M36.3158 22.262C37.3684 22.262 38.2895 21.341 38.2895 20.2883C38.2895 19.2357 37.3684 18.3147 36.3158 18.3147C35.2632 18.3147 34.3421 19.2357 34.3421 20.2883C34.3421 21.341 35.2632 22.262 36.3158 22.262Z" fill="#8EB50D"/>
                                                <path d="M20.6579 22.262C21.7105 22.262 22.6316 21.341 22.6316 20.2883C22.6316 19.2357 21.7105 18.3147 20.6579 18.3147C19.6053 18.3147 18.6842 19.2357 18.6842 20.2883C18.8158 21.341 19.6053 22.262 20.6579 22.262Z" fill="#8EB50D"/>
                                                </svg>
                                                </div>
                                                                <br />
                                                <p className='iconLabel-deposit'>{t('live chat')}</p>
                                                                <p class="iconSubLabel-deposit">{t("cs support deposit")}</p>
                                                <a href="#chat" target="_blank"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                                            <div style={{ textAlign: 'center' }}>
                                                <div className='icon-bg-deposit'>
                                                <svg class="icon-deposit" width="42" height="38"  viewBox="0 0 51 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '23px' }}>
                                                <path d="M20.2129 17.8208L18.7679 21.8734H21.6482L20.2129 17.8208Z" fill="#8EB50D"/>
                                                <path d="M25.4951 0.558594C11.6892 0.558594 0.5 9.30662 0.5 20.0907C0.5 27.0657 5.17682 33.1835 12.2165 36.6418C9.51191 45.8866 9.44357 45.9353 9.90246 46.1984C10.4785 46.5296 11.2206 46.1886 21.0624 39.3207C22.5074 39.5253 23.9915 39.6325 25.5049 39.6325C39.3108 39.6325 50.5 30.8844 50.5 20.1004C50.5 9.31636 39.301 0.558594 25.4951 0.558594ZM13.1147 25.546C13.1147 26.2961 12.4703 26.9098 11.6697 26.9098C10.8691 26.9098 10.2247 26.2961 10.2247 25.546V17.0513H7.97901C7.19791 17.0513 6.56327 16.4278 6.56327 15.6485C6.56327 14.8691 7.19791 14.2457 7.97901 14.2457H15.3604C16.1415 14.2457 16.7761 14.8691 16.7761 15.6485C16.7761 16.4278 16.1415 17.0513 15.3604 17.0513H13.1147V25.546ZM24.3235 26.8904C22.8199 26.8904 23.103 25.7116 22.4098 24.4062H18.0161C17.3326 25.7019 17.5963 26.8904 16.1024 26.8904C14.6574 26.8904 14.4035 26.0136 14.8429 24.6692L18.2895 15.6679C18.5336 14.986 19.2659 14.2749 20.2129 14.2554C21.1503 14.2749 21.8923 14.9763 22.1364 15.6679C24.4016 22.7112 27.6529 26.9001 24.3235 26.8904ZM32.525 26.7053H27.897C25.4854 26.7053 26.9499 23.4515 26.5203 15.6874C26.5203 14.8983 27.1842 14.2554 27.9946 14.2554C28.805 14.2554 29.469 14.8983 29.469 15.6874V24.0847H32.525C33.2866 24.0847 33.9017 24.6692 33.9017 25.3901C33.9017 26.111 33.2866 26.7053 32.525 26.7053ZM43.8119 25.6239C43.763 26.0039 43.558 26.3351 43.2553 26.5689C41.2635 28.0691 39.799 23.6951 37.856 21.8539L37.358 22.3507V25.4681C37.358 26.2571 36.7136 26.9001 35.913 26.9001C35.1221 26.9001 34.468 26.2571 34.468 25.4681V15.6874C34.468 14.8983 35.1124 14.2554 35.913 14.2554C36.7136 14.2554 37.358 14.8983 37.358 15.6874V18.7561C40.0138 16.7687 41.4881 13.2033 43.1772 14.8691C44.8273 16.5057 41.41 17.928 39.9942 19.9056C43.3237 24.5036 43.9583 24.6205 43.8119 25.6239Z" fill="#8EB50D"/>
                                                </svg>
                                                </div>
                                                                <br />
                                                <p className='iconLabel-deposit'>{t("kakao talk")}</p>
                                                    <p class="iconSubLabel-deposit">{t('kakao')}</p>
                                                <a href="#chat"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                                            <div style={{ textAlign: 'center' }}>
                                                <div className='icon-bg-deposit'>
                                                <svg class="icon-deposit" width="38" height="33" viewBox="0 0 51 35" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '21px'}}>
                                                <path d="M47.9286 0.753906H3.07143C1.64286 0.753906 0.5 1.90831 0.5 3.35131V31.4898C0.5 32.9328 1.64286 34.0872 3.07143 34.0872H47.9286C49.3571 34.0872 50.5 32.9328 50.5 31.4898V3.35131C50.5 1.90831 49.3571 0.753906 47.9286 0.753906ZM46.9286 2.62981L26.9286 17.4927C26.5 17.7813 26.0714 17.9256 25.5 17.9256C24.9286 17.9256 24.3571 17.7813 24.0714 17.4927L4.07143 2.62981H46.9286ZM36.2143 18.6471L47.0714 32.067H3.78571L14.6429 18.6471C14.9286 18.2142 14.9286 17.637 14.5 17.3484C14.0714 17.0598 13.5 17.0598 13.2143 17.4927L2.35714 30.7683V3.92851L22.7857 19.2243C23.5 19.8015 24.5 20.0901 25.3571 20.0901C26.2143 20.0901 27.2143 19.8015 27.9286 19.2243L48.5 3.92851V30.7683L37.6429 17.4927C37.3571 17.0598 36.6429 17.0598 36.3571 17.3484C36.0714 17.637 35.9286 18.2142 36.2143 18.6471Z" fill="#8EB50D"/>
                                                </svg>
                                                </div>
                                                                <br />
                                                <p className='iconLabel-deposit'>{t("email")}</p>
                                                    <p class="iconSubLabel-deposit">{t('cs support email')}</p>
                                                <a href="mailto:cskr@w365.com" target="_blank"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                                            <div style={{ textAlign: 'center' }}>
                                                <div className='icon-bg-deposit'>
                                                <svg class="icon-deposit" width="31" height="38" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '21px' }}>
                                                <path d="M1.90645 21.4606C0.998155 19.1982 0.316937 16.7095 0.54401 14.2208C0.771083 12.6371 1.22523 11.2796 2.36059 10.1484C3.72303 9.01721 4.85839 7.65974 5.99375 6.52852C7.81034 4.94481 9.85399 4.94481 11.4435 6.52852C12.5789 7.4335 13.4872 8.33847 14.3954 9.4697C15.5308 10.3747 16.4391 11.2796 17.3474 12.4109C18.9369 14.2208 19.164 16.0308 17.3474 17.8407C16.212 18.972 14.8496 20.1032 13.7142 21.2344C13.4872 21.6869 13.2601 21.9131 13.4872 22.3656C14.3954 24.4018 15.5308 25.9855 16.8932 27.5692C19.6181 30.7367 22.5701 33.6778 26.2032 35.9403C26.8844 36.3928 27.7927 36.8453 28.701 37.2977C29.1552 37.524 29.3822 37.524 29.8364 37.0715C30.9718 35.9403 32.3342 34.5828 33.4696 33.4516C35.0591 31.8679 37.1027 31.8679 38.6922 33.4516C40.7359 35.4878 42.5525 37.2977 44.5961 39.334C46.1856 40.9177 46.1856 42.9539 44.5961 44.7638C43.4607 45.895 42.3254 47.0263 41.19 48.1575C39.6005 49.9674 37.7839 50.4199 35.5132 50.4199C32.3342 50.1937 29.3822 49.2887 26.4303 47.705C20.0723 44.5376 14.6225 40.2389 10.0811 35.0353C6.4479 30.7367 3.72303 26.438 1.90645 21.4606ZM50.5 25.0806C50.5 11.5059 39.3734 0.419922 25.7491 0.419922V5.17105C36.8756 5.17105 45.9585 14.2208 45.9585 25.0806H50.5ZM36.8756 25.0806H41.6442C41.6442 16.257 34.3778 9.24345 25.7491 9.24345V13.9946C28.701 13.9946 31.653 15.1258 33.6966 17.162C35.7403 19.4244 36.8756 22.1394 36.8756 25.0806Z" fill="#8EB50D"/>
                                                </svg>
                                                </div>
                                                                <br />
                                                <p className='iconLabel-deposit'>{t("hotline")}</p>
                                                    <p class="iconSubLabel-deposit">{t('hotline number')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            <div className="p-3 col-12">
                                <div className='row'>
                                                <div className="mb-2 col-4 colgray" style={{ textTransform: 'uppercase' }}>{t('amount')}:</div>
                                    <div className="mb-2 col-4" >{displayAmount(amount)}</div> 
                                </div>

                                <div className='row'>
                                                <div className="mb-2 col-4 colgray" style={{ textTransform: 'uppercase' }}>{t('bank account name')}:</div>
                                    <div className="mb-2 col-4" >{bankAccountName}</div> 
                                </div>
                            </div>
                        }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                            
                            {
                                success || ((errors && errors.bank === "No bank") || isBlacklisted || isClosed) || isError400 || ongoingSubscription ?
                                    paymentOption === 5 ?
                                    <a href={t('payment_wincoin_url')} target="_Blank" onClick={handleClose} className={classname("btn btn-success btn-loader")}>
                                        {t('purchase')}
                                    </a>
                                    :
                                    <button onClick={handleClose} className={classname("btn btn-success")}>
                                    {t('close')}
                                    </button>
                                : 
                                    <button onClick={handleClose} className={classname("btn btn-success")}>
                                    {t('cancel')}
                                    </button>
                            }
    
        
                        {
                            success || ((errors && errors.bank === "No bank") || isBlacklisted || isClosed) || isError400 || ongoingSubscription ? '' : <button onClick={onSubmit} disabled={submitDisabled} className={classname("btn btn-secondary btn-loader")}>{t('confirm')}</button>


                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default ConfirmDeposit;
