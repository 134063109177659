import { all } from 'redux-saga/effects';
import { transactionHistoryWatcher } from './transaction';
import { bannerWatcher } from './banner';
import { playerWatcher } from './player';
import { paymentWatcher } from './payment';
import { authenticationWatcher } from './authentication';
import { registrationWatcher } from './registration';
import { promotionWatcher } from './promotion';
import { casinoWatcher } from './casino';
import { miniWatcher } from './mini';
import { sportsWatcher } from './sports';
import { pageWatcher } from './page';
import { bonusWatcher } from './bonus';
import { jackpotWatcher } from './jackpot';
import { slotsWatcher } from './slot';
import { partnerWatcher } from './partner';
import { productWatcher } from './product';
import { ipWatcher } from './ipaddress';
import { historyWatcher } from './history';
import { bankWatcher } from './bank';
import { providerWatcher } from './provider';
import { errorsWatcher } from './errors';
import { cashWatcher } from './cash';
import { virtualWatcher } from './virtual';
import { p2pWatcher } from './p2p';
import { arcadeWatcher } from './arcade';
import { rebateWatcher } from './rebate';
import { landingPageWatcher } from './landingpage';
import { miniGameWatcher } from './minigame';
import { cashbackWatcher } from './cashback';
import { betConstructWatcher } from './betconstruct';
import { pokerWatcher } from './poker';

export default function* rootSaga() {
    yield all([
        transactionHistoryWatcher(),
        bannerWatcher(),
        authenticationWatcher(),
        playerWatcher(),
        paymentWatcher(),
        registrationWatcher(),
        promotionWatcher(),
        casinoWatcher(),
        miniWatcher(),
        sportsWatcher(),
        pageWatcher(),
        bonusWatcher(),
        jackpotWatcher(),
        slotsWatcher(),
        productWatcher(),
        ipWatcher(),
        partnerWatcher(),
        historyWatcher(),
        bankWatcher(),
        providerWatcher(),
        errorsWatcher(),
        cashWatcher(),
        virtualWatcher(),
        p2pWatcher(),
        arcadeWatcher(),
        rebateWatcher(),
        landingPageWatcher(),
        miniGameWatcher(),
        cashbackWatcher(),
        betConstructWatcher(),
        pokerWatcher(),
    ]);
}
