import React, { Component, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Error from "../../../errors";
import { postPlay, postSports } from "../../../actions/sports";
import { getWithdrawals, getWithdrawalSummary, getWinnerList, getWinnerList30Days, getWinnerListSummary, updateWithdrawals} from "../../../actions/landingpage";
import Login from "./modals/login";
import Notice from "./modals/notice";
import DepositNotice from "./modals/deposit-notice";
import NewNotice from "./modals/new-notice";
import Menu from './Menu';
import { ROOT_URL, findToken } from "../../../config/api";
import ComingSoonModal from '../../common/ComingSoonModal';
import { checkCanRegister } from "../../../actions/registration";
import { getTemplateContent } from "../../../actions/page";
import { notification } from "../../../helpers/landingpage";
import { Reorder } from 'framer-motion/dist/framer-motion';
import { displayAmount, realAmount } from "../../../helpers";
import { notification as notificationWithToken } from "../../../helpers/notification";
import { kickUser, getUnreadMessage, getUnreadNotice } from "../../../actions/authentication";
import Popup from "./popup";
import { Link, useHistory } from "react-router-dom";
import Language from "../../layouts/Header/pages/language";

class Main extends Component {

    state = {
        showLogin: false,
        showComingSoonModal: false,
        canSignUp:false,
        content: '',
        showDepositNotice2: false,
        content2: '',
        showWithdrawal: true,
        showWithdrawalSummary: false,
        withdrawals: [],
        withdrawalSummary: [],
        showWinnerList: false,
        showWinenrListSummary: true,
        winnerList: [],
        winnerListSummary: [],
        winnerList30Days: [],
        noticeCount: 0,
        messageCount: 0,
    };


    handleClose() {
        this.setState({ showLogin: false });
    }

    handleOpen() {
        this.setState({ showLogin: true });
    }

    handleComingSoonClose() {
        this.setState({ showComingSoonModal: false });
    }

    handleComingSoonOpen(e) {
        this.setState({ showComingSoonModal: true });
        e.preventDefault();
    }

    handleCloseNotice() {
        this.setState({ showNotice: false });
        // this.props.getTemplateContent({slug:'second-notice'});
        // this.setState({ showDepositNotice: true });
    }

    handleCloseDepositNotice() {
        this.setState({ showDepositNotice: false });
    }

    handleCloseDepositNotice2() {
        this.setState({ showDepositNotice2: false });
    }

    redirect() {
        window.location = '/sports-betting';
    }

    async componentDidMount() {
        if (localStorage.getItem("appToken")) {
            localStorage.removeItem("persist:root")
            this.props.getUnreadMessage();
            this.props.getUnreadNotice();
        }
        this.updateLang();


        const { i18n } = this.props;
        const language = localStorage.getItem('i18nextLng');
        let lang = '';

        if(language !== null) {
            lang = language.split("-")[0];
        } else {
            lang = 'ko';
        }

       
        // this.props.getTemplateContent({slug:'second-notice', lang: lang[0]});
        //this.props.getWithdrawals();
        //this.props.getWithdrawalSummary();
        this.props.getWinnerList();
        this.props.getWinnerList30Days();
        this.props.checkCanRegister({domain: window.location.hostname === 'localhost' ? 'joa15s.com' : window.location.hostname});
        notification(data => {
            this.processNotification(data);
        });

        notificationWithToken(data => {
            this.processNotification(data);
        });
    }

    processNotification = (data) => {
        const action = data.action;

        switch(action){
            case 'brandNotified':
                if(data.params.message.action === "withdrawalApproved") {
                    const params = {
                        PlayerId: data.playerUser,
                        Amount: data.amount,
                        Date: data.dateTime,
                    }
                    let updatedWithdrawals = this.state.withdrawals;
    
                    if(updatedWithdrawals.length < 10) {
                        updatedWithdrawals.splice(0,0,params);
                    } else {
                        updatedWithdrawals.pop();
                        updatedWithdrawals.splice(0,0,params);
                    }
                    this.setState({withdrawals: updatedWithdrawals});
                } else if(data.params.message.action === "winnerList") {
                    const params = {
                        Username: data.params.message.Username,
                        BetSubTypeID: data.params.message.Subtype,
                        Odds: data.params.message.Odds,
                        BetAmount: data.params.message.BetAmount,
                        WinningAmount: data.params.message.Payout,
                        DateSettled: data.params.message.DateSettled,
                    }

                    let updatedWinnerList = this.state.winnerList;
                    let updatedWinnerList30Days = this.state.winnerList30Days;
                    let updatedWinnerListSummary = this.state.winnerListSummary;
    
                    if(updatedWinnerList.length < 10) {
                        updatedWinnerList.splice(0,0,params);
                    } else {
                        updatedWinnerList.pop();
                        updatedWinnerList.splice(0,0,params);
                    }
    
                    if(updatedWinnerList30Days.length < 10) {
                        updatedWinnerList30Days.splice(0,0,params);
                    } else {
                        updatedWinnerList30Days.pop();
                        updatedWinnerList30Days.splice(0,0,params);
                    }
    
                    if(updatedWinnerListSummary < 10) {
                        updatedWinnerListSummary.splice(0,0,params);
                        updatedWinnerListSummary.sort((a,b) => {
                            return (Number(a.WinningAmount) < Number(b.WinningAmount) ? 1 : -1)
                        })
                    } else {
                        updatedWinnerListSummary.splice(0,0,params);
                        updatedWinnerListSummary.sort((a,b) => {
                            return (Number(a.WinningAmount) < Number(b.WinningAmount) ? 1 : -1)
                        })
                        updatedWinnerListSummary.pop();
                    }
                    this.setState({winnerList: updatedWinnerList, winnerListSummary: updatedWinnerListSummary});
                }
                break;
            default:
                break;
            case 'kickPlayer':
                this.props.kickUser();
            break;
            case 'kickAllPlayer':
                this.props.kickUser();
            break;
        }
    };

    updateLang() {
        var i18 = localStorage.getItem('i18nextLng');
        switch (i18) {
            case "ko":
                localStorage.setItem('i18nextLng', 'ko-KR');
                window.location.reload();
                break;
            case "ko-KR":
                break;
            case "en-US":
                break;
            default:
                localStorage.setItem('i18nextLng', 'ko-KR');
                window.location.reload();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.canRegister.CanSignUp !== undefined) {
            this.setState({canSignUp:nextProps.canRegister.CanSignUp})
        }
        if(nextProps.withdrawals){
            this.setState({withdrawals: nextProps.withdrawals})
        }
        if(nextProps.withdrawalSummary){
            this.setState({withdrawalSummary: nextProps.withdrawalSummary})
        }
        if(nextProps.winnerList){
            this.setState({winnerList: nextProps.winnerList})
        }
        if(nextProps.winnerList30Days){
            this.setState({winnerList30Days: nextProps.winnerList30Days})
        }
        if(nextProps.winnerListSummary){
            this.setState({winnerListSummary: nextProps.winnerListSummary})
        }
        const {unreadNotice,unreadMessage} = nextProps.unread
        const unReadMessageCount = unreadMessage && unreadMessage.unread_message_replies_count !== undefined ? unreadMessage.unread_message_replies_count : 0
        const unReadNoticeCount = unreadNotice && unreadNotice.unread_notice_count !== undefined ? unreadNotice.unread_notice_count : 0
        this.setState({
            noticeCount: unReadNoticeCount,
            messageCount: unReadMessageCount,
        })
    }

    render() {
        const { t, i18n } = this.props;
        let emailTextDynamicStyling = {position:'absolute',cursor:'auto'};

        if(this.state.canSignUp) {
            emailTextDynamicStyling.right = '15px';
            emailTextDynamicStyling.bottom = '-20px';
        } else {
            emailTextDynamicStyling.right = '41px';
            emailTextDynamicStyling.bottom = '-10px';
        }
        const totalMsg = this.state.noticeCount + this.state.messageCount;
        return (
        <React.Fragment>
            <div className="landing-page">
                <div className="header align-items-center">
                    <div className="container-fluid">
                        <div className="row justify-content-between m-0" style={{position:'relative'}}>
                            <a href="/">
                                <div className="col-auto logo p-0">
                                    <img src="/assets/img/logo/daebak-bet-logo.svg" alt="Logo" />
                                </div>
                            </a>  

                            {
                                findToken() ? 
                                <div className="row align-items-center col-auto btns-section m-0 account-icons">
                                     <div class="col-auto">
                                        <a href="/myaccount/deposit" class="icon-box currency" onClick={()=> this.resetToastPopUp()} ></a>
                                    </div>
                                    <div class="col-auto">
                                        <a href="/myaccount/messages" class="icon-box messages">
                                        {totalMsg > 0 ? (
                                            <div class="badge badge-danger">
                                                {/* {this.props.message} */}
                                                { totalMsg }
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        </a>
                                    </div>
                                    <div class="col-auto">
                                        <a href="/myaccount/profile" class="icon-box account"></a>
                                    </div>
                                    <div className="lang_select">
                                        <Language/>
                                    </div>
                                </div>
                                :
                                <div className="row align-items-center col-auto btns-section m-0">
                                    <a className="btn btn-transparent btn-login" data-toggle="modal" onClick={() => this.handleOpen()}>{t('login')}</a>
                                    {/* <a href="/register" className="btn btn-success" id="createAccount" name="Create an Account">지금 가입</a> */}
                                    {
                                        this.state.canSignUp ? <a href="/register" className="btn btn-secondary" id="createAccount" name="Create an Account">{t('register')}</a> : ''
                                    }
                                    <div className="lang_select">
                                        <Language/>
                                    </div>
                                </div>
                            }
                            <div style={emailTextDynamicStyling}><span style={{fontSize:'17px'}}>{t('cs support email')}</span></div>
                        </div>
                    </div>
                    <Login show={this.state.showLogin} handleClose={this.handleClose.bind(this)} handleComingSoonOpen={this.handleComingSoonOpen.bind(this)} />
                    {/* <ComingSoonModal show={this.state.showComingSoonModal} handleClose={this.handleComingSoonClose.bind(this)} /> */}
                </div>
                <div className="content-section m-0 row align-items-center justify-content-center">
                    <div className="col content-box">
                        <div className="text">
                            {
                             i18n.exists("homepage header") && <div dangerouslySetInnerHTML={{ __html: t('homepage header') }} />
                            }
                        </div>
                        <div className="players">
                            <img className="player-img animateZoom" src="/assets/img/banners/landingpage/players.png" />
                            <Menu handleOpen={this.handleOpen.bind(this)}/>
                        </div>
                        <div className="table-data row tabs-section">
                            <div className="col tbl-container">
                                <nav className="d-flex justify-content-center">
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className={`nav-item nav-link ${this.state.showWinenrListSummary? " active" : ""}`} onClick={() => this.setState({showWinenrListSummary: true, showWinnerList: false})} id="tbl2-nav-2-tab" data-toggle="tab" role="tab" aria-controls="tbl2-nav-2" aria-selected="false">{t('top winners 7 days')}</a>
                                    </div>
                                </nav>
                                <div className="tab-content mt-3" id="nav-tabContent">
                                    <div className={`tab-pane fade ${this.state.showWinenrListSummary ? "show active" : ""}`} id="tbl2-nav-2" role="tabpanel" aria-labelledby="tbl2-nav-2-tab">
                                        <Reorder.Group as="table" values={this.state.winnerList} className="table fixed-tbl-header table-striped table-dark">
                                            <thead>
                                                <tr>
                                                    <th>{t('player id')}</th>
                                                    <th>{t('multi')}</th>
                                                    <th>{t('odds')}</th>
                                                    <th className="txt-align-right">{t('bet amount')}</th>
                                                    <th className="txt-align-right">{t('win amount')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.winnerList30Days.map((winner, key) => {
                                                        return (
                                                            <Reorder.Item as="tr" key={winner.DateSettled} value={winner.WinningAmount}>
                                                                <td>{winner.Username}</td>
                                                                <td>{winner.BetSubTypeID}</td>
                                                                <td>{winner.Odds}</td>
                                                                <td className="txt-align-right">{displayAmount(winner.BetAmount)}</td>
                                                                <td className="txt-align-right">{displayAmount(winner.WinningAmount)}</td>
                                                            </Reorder.Item>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Reorder.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col tbl-container">
                                <nav className="d-flex justify-content-center">
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a className={`nav-item nav-link ${this.state.showWinenrListSummary? " active" : ""}`} onClick={() => this.setState({showWinenrListSummary: true, showWinnerList: false})} id="tbl2-nav-2-tab" data-toggle="tab" role="tab" aria-controls="tbl2-nav-2" aria-selected="false">{t('top winners 24 hours')}</a>
                                    </div>
                                </nav>
                                <div className="tab-content mt-3" id="nav-tabContent">
                                    <div className={`tab-pane fade ${this.state.showWinenrListSummary ? "show active" : ""}`} id="tbl2-nav-2" role="tabpanel" aria-labelledby="tbl2-nav-2-tab">
                                        <Reorder.Group as="table" values={this.state.winnerList} className="table fixed-tbl-header table-striped table-dark">
                                            <thead>
                                                <tr>
                                                    <th>{t('player id')}</th>
                                                    <th>{t('multi')}</th>
                                                    <th>{t('odds')}</th>
                                                    <th className="txt-align-right">{t('bet amount')}</th>
                                                    <th className="txt-align-right">{t('win amount')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.winnerList.map((winner, key) => {
                                                        return (
                                                            <Reorder.Item as="tr" key={winner.DateSettled} value={winner.WinningAmount}>
                                                                <td>{winner.Username}</td>
                                                                <td>{winner.BetSubTypeID}</td>
                                                                <td>{winner.Odds}</td>
                                                                <td className="txt-align-right">{displayAmount(winner.BetAmount)}</td>
                                                                <td className="txt-align-right">{displayAmount(winner.WinningAmount)}</td>
                                                            </Reorder.Item>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Reorder.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup/> 
        </React.Fragment>
        );
    }
}

Main.propTypes = {
    postPlay: PropTypes.func.isRequired
};

Main.defaultProps = {
    sports: {
        result: {
            url: ""
        }
    }
};

const mapStateToProps = (state) => ({
    sports: state.sports,
    canRegister: state.register.canRegister,
    withdrawals: state.landingpage.withdrawals,
    withdrawalSummary: state.landingpage.withdrawalSummary,
    winnerList: state.landingpage.winnerList,
    winnerListSummary: state.landingpage.winnerListSummary,
    winnerList30Days: state.landingpage.winnerList30Days,
    unread: state.auth,
});

export default connect(mapStateToProps, { postPlay, postSports,checkCanRegister,getTemplateContent, getWithdrawals, getWithdrawalSummary, getWinnerList, getWinnerList30Days, getWinnerListSummary, updateWithdrawals, kickUser, getUnreadMessage, getUnreadNotice})(
    withTranslation()(withRouter(Main))
);
