import {
    SET_PROMO_BANNERS,
    SET_PRODUCTS,
    SET_PROVIDERS,
    SET_PRODUCT,
    SET_PROMOTIONS,
    LOAD_PROMOTIONS,
    SET_PROMO_CONTENT,
    SET_PROMO_MIN_DEPOSIT,
    RESET_PROMO_MIN_DEPOSIT,
    SET_DEPOSIT_PROMOTIONS,
    SET_DEPOSIT_PROMOTION_BONUS,
    GET_DEPOSIT_PROMOTIONS,
    SET_DEPOSIT_PROMOTIONS_CHECK,
    GET_DEPOSIT_PROMOTIONS_CHECK,
    GET_PROMOTION_CONTENT_CATEOGRY,
    SET_PROMOTION_CONTENT_CATEOGRY,
    SET_HOTLINK_PROMOTION
} from "../actions/types";

const initialState = {
    banners: [],
    promotions: [],
    providers: [],
    content: [],
    promoMinDeposit: [],
    depositPromotions: [],
    promotionID: null,
    selectedPromotion: null,
    loading: false,
    hasPendingSubcription: false,
    hasPendingCashback: false,
    loadingPendingSubscription: true,
    promotionCategory: [],
    loadingCategory: false,
    hotlinkPromotion: null,
};

export default function(state = initialState, action) {

  switch (action.type) {
      case SET_PROMO_BANNERS:
          return {
              ...state,
              banners: action.payload
          };
      case SET_PROMOTIONS:
          return {
              ...state,
              promotions: action.payload,
              isLoading: false
          };
      case SET_PROMO_CONTENT:
          return {
              ...state,
              content: action.payload
          };
      case SET_PRODUCTS:
          return {
              ...state,
              products: action.payload
          };
      case SET_PROVIDERS:
          return {
              ...state,
              providers: action.payload
          };
      case SET_PRODUCT:
          return {
              ...state,
              product: action.payload
          };
      case SET_PROMO_MIN_DEPOSIT:
          return {
              ...state,
              promoMinDeposit: action.payload
          };
      case RESET_PROMO_MIN_DEPOSIT:
          return {
              ...state,
              promoMinDeposit: []
          };
      case LOAD_PROMOTIONS:
          return {
              ...state,
              isLoading: true
          };
        case SET_DEPOSIT_PROMOTIONS:
            // const d = new Date();
            // let day = d.getDay()
            // const promos = action.payload;
            // const id = action.id;
            // const filtered = promos.filter((data) => {
            //     return data.PromotionID === parseInt(id);
            // })
            return {
                ...state,
                depositPromotions: action.payload,
                loading:false,
                // hotlinkPromotion: id ? filtered : null
            }
        case SET_DEPOSIT_PROMOTION_BONUS:
            return {
                ...state,
                promotionID: action.promotionID,
                selectedPromotion: action.promotion
            }
        case GET_DEPOSIT_PROMOTIONS:
            return {
                ...state,
                loading: true,
            }
        case GET_DEPOSIT_PROMOTIONS_CHECK: 
            return {
                ...state,
                loadingPendingSubscription: true
            }
        case SET_DEPOSIT_PROMOTIONS_CHECK: 
            if(action.payload){
                if(action.payload.subscription){
                    return {
                        ...state,
                        hasPendingSubcription: true,
                        loadingPendingSubscription: false
                    }
                } else {
                    return {
                        ...state,
                        hasPendingCashback: true,
                        loadingPendingSubscription: false
                    }
                }
            } else {
                return {
                    ...state,
                    loadingPendingSubscription: false
                }
            }
        case GET_PROMOTION_CONTENT_CATEOGRY:
            return {
                ...state,
                loadingCategory: true
            }
        case SET_PROMOTION_CONTENT_CATEOGRY:
            return {
                ...state,
                promotionCategory: action.payload,
                loadingCategory: false
            }
        case SET_HOTLINK_PROMOTION:
            return {
                ...state,
                hotlinkPromotion: action.payload
            }
      default:
          return state;
  }
}
