import React from 'react';
import { withTranslation } from 'react-i18next';

const DailyCashback = (props) => {
    const { t } = props;

    return (
        <div id="vip-club-cashback-table">
            <h2 className="form-title">SPORTS BETTING REBATE (INCLUDING E-SPORTS)</h2>
            <div className="vip-club-table-wrap">
                <table className="rolling-bonus-benefits">
                    <thead>
                        <tr>
                            <th width="20%" className="bold-small-text border-left-width-0 border-top-width-0 gray-text">WEEKLY CASHBACK</th>
                            <th className="bold-text bronze border-top-width-0">Bronze</th>
                            <th className="bold-text silver border-top-width-0">Silver</th>
                            <th className="bold-text gold border-top-width-0">Gold</th>
                            <th className="bold-text diamond border-top-width-0 border-right-width-0">Diamond</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">BTI</td>
                            <td rowSpan="8" className="no-padding uppercase border-bottom-width-0">NO</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">BETRADAR</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">E-SPORTS</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">LIVE CASINO</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">TV Casino</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">SLOT</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0">POWERBALL</td>
                            <td>3% / 50,000</td>
                            <td>3% / 100,000</td>
                            <td className="border-right-width-0">3% / 300,000</td>
                        </tr>
                        <tr className="level-color">
                            <td className="bold uppercase border-left-width-0 border-bottom-width-0">MINIGAMES</td>
                            <td className="border-bottom-width-0">3% / 50,000</td>
                            <td className="border-bottom-width-0">3% / 100,000</td>
                            <td className="border-bottom-width-0 border-right-width-0">3% / 300,000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 className="form-title">VIP LEVELS</h2>
            <div class="d-flex justify-content-center mb-5">
                <div class="vip-levels"><img src="/assets/img/vip/chip-bronze.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-silver.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-gold.png" alt="" class="img-fluid" /></div>
                <div class="vip-levels"><img src="/assets/img/vip/chip-diamond.png" alt="" class="img-fluid" /></div>
            </div>
            <div className="mb-5">
                <h2 className="form-title">GENERAL TERMS & CONDITIONS</h2>
                <ol id="vip-tnc">
                    <li>Daebakbet new experience bonus 100% 50,000 + 50,000 paid!</li>
                    <li>Welcome bonus 50% up to 300,000 / 20% up to 500,000</li>
                </ol>
            </div>
        </div>
    );
}

export default withTranslation()(DailyCashback);
