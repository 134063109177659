import {
    CASHBACK_GET,
    CASHBACK_POST,
} from "./types";

export const getCashbackBalance = () => {
    return {
        type: CASHBACK_GET
    };
};

export const postCashbackBalance = payload => {
    return {
        type: CASHBACK_POST,
        payload
    };
};