import axios from "axios";
import { ROOT_URL, findToken } from "../config/api";

export { getCashbackBalance, postCashbackBalance };

function getCashbackBalance() {
    return axios.get(`${ROOT_URL}/getCashbackBalance`,{
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}

function postCashbackBalance(params) {
    return axios.post(`${ROOT_URL}/transferCashback`, params, {
      headers: {
        Authorization: `Bearer ${findToken()}`
      }
    });
}
