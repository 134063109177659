import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from "redux-react-hook";
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import BtnLoader from '../../../common/BtnLoader';
import WarningIcon from '../../../../assets/img/icons/caution-icon.svg'

import { thousandAmount, realAmount } from '../../../../helpers';
import { setDepositPromotionBonus, setDepositHotlink } from "../../../../actions/promotion";

const DepositPromotions = ({show, handleClose, amount, minAmount}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { depositPromotions, loading, hasPendingSubcription, hasPendingCashback, loadingPendingSubscription, hotlinkPromotion } = useSelector(state => state.promotions);
    const d = new Date();
    let day = d.getDay() === 0 ? 7 : d.getDay();
    let { id } = useParams();

    const [inputAmount, setInputAmount] = useState(0);
    const [promo, setPromo] = useState([]);

    const selectDepositPromotion = (id, promotion) => {
        if(!id && !promotion) {
            dispatch(setDepositPromotionBonus(id, promotion));
            handleClose();
        } else {
            if(!hasPendingSubcription){
                if(promotion.SubType !== 15 && !hasPendingCashback){
                    if(parseFloat(inputAmount) >= parseFloat(promotion.PromotionCurrency.MinDeposit) || id == null){
                        dispatch(setDepositPromotionBonus(id, promotion));
                        handleClose();
                    }
                } else if(promotion.SubType === 15 ) {
                    if(parseFloat(inputAmount) >= parseFloat(promotion.PromotionCurrency.MinDeposit) || id == null){
                        dispatch(setDepositPromotionBonus(id, promotion));
                        handleClose();
                    }
                }
            }
        }
    }

    useEffect(() => {
        setInputAmount(amount ? realAmount(amount) : 0);
    }, [amount]);

    useEffect(() => {
        if(hotlinkPromotion && hotlinkPromotion.length !== 0){
            const days = hotlinkPromotion[0].RecurringDays.map((data) => {
                return data.DayNumber;
            })
            if(!hasPendingSubcription && !loadingPendingSubscription) {
                if(hotlinkPromotion[0].RecurringDays.length !== 0){
                    if(days.includes(day)){
                        dispatch(setDepositPromotionBonus(hotlinkPromotion[0].PromotionID, hotlinkPromotion[0]));
                    }
                } else {
                    dispatch(setDepositPromotionBonus(hotlinkPromotion[0].PromotionID, hotlinkPromotion[0]));
                }
            }
        }
    }, [hotlinkPromotion, loadingPendingSubscription]);

    useEffect(() => {
        if(depositPromotions.length !== 0 || depositPromotions){
            const d = new Date();
            let data = []
            if (depositPromotions.length > 0) {
                depositPromotions.map(promotion => {
                    if (promotion.SubType === 2) {
                        if (promotion.RecurringDays.length === 0) {
                            data.push(promotion)
                        } else {
                            promotion.RecurringDays.map(item => {
                                let day = d.getDay() === 0 ? 7 : d.getDay();
                                if(item.DayNumber === day) {
                                    data.push(promotion)
                                }
                            })
                        }
                    } else {
                        data.push(promotion)
                    }
                });
            }
            data.map( (promotion, i) =>  {  
                let products = [...new Map(promotion.Products.map(item =>
                [item.Data['ID'], item])).values()];
                data[i].FilteredProduct = products;
            });
            setPromo(data);
            if(id) {
                if(hotlinkPromotion == null){
                    const filtered = depositPromotions.filter((data) => {
                        return data.PromotionID === parseInt(id);
                    })
                    if(filtered.length != 0){
                        dispatch(setDepositHotlink(filtered));
                    }
                }
            }
        }
    }, [depositPromotions])
    return (
        <React.Fragment>
            <div
                className="modal fade show promo-bonus-list"
                style={{
                    display: show ? "block" : "none"
                }}
                id="DepositModal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="DepositModal" 
                aria-modal="true"
            >
                <Modal
                    className='promo-bonus-list'
                    centered
                    show={show}
                    onHide={() => selectDepositPromotion(null, null)}
                >
                    <Modal.Header>
                        <p className="modal-title" id="LoginModal">{t('select promotion')}</p>
                        <button
                            type="button"
                            className="close"
                            onClick={() => selectDepositPromotion(null, null)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body text-center">
                    <div 
                        class="row m-0" 
                        style={{
                            maxHeight: promo.length !== 0 && !loading && !loadingPendingSubscription  ? '320px' : '', 
                            overflowY: promo.length !== 0 ? 'auto': '',
                            justifyContent: promo.length === 0 ? 'center': '',
                            alignItems: promo.length === 0 ? 'center': '',
                        }}
                    >
                        {
                            !loading && !loadingPendingSubscription && promo.length !== 0 ? 
                            promo.map((promotion) => {
                                const days = promotion.RecurringDays.map((day) => {
                                    return day.DayNumber === 0 ? 7 : day.DayNumber;
                                })
                                return (
                                    <React.Fragment>
                                        {
                                            days.includes(day) || days.length === 0 ?
                                            <div class="col-6 p-0">
                                                <div 
                                                    class="cardTransfer" 
                                                    data-dismiss="modal" 
                                                    onClick={() => selectDepositPromotion(promotion.PromotionID, promotion)}
                                                    style={{
                                                        // opacity: parseFloat(inputAmount) < parseFloat(promotion.PromotionCurrency.MinDeposit) || hasPendingSubcription  ? '0.5' : 'unset'
                                                    }}
                                                >
                                                    <a href="#" class="select" onClick={() => selectDepositPromotion(promotion.PromotionID, promotion)}>{t('select')}  <i class="ml-1 fa fa-chevron-right" aria-hidden="true"></i></a>
                                                    <p class="provider-type promotion-name deppromo-title">{promotion.PromotionTitle === null ? promotion.Code : promotion.PromotionTitle}</p>
                                                    <div class="row m-0 highlighted mt-2 mb-2">
                                                    <div class="col-auto p-0 pr-1">
                                                        {promotion.SubType === 1 ? <p>{promotion.BonusType === 1 ? t('bonus amount display', {bonusAmount: realAmount(promotion.Bonus), maxBonusAmount: thousandAmount(promotion.PromotionCurrency.MaxBonus)}) : `${thousandAmount(promotion.Bonus)} ${t('bonus')}`}</p> : null}
                                                        {promotion.SubType === 2 ? <p>{promotion.BonusType === 1 ? t('bonus amount display', {bonusAmount: realAmount(promotion.PromotionCurrency.TotalMaxBonus), maxBonusAmount: thousandAmount(promotion.PromotionCurrency.MaxBonus)}) : `${thousandAmount(promotion.PromotionCurrency.TotalMaxBonus)} ${t('bonus')}`}</p> : null}
                                                        {promotion.SubType === 15 ? <p>{promotion.BonusType === 1 ? t('cashback amount display', {bonusAmount: realAmount(promotion.Bonus), maxBonusAmount: thousandAmount(promotion.PromotionCurrency.MaxBonus)}) : `${thousandAmount(promotion.Bonus)} ${t('cashback')}`}</p> : null}
                                                    </div>
                                                    <div class="col-auto p-0 pl-1">
                                                        <p>{t('wagering')}: {promotion.WagerAmount}x</p>
                                                    </div>
                                                    </div>
                                                    <p><span>{t('minimum deposit')}:</span> {thousandAmount(promotion.PromotionCurrency.MinDeposit)}</p>
                                                    <p><span>{t('minimum odds')}:</span> {promotion.MinimumOdds}</p>
                                                    {
                                                        promotion.SubType !== 15 ?
                                                        <p><span>{t('valid on')}: </span> 
                                                            <span className='products-list'>
                                                            {
                                                                promotion.FilteredProduct.length > 0 
                                                                ? 
                                                                promotion.FilteredProduct.map((product, index) => {
                                                                let x =  [];

                                                                return (
                                                                    <span key={index}>
                                                                    <span className='product-name'>{t(product.Data.Name.toLowerCase())}{' '}</span>
                                                                    <span className='product-provider-name'>({
                                                                
                                                                    promotion.Products.map((p, i) => {
                                                                        if (product.Data.ID === p.Data.ID) {
                                                                        x.push(t(p.Data.ProviderName.toLowerCase()))
                                                                        }
                                                                    })
                                                                    }<>{x.join(', ')}</>)</span>{
                                                                    promotion.FilteredProduct.length === 1 ? '' : index < promotion.FilteredProduct.length - 1 ? ', ' : ''
                                                                    }
                                                                </span>
                                                                )
                                                                })
                                                                : ''
                                                            }
                                                            </span>
                                                        </p>
                                                        : <></>
                                                    }
                                                    {
                                                        hasPendingSubcription ?
                                                        <div className='dep-promo-warning'>
                                                            <img src={WarningIcon} style={{width: '25px'}}/> <span className='ml-2'>{t('you have on going wagering requirements')}</span>
                                                        </div>
                                                        :
                                                        promotion.SubType !== 15 && hasPendingCashback ?
                                                        <div className='dep-promo-warning'>
                                                            <img src={WarningIcon} style={{width: '25px'}}/> <span className='ml-2'>{t('you have ongoing cashback subscription')}</span>
                                                        </div>
                                                        :
                                                        parseFloat(inputAmount) < parseFloat(promotion.PromotionCurrency.MinDeposit) ?
                                                        <div className='dep-promo-warning'>
                                                            <img src={WarningIcon} style={{width: '25px'}}/> <span className='ml-2'>{t('deposit amount must be at least') + " " + thousandAmount(promotion.PromotionCurrency.MinDeposit)}</span>
                                                        </div>
                                                        : <></>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            <></>
                                        }
                                    </React.Fragment>
                                )
                            })
                            : 
                            !loading && !loadingPendingSubscription && promo.length === 0 ?
                            <div className='row'>
                                <div class="col-12 p-4">
                                    <img src={WarningIcon} style={{width: '50px', marginBottom: '20px'}}/>
                                    <p>{t('no promotion to show')}</p>
                                </div>
                            </div>
                            : <></>
                        }
                    </div>
                    <div class="btn-container mt-3 mb-3">
                        <p class="or">{t('or')}</p>
                    </div>
                    <div class="btn btn-success" onClick={() => selectDepositPromotion(null, null)}>
                        <p class="provider-type provider-name">{t('i want rebate instead')} <i class="ml-1 mr-0 fa fa-chevron-right" aria-hidden="true"></i></p>
                    </div>
                    </Modal.Body>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default DepositPromotions;
