import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser, processMainWalletMesage, updateBadgeCount,getUnreadMessage, updateUnreadMessage, getUnreadNotice, kickUser } from "../../../../actions/authentication";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import { Loader } from "../../../common";
import { displayAmount, totalAmount, realAmount } from "../../../../helpers";
import { getPlayer, getWallet, clearWallet, processWalletMesage } from "../../../../actions/player";
import { getActiveProviders, resetActiveCount } from '../../../../actions/provider';
import { getRebateBalance, processRebateMesage } from '../../../../actions/rebate';
import { getCashbackBalance } from '../../../../actions/cashback';
import { withTranslation } from "react-i18next";
import { findToken, ROOT_URL } from "../../../../config/api";
import { notification } from "../../../../helpers/notification";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import Langauge from "./language";
import PlayerDropdown from "./playerDropdown";
import {
    resetResendDeposit,
    getBankAccount,
    resetDeposit,
    resetResendError,
} from "../../../../actions/payment";
import IdleTimer from 'react-idle-timer';

import NoticeIcon from '../../../../assets/img/Bell-icon.svg';

class LoginHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spin: false,
            page: "JOA스포츠",
            wallet: [],
            prov: 21,
            mainWallet: 0,
            rebate: 0,
            noticeCount: 0,
            messageCount: 0,
            cashback: 0,
        };
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    handleOnAction (event) {
        // console.log('user did something', event)
    }
    
    handleOnActive (event) {
        // console.log('user is active', event)
        // console.log('time remaining', this.idleTimer.getRemainingTime())
        console.log('reset');
        this.idleTimer.reset();
    }
    
    handleOnIdle (event) {
        console.log('user is idle', event)
        console.log('last active', this.idleTimer.getLastActiveTime())
        this.props.logoutUser(this.props.history);
    }

    onLogout = (e) => {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    };

    refreshWallet = () => {
        this.props.clearWallet();
        this.props.resetActiveCount();
        //this.props.getActiveProviders();
        this.props.getRebateBalance();
        this.props.getPlayer();
        this.props.resetResendDeposit();
        this.props.resetResendError();
        this.props.getCashbackBalance();
    };

    resetToastPopUp = () => {
        this.props.resetResendError();
        this.props.resetResendDeposit();
    }

    processNotification = (data) => {
        const action = data.action;

        // console.log(data)
        // console.log('test - check websocket')
        switch(action){
            case 'closeGame':
                this.props.history.push('/');
                break;
            case 'walletUpdated':
                this.props.processMainWalletMesage({balance:data.balance});
                break;
            case 'rebateWalletUpdated':
                if(data.balance)this.props.processRebateMesage({balance: data.balance});
                break;
            case 'playerNotified':
                if (typeof  data.message === 'string') {
                    toast.success( data.message, {
                        className: "toast toast-success"
                    });
                } else if (typeof data.message === 'object') {
                    if (data.message.count){
                        if (data.message.type === 'notice') {
                            this.props.updateBadgeCount(data.message);
                        }else{
                            // if (data.message.action === 'add') {
                            //     this.props.updateUnreadMessage();
                            //     this.props.updateBadgeCount({type: data.message.type, parentID: data.message.parentID, messageID: data.message.messageID, action: data.message.action});
                            // } else {
                            //     this.props.getUnreadMessage();
                            // }
                            this.props.getUnreadMessage();
                        }
                    }
                    if (data.message.text){
                        toast.success( data.message.text, {
                            className: "toast toast-success"
                        });
                    }
                    if (window.location.pathname.includes("withdrawal") && data.message.status === '2') {
                        this.props.getPlayer();
                    }
                    if (window.location.pathname.includes("deposit")){
                        this.props.resetDeposit();
                    }

                }
                break;
            case 'gameMaintenanceUpdated':
                if(window.location.pathname.includes("live-sports") && data.providerID === 25) {
                    window.location.reload();
                } else if(window.location.pathname.includes("sports-betting") && data.providerID == 21) {
                    window.location.reload();
                }
                break;
            case 'kickPlayer':
                this.props.kickUser();
            break;
            case 'kickAllPlayer':
                this.props.kickUser();
            break;
            default:
                break;
        }
    };

    componentDidMount() {
        this.props.getPlayer();
        this.setProviderName();
        this.props.clearWallet();
        this.props.resetActiveCount();
        this.props.getRebateBalance();
        //this.props.getActiveProviders();
        this.props.getUnreadMessage();
        this.props.getUnreadNotice();
        this.props.getBankAccount({transaction_type: 1000});
        this.props.getBankAccount({transaction_type: 2000});
        this.props.getCashbackBalance();

        // axios
        // .get(`${ROOT_URL}/getUnreadMessageCount`, { 
        //     headers: { 
        //         Authorization: `Bearer ${findToken()}` 
        //     }
        // }).then(response => {
        //     if (response.data.unread_message_replies_count !== undefined) {
        //         this.setState({messageCount: response.data.unread_message_replies_count})
        //     }
        // });

        // axios
        // .get(`${ROOT_URL}/getUnreadNoticeCount`, { 
        //     headers: { 
        //         Authorization: `Bearer ${findToken()}` 
        //     }
        // }).then(response => {
        //     if (response.data.unread_notice_count !== undefined) {
        //         this.setState({noticeCount: response.data.unread_notice_count})
        //     }
        // });

        if(findToken()){
            notification(data => {
                this.processNotification(data);
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let rebate = 0;
        if (nextProps.rebate.success) {
            rebate = nextProps.rebate.balance
        }

        let cashback = nextProps.cashback.balance

        this.setState({wallet: nextProps.walletz, mainWallet: nextProps.wallet, rebate: rebate, cashback: cashback})
        this.setProviderName();

        const {unreadNotice,unreadMessage} = nextProps.unread
        const unReadMessageCount = unreadMessage && unreadMessage.unread_message_replies_count !== undefined ? unreadMessage.unread_message_replies_count : 0
        const unReadNoticeCount = unreadNotice && unreadNotice.unread_notice_count !== undefined ? unreadNotice.unread_notice_count : 0

        // console.log(unReadMessageCount)
        // console.log(unReadNoticeCount)

        this.setState({
            noticeCount: unReadNoticeCount,
            messageCount: unReadMessageCount,
        })
    }

    setProviderName() {
        if(window.location.pathname === "/sports-betting" || window.location.pathname === "/live-sports") {
            this.setState({page: "JOA스포츠", prov: 21})
        } else if(window.location.pathname === "/e-sports") {
            this.setState({page: "DONGSEN", prov: 22})
        } else if(window.location.pathname === "/powerball") {
            this.setState({page:  "JOA Powerball", prov: 23})
        }
    }

    checkAllBalance(){
        const { wallet } = this.state;

        if (this.objectLen(wallet) > 0) {
            delete wallet[0]; //special case for transfer > main wallet
            delete wallet[undefined]; //special case for transfer > main wallet

            return (this.objectLen(this.props.providers) <= this.objectLen(wallet));
        }
    }

    objectLen(obj){
        return Object.keys(obj).length;
    }

    render() {
        let wallet = this.props.wallet || 0;
        let currency = "";

        if (this.props.player) {
            currency = this.props.player.currency.Abbreviation;
        }
        const spin = this.state.spin;
        const badge = this.props.badge;
        const { t } = this.props;

        const allgameswallet = realAmount(totalAmount(this.state.wallet));
        const mainwallet = parseFloat(this.state.mainWallet);
        //const totalwallet = allgameswallet + mainwallet;
        const totalwallet = mainwallet;
        const { unread_notice_count } = this.props.auth.unreadNotice;
        // const { unread_message_replies_count } = this.props.auth.unreadMessage;
        const totalMsg = this.state.noticeCount + this.state.messageCount;

        return (
            <div class="col-auto login-area">
                <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    timeout={1000 * 60 * 60}
                    onActive={this.handleOnActive}
                    onIdle={this.handleOnIdle}
                    onAction={this.handleOnAction}
                    debounce={250}
                    crossTab={{
                        emitOnAllTabs: true
                    }}
                />
                {/* <ToastContainer autoClose={8000} /> */}
                <div class="row align-items-center justify-content-end">
                    <div class="col-auto">
                        <div class="account-details">
                            <div class="wallets">
                                <div class="account-name">
                                    <div class="notice-badge">
                                        <a class href="/myaccount/notices">
                                            <img class="notice-badge-img" src={NoticeIcon} width={22} height={22}/>
                                            { unread_notice_count >= 10 ? <span className="badge badge-danger notice-counter">{"9+"}</span> : unread_notice_count > 0 ? <span className="badge badge-danger notice-counter">{unread_notice_count}</span> : "" }
                                        </a>
                                    </div>
                                    <div className="vip">
                                        <img src={badge} />
                                    </div>
                                    <PlayerDropdown
                                        badge={badge}
                                        username={this.props.username}
                                        walletBalance={displayAmount(totalwallet)}
                                        rebateBalance={displayAmount(this.state.rebate)}
                                        refreshWallet={this.refreshWallet} onLogout={this.onLogout} 
                                        cashbackBalance={displayAmount(this.state.cashback)}
                                        cashbackEnabled={this.props.cashback.cashback_enabled} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto ml-1">
                        <div className="row">
                            <div className="col-auto">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <div className="lang_select">
                                            <Langauge/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginHeader.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    getPlayer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    wallet: state.auth.wallet.wallet,
    player: state.auth.wallet.player,
    username: state.auth.wallet.username,
    // message: state.auth.wallet.message,
    badge: state.auth.wallet.badge,
    coupon: state.auth.wallet.coupon,
    providers: state.providers.providers,
    walletz: state.player.wallet,
    auth: state.auth,
    rebate: state.rebate.rebate,
    unread: state.auth,
    cashback: state.cashback.cashback,
});

export default connect(
    mapStateToProps,
    { logoutUser, getPlayer, getWallet, getActiveProviders, resetActiveCount, clearWallet, getRebateBalance, processWalletMesage, processMainWalletMesage, processRebateMesage,resetResendDeposit, updateBadgeCount, getUnreadMessage, updateUnreadMessage, getBankAccount, resetDeposit, resetResendError, getUnreadNotice, kickUser, getCashbackBalance }
)(withRouter(withTranslation()(LoginHeader)));
