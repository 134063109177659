import React, { Component } from "react";

class BrowserRestriction extends Component {
    render() {
        return (
            <div>
               <header id="header">  
                    <div className="container-fluid">
                        <div className="row align-items-center justify-content-center">
                            <div className="website-logo">
                                <img className="ImgFluid" src="/assets/img/daebak_logo.svg" alt="Logo"/>
                            </div>
                        </div>
                    </div>
                </header>
                <section>
                    <div className="container-fluid">
                        <div className="flex-center-center h-screen">
                            <div className="row">
                                <div className="column">
                                    <div className="d-flex">
                                        <div className="browser-not-supported-icon">
                                            <img src="/assets/img/icons/browser-not-supported.svg" alt="daebak browser not supported" />
                                        </div>
                                    </div>
                                </div>
                                <div className="column browser-not-supported ml-4">
                                    <div class="browser-not-supported-desc">
                                        <h1>BROWSER NOT SUPPORTED</h1>
                                        <div className="box-wrapper">
                                            Looks like you are using an old browser, which is not supported by this website. Please use Google Chrome, Mozilla Firefox or Edge to start playing in Daebakbet
                                        </div>
                                    </div>
                                    <div class="browser-not-supported-desc mt-5">
                                        <h1>브라우저가 작동하지 않습니다</h1>
                                        <div className="box-wrapper">
                                            우리 사이트와 호환이 안되는 구버젼 브라우저를 사용중이신거 같습니다 구글크롬, 모질라, 파이어폭스, 엣지등으로 다시 접속 해주시기 바랍니다
                                        </div>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <div className="flex-start-center">
                                            <img src="/assets/img/icons/chrome.svg" alt="daebak browser google chrome" />
                                            <div className="ml-2">Google Chrome</div>
                                        </div>
                                        <div className="flex-start-center mx-5">
                                            <img src="/assets/img/icons/firefox.svg" alt="daebak browser mozilla firefox" />
                                            <div className="ml-2">Mozilla Firefox</div>
                                        </div>
                                        <div className="flex-start-center">
                                            <img src="/assets/img/icons/safari.svg" alt="daebak browser safari" />
                                            <div className="ml-2">Safari</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}



export default BrowserRestriction;

