import { postPlay } from "../../../api/sports";

export const openPoker = (player) => {
    const language = localStorage.getItem('i18nextLng');

    if (localStorage.getItem("appToken") === null) {
        document.getElementById("login-btn").click();
    } else {
        const params = {
            brandID: 27,
            gameID: 0,
            isMobile: false,
            gameType: 'live',
            launchType: 'html5',
            hashid: '',
            lang: language,
            currency: player.player.currency.Abbreviation,
            mode: 1,
            launchType: 'html5',
            playerID: player.player.PlayerID,
            username: player.username,
            productID: 12,
            providerID: 40,
            integrationID: 58
        };
        postPlay(params).then(response => {
            if (response.data.success) {
                window.location = response.data.game_url;
            } else {
                window.location = "/game-error";
            }
        })
        .catch(error => {
            if (error.response.data.errors) {
                if (error.response.data.errors.maintenance) {
                    // window.location = "poker";
                } else {
                    window.location = "/game-error";
                }
            } else {
                window.location = "/game-error";
            }
        })
    }

    
}