import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import classNames from "classnames";
import Error from "../../common/error";
import { resetPlayerError, postPlayer } from "../../../actions/player";
import { getCurrencies } from "../../../actions/registration";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import SuccessAlert from "../../common/SuccessAlert";
import ReactHtmlParser from 'react-html-parser';
import ko from "date-fns/locale/ko";
registerLocale("ko", ko);

class Profile extends Component {
    constructor(props) {
        super(props);
        const maxDate = new Date();
        maxDate.setDate(maxDate.getDate() - 1);
        maxDate.setFullYear(maxDate.getFullYear() - 20);

        this.state = {
            name: "",
            birthday: "",
            email: "",
            gender: "",
            validated: false,
            errors: {},
            success: {},
            isSubmitted: false,
            maxDate: maxDate,
            showingAlert: false,
            update: false,
            oldnumber: "",
            newnumber: "",
            country: "",
            telegram: "",
            dateRegistered: "",
            ctr: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.mobileNUmberPrefix = '+82';
    }

    handleChange = (date) => {
        this.setState({ birthday: date });
    };

    notify = message => {
        toast.success(message, {
            className: 'toast toast-success profile-toast'
        });
    };

    componentDidMount() {
        this.props.getCurrencies();
        // this.props.history.listen((location, action) => {
        //     if (location.pathname.includes("profile")) {
        //         this.props.resetPlayerError();
        //     }
        // });
        this.props.resetPlayerError();
        if (!this.props.player) return false;
        this.componentWillReceiveProps(this.props);
    }

    componentWillReceiveProps(nextProps) {
        const { isSubmitted } = this.state;
        const errors = nextProps.errors;
        let telegramsz, emailValue;

        this.setState({ errors });

        if (nextProps.player.FirstName) {
            emailValue = nextProps.player.Email;
        }

        if (nextProps.player.DateCreated) {
            let date = nextProps.player.DateCreated.split(" ");
            this.setState({dateRegistered:date[0]})
        }
        
        if(this.props.player.FirstName !== '') {
            this.setState({ email:  emailValue});
        }

        if (nextProps.player.contact) {
            const kak = nextProps.player.contact.filter((val) => {
                return val.TypeID === 7;
            });

            if(kak.length !== 0) {
                telegramsz = kak[0].Info;
            }
        }

        this.setState({ telegram:  telegramsz});

        try {
            if (nextProps.success && isSubmitted) {
                
                if (
                    nextProps.success.message ===
                    "Player details succesfully saved"
                ) {
                    this.setState({ success1: true });
                    this.setState({ update: false });
                    this.setState({ oldnumber: "" });
                    this.setState({ newnumber: "" });
                    this.setState({ ctr: true });

                    if(this.state.ctr){
                        //{this.notify(nextProps.t("player details success"))}
                        setTimeout(() => {
                            this.setState({success1: false});
                        }, 5000);

                        this.setState({ ctr: false });
                    } 
                }
            }
        } catch (error) {
            nextProps.showError();
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    updateAccount() {
        this.setState({
            update: !this.state.update
        });
    };

    onSubmit = (e) => {
        e.preventDefault();

        this.setState({ isSubmitted: true });
        this.setState({ validated: true });
        var d = new Date(this.state.birthday);
        const bday = moment(d).format('YYYY-MM-DD');

        if (this.form.checkValidity()) {
            if(this.state.update === true) {
                let old, newz;
                if(this.state.oldnumber === "" && this.state.newnumber === "") {
                    old = "";
                    newz = "";
                } else {
                    // old = this.props.player.currency.country.PhoneCode + '' + this.state.oldnumber;
                    // newz = this.props.player.currency.country.PhoneCode + '' + this.state.newnumber;
                    // old = `${this.mobileNUmberPrefix}${this.state.oldnumber}`;
                    // newz = `${this.mobileNUmberPrefix}${this.state.newnumber}`;
                    old = `${this.state.oldnumber}`;
                    newz = `${this.state.newnumber}`;
                }
                
                const player = {
                    name: this.state.name !== "" ? this.state.name : this.props.player.FirstName,
                    birthday: this.props.player.BirthDate === null ? bday : this.props.player.BirthDate,
                    gender: this.state.gender !== "" ? this.state.gender : this.props.player.Gender,
                    // email: this.state.email !== "" ? this.state.email : this.props.player.Email,
                    email: this.state.email,
                    oldnumber: old,
                    newnumber: newz,
                    phoneCode: 82,
                    country: this.props.player.currency.country.PhoneCode,
                    telegram: this.state.telegram
                };

                this.props.postPlayer(player);
                this.setState({ validated: false });
            } else {
                const player = {
                    name: this.state.name !== "" ? this.state.name : this.props.player.FirstName,
                    birthday: this.props.player.BirthDate === null ? bday : this.props.player.BirthDate,
                    gender: this.state.gender !== "" ? this.state.gender : this.props.player.Gender,
                    email: this.state.email !== "" ? this.state.email : this.props.player.Email,
                    telegram: this.state.telegram
                };

                this.props.postPlayer(player);
                this.setState({ validated: false });
                this.setState({ update: false });
            }
        }
    };

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    render() {
        
        const email = this.state.email;
        const { errors } = this.state;

        let firstname = "";
        let phone, emailValue, birthDate, genderValue, test;
        let currency, country, phoneCode, telegramsz;

        if (this.props.player) {
            currency = this.props.player.currency.Abbreviation;
            country = this.props.player.address.country.Name;
            birthDate = this.props.player.BirthDate;
        }

        if(this.props.player.currency.country) {
            phoneCode = this.props.player.currency.country.PhoneCode;
        }

        if (this.props.player.FirstName) {
            firstname = this.props.player.FirstName;
            emailValue = this.props.player.Email;
            genderValue = this.props.player.Gender === "m" ? "male" : this.props.player.Gender === "f" ? "female" : "";
        }

        if (this.props.player.contact) {
            const pz = this.props.player.contact.filter((val) => {
                return val.TypeID === 2;
            });
            phone = "+" + pz[0].Info;
            const kak = this.props.player.contact.filter((val) => {
                return val.TypeID === 7;
            });

            if(kak.length !== 0) {
                telegramsz = kak[0].Info;
            }
        }

        const { t, i18n } = this.props;

        const kakaotalk = this.state.telegram;

        test = String(phone);
        test = test.replace('+', '');
        test = this.mobileNUmberPrefix + "10 **** " + test.substr(test.length - 4);

        return (
            <form
                noValidate
                className={classNames({
                    "needs-validation was-validated": this.state.validated
                })}
                onSubmit={this.onSubmit.bind(this)}
                ref={(form) => (this.form = form)}
            >
                {this.props.username !== undefined ?
                (
                    this.state.update ? 
                    (
                        <React.Fragment>
                            {/* <h2 class="formTitle">{t("my account details")}</h2> */}
                            <div class="accountInfo accountInfo-w365 mb-5">
                                <div id="account-details">
                                    <div className="row m-0">
                                        <div class="col-md-3">
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("username")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{this.props.username}</p>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("full name")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{firstname}</p>
                                                    </div> 
                                                </div> 
                                            </div>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("birthday")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{birthDate}</p>
                                                    </div> 
                                                </div> 
                                            </div>
                                            {
                                                this.props.player.Gender ?
                                                <div class="colLeftInfos">
                                                    <div class="form-group row">
                                                        <div class="col-3 col-form-label labelColor">{t("gender")}</div>
                                                        <div class="col"> 
                                                            <p class="col text_display infoColor">{t(genderValue)}</p>
                                                        </div> 
                                                    </div> 
                                                </div>
                                                :
                                                <div class="form-group row align-items-center">
                                                    <div class="col-3 col-form-label labelColor">{t("gender")} *</div>
                                                    <div class="col">
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                id="genderM"
                                                                value="m"
                                                                onChange={this.onChange}
                                                                required
                                                            />
                                                            <label class="form-check-label labelColor" for="inlineCheckbox1">{t("male")}</label>
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                name="gender"
                                                                id="genderF"
                                                                value="f"
                                                                onChange={this.onChange}
                                                                required
                                                            />
                                                            <label class="form-check-label labelColor" for="inlineCheckbox2">{t("female")}</label>
                                                        </div>
                                                        <div
                                                            className={classNames(
                                                                "invalid-tooltip",
                                                                {
                                                                    "d-block":
                                                                        this.state.gender === "" &&
                                                                        this.state.validated
                                                                }
                                                            )}
                                                        >
                                                            {t("this field is required")}
                                                        </div>
                                                    </div>
                                                </div> 

                                            }
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("currency")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{currency}</p>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("country")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{country}</p>
                                                </div> 
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("date registered")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{this.state.dateRegistered}</p>
                                                </div> 
                                            </div>
                                            <div class="form-group row">
                                                <div class="col p-0">
                                                    <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("email")} *</label>
                                                    <div class="input-group padLabel">
                                                        <div class="input-group-prepend">
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            className={classNames(
                                                                "form-control dark",
                                                                {
                                                                    "is-invalid": errors.email
                                                                }
                                                            )}
                                                            value={email ? email.toLowerCase() : ""}
                                                            onChange={this.onChange}
                                                        />
                                                        {/* <Error errors={errors} id="email" /> */}
                                                        <div className="invalid-tooltip">{errors.email ? t(errors.email[errors.email.length - 1].toLowerCase()) : null}</div>
                                                        </div>
                                                </div>
                                            </div> 
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("contact number")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{test}</p>
                                                </div> 
                                            </div> 
                                            <div class="form-group row">
                                                <div class="col p-0">
                                                    <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("telegram")}</label>
                                                    <div class="input-group padLabel">
                                                        <div class="input-group-prepend">
                                                        </div>
                                                        <input
                                                            type="text"
                                                            name="telegram"
                                                            id="telegram"
                                                            className={classNames(
                                                                "form-control dark",
                                                                {
                                                                    "is-invalid": errors.telegram
                                                                }
                                                            )}
                                                            onChange={this.onChange}
                                                            value={kakaotalk}
                                                        />
                                                        <div className={classNames("invalid-tooltip",{"d-block": errors.telegram })}>
                                                            {t(String(errors.telegram).toLowerCase())}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div> 
                                        <div class="col-12 p-0">
                                            <h2 class="form-title">{t("update contact number")}</h2>
                                            <div class="row">
                                                <div class="col-6">
                                                    <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("verify old number")}</label>
                                                    <div class="input-group-prepend">
                                                        {/* <select class="form-control dark form-control-md col-2 noBorderRightTop pl-1" id="flag" disabled>
                                                            <option>{"+" + phoneCode}</option>
                                                        </select> */}
                                                        <input className="form-control dark form-control-md col-1 noBorderRightTop text-center" defaultValue={this.mobileNUmberPrefix} disabled />
                                                        <input type="text" class="form-control col dark form-control-md col noBorderLeftTop" placeholder="10xxxxxxxx" name="oldnumber"
                                                            onChange={this.onChange}
                                                            maxLength="11"
                                                        />
                                                        {errors.oldnumber ? <div className={classNames("invalid-tooltip",{"d-block": errors.oldnumber })}>
                                                            {t(String(errors.oldnumber[0]).toLowerCase())}
                                                        </div> : null}
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("new number")}</label>
                                                    <div class="input-group-prepend">
                                                        <input className="form-control dark form-control-md col-1 noBorderRightTop text-center" defaultValue={this.mobileNUmberPrefix} disabled />
                                                        <input type="text" class="form-control col dark form-control-md col noBorderLeftTop" placeholder="10xxxxxxxx" name="newnumber"
                                                            onChange={this.onChange}
                                                            maxLength="11"
                                                        />
                                                        <div className={classNames("invalid-tooltip",{"d-block": errors.newnumber })}>
                                                            {errors && errors.newnumber ? t(String(errors.newnumber[0]).toLowerCase()) : t("phone missing validation")}
                                                            {/* {errors && errors.newnumber && errors.newnumber[0] === "phone validation" ? t('phone validation') : " "} */}
                                                            {/* {errors && errors.newnumber && (errors.newnumber[0] === "phone format" || errors.newnumber[1] === "phone format") ? t('phone format') : " "} */}
                                                            {/* {errors && errors.newnumber && errors.newnumber[0] === "The number is blacklisted." ? t('the number is blacklisted.'): " "} */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                </div>
                                                <div class="col-6">
                                                    <div class="labelColor labelFontsize">
                                                        {ReactHtmlParser(t('please enter your mobile number in the following format'))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 p-0 mt-4">
                                            <div className="row align-items-center justify-content-end">
                                                <div class="col-auto d-flex flex-row-reverse">
                                                    <button type="submit" class="btn btn-secondary saveBtn" disabled={firstname === '' ? true: false}>{t("save")}</button>
                                                    <button class="btn btn-success cancelBtn mr-3" disabled={firstname === '' ? true: false } onClick={() => {
                                                        this.updateAccount();
                                                        this.setState({
                                                            errors:{},
                                                            oldnumber: '',
                                                            newnumber: '',
                                                            telegram: telegramsz,
                                                            email: this.props.player.Email
                                                        });
                                                    }}>
                                                        {t("cancel")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ) :
                    (
                        <React.Fragment>
                            {/* <h2 class="formTitle">{t("my account details")}</h2> */}
                            <div className="accountInfo accountInfo-w365 mb-5">
                                <div id="account-details">
                                    {this.state.success1 ? <SuccessAlert translationCode="player details success" /> : null}
                                    <div className="row m-0">
                                        <div className={`col-md-${firstname === '' ? '4' : '3'}`}>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("username")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{this.props.username}</p>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    {
                                                        firstname === "" ?
                                                        <React.Fragment>
                                                            <div class="col p-0">
                                                                <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("full name")} *</label>
                                                                <div class="input-group padLabel">
                                                                    <div class="input-group-prepend">
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className={classNames(
                                                                            "form-control dark",
                                                                            {
                                                                                "is-invalid": errors.name
                                                                            }
                                                                        )}
                                                                        name="name"
                                                                        id="name"
                                                                        placeholder={t("full name")}
                                                                        onChange={this.onChange}
                                                                        required
                                                                    />
                                                                    <Error errors={errors} id="name" />
                                                                </div>
                                                            </div>
                                                        </React.Fragment> 
                                                        :
                                                        <React.Fragment>
                                                            <div class="col-3 col-form-label labelColor">{t("full name")}</div>
                                                            <div class="col"> 
                                                                <p class="col text_display infoColor">{firstname}</p>
                                                            </div> 
                                                        </React.Fragment>
                                                    }
                                                </div> 
                                            </div>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    {
                                                        firstname === "" ?
                                                        <React.Fragment>
                                                            {this.props.player.BirthDate ? 
                                                                <div class="col p-0 pl-3">
                                                                    <div class="colLeftInfos">
                                                                        <div class="form-group row">
                                                                            <div class="col-3 col-form-label labelColor">{t("birthday")}</div>
                                                                            <div class="col"> 
                                                                                <p class="col text_display infoColor">{birthDate}</p>
                                                                            </div> 
                                                                        </div> 
                                                                    </div>
                                                                </div>
                                                                :
                                                                <>
                                                                <div class="col p-0">
                                                                    <label for="transfer-amount" class="col-form-label labelColor">{t("birthday")} *</label>
                                                                    <div class="input-group padLabel">
                                                                        <div class="input-group-prepend">
                                                                        </div>
                                                        
                                                                            {i18n.language === "en-US" ? (
                                                                                <DatePicker
                                                                                    className={classNames(
                                                                                        "form-control dark",
                                                                                        {
                                                                                            "is-invalid":
                                                                                                errors.birthday
                                                                                        }
                                                                                    )}
                                                                                    selected={this.state.birthday}
                                                                                    onChange={this.handleChange}
                                                                                    // onChangeRaw={
                                                                                    //     this.handleDateChangeRaw
                                                                                    // }
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    maxDate={this.state.maxDate}
                                                                                    placeholderText="yyyy-mm-dd"
                                                                                    id="birthday"
                                                                                    name="birthday"
                                                                                    required
                                                                                    autoComplete="off"
                                                                                />
                                                                                ) : (
                                                                                    <DatePicker
                                                                                    className={classNames(
                                                                                        "form-control dark",
                                                                                        {
                                                                                            "is-invalid":
                                                                                                errors.birthday
                                                                                        }
                                                                                    )}
                                                                                    selected={this.state.birthday}
                                                                                    onChange={this.handleChange}
                                                                                    // onChangeRaw={
                                                                                    //     this.handleDateChangeRaw
                                                                                    // }
                                                                                    showYearDropdown
                                                                                    showMonthDropdown
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    maxDate={this.state.maxDate}
                                                                                    placeholderText="yyyy-mm-dd"
                                                                                    id="birthday"
                                                                                    name="birthday"
                                                                                    required
                                                                                    autoComplete="off"
                                                                                    locale="ko"
                                                                                />
                                                                            )}
                                                                        
                                                                        <div
                                                                            className={classNames(
                                                                                "invalid-tooltip",
                                                                                {
                                                                                    "d-block":
                                                                                        this.state.birthday ===
                                                                                            "" &&
                                                                                        this.state.validated
                                                                                }
                                                                            )}
                                                                        >
                                                                            {t("this field is required")}
                                                                        </div>
                                                                        <div
                                                                            className={classNames(
                                                                                "invalid-tooltip",
                                                                                {
                                                                                    "d-block": errors.birthday
                                                                                }
                                                                            )}
                                                                        >
                                                                            {errors.birthday}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </>
                                                            }
                                                        </React.Fragment> 
                                                        :
                                                        <React.Fragment>
                                                            <div class="col-3 col-form-label labelColor">{t("birthday")}</div>
                                                            <div class="col"> 
                                                                <p class="col text_display infoColor">{birthDate}</p>
                                                            </div> 
                                                        </React.Fragment>
                                                    }
                                                </div> 
                                            </div>
                                            <div class="colLeftInfos">
                                                    {
                                                        firstname === "" ?
                                                        <div class="form-group row align-items-center">
                                                            <div class="col-3 col-form-label labelColor">{t("gender")} *</div>
                                                            <div class="col">
                                                                <div class="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gender"
                                                                        id="genderM"
                                                                        value="m"
                                                                        onChange={this.onChange}
                                                                        required
                                                                    />
                                                                    <label class="form-check-label labelColor" for="inlineCheckbox1">{t("male")}</label>
                                                                </div>
                                                                <div class="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="gender"
                                                                        id="genderF"
                                                                        value="f"
                                                                        onChange={this.onChange}
                                                                        required
                                                                    />
                                                                    <label class="form-check-label labelColor" for="inlineCheckbox2">{t("female")}</label>
                                                                </div>
                                                                <div
                                                                    className={classNames(
                                                                        "invalid-tooltip",
                                                                        {
                                                                            "d-block":
                                                                                this.state.gender === "" &&
                                                                                this.state.validated
                                                                        }
                                                                    )}
                                                                >
                                                                    {t("this field is required")}
                                                                </div>
                                                            </div>
                                                        </div> 
                                                        :
                                                        <div class="form-group row">
                                                            <div class="col-3 col-form-label labelColor">{t("gender")}</div>
                                                            <div class="col"> 
                                                                <p class="col text_display infoColor">{t(genderValue)}</p>
                                                            </div> 
                                                        </div>
                                                    }
                                            </div>
                                            <div class="colLeftInfos">
                                                <div class="form-group row">
                                                    <div class="col-3 col-form-label labelColor">{t("currency")}</div>
                                                    <div class="col"> 
                                                        <p class="col text_display infoColor">{currency}</p>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-md-4${firstname === '' ? ' space-left' : ''}`}>
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("country")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{country}</p>
                                                </div> 
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("date registered")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{this.state.dateRegistered}</p>
                                                </div> 
                                            </div>
                                            <div class="form-group row">
                                                {
                                                    firstname === "" ?
                                                    <React.Fragment>
                                                        <div class="col p-0">
                                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("email")} *</label>
                                                            <div class="input-group padLabel">
                                                                <div class="input-group-prepend">
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    id="email"
                                                                    className={classNames(
                                                                        "form-control dark",
                                                                        {
                                                                            "is-invalid": errors.email
                                                                        }
                                                                    )}
                                                                    placeholder={t("email")}
                                                                    onChange={this.onChange}
                                                                    required
                                                                />
                                                                <Error errors={errors} id="email" />
                                                            </div>
                                                        </div>
                                                    </React.Fragment> 
                                                        :
                                                    <React.Fragment>
                                                        <div class="col-3 col-form-label labelColor">{t("email")}</div>
                                                        <div class="col"> 
                                                            <p class="col text_display infoColor">{emailValue}</p>
                                                        </div> 
                                                    </React.Fragment>
                                                }
                                            </div> 
                                            <div class="form-group row">
                                                <div class="col-3 col-form-label labelColor">{t("contact number")}</div>
                                                <div class="col"> 
                                                    <p class="col text_display infoColor">{test}</p>
                                                </div> 
                                            </div> 
                                            <div class="form-group row">
                                                {
                                                    firstname === "" ?
                                                    <React.Fragment>
                                                        <div class="col p-0">
                                                            <label for="transfer-amount" class="col-form-label labelColor mb-1">{t("telegram")}</label>
                                                            <div class="input-group padLabel">
                                                                <div class="input-group-prepend">
                                                                </div>
                                                                <input
                                                                    type="text"
                                                                    name="telegram"
                                                                    id="telegram"
                                                                    className={classNames(
                                                                        "form-control dark",
                                                                        {
                                                                            "is-invalid": errors.telegram
                                                                        }
                                                                    )}
                                                                    onChange={this.onChange}
                                                                />
                                                                <div className={classNames("invalid-tooltip",{"d-block": errors.telegram })}>
                                                                    {t(String(errors.telegram).toLowerCase())}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment> 
                                                        :
                                                    <React.Fragment>
                                                        <div class="col-3 col-form-label labelColor">{t("telegram")}</div>
                                                        <div class="col"> 
                                                            <p class="col text_display infoColor">{telegramsz}</p>
                                                        </div> 
                                                    </React.Fragment>
                                                }
                                            </div> 
                                            <div class="contactBtn row align-items-center justify-content-end">
                                            </div>
                                        </div> 
                                        <div className="col-12 p-0 mt-4">
                                            <div className="row align-items-center justify-content-end">
                                                <div class="col-auto">
                                                {
                                                    firstname === "" ? 
                                                    <button type="submit" class="btn btn-secondary small-caps">{t("save")}</button>
                                                    : 
                                                    <div>
                                                        <button className="btn btn-secondary small-caps"
                                                        onClick={() => this.updateAccount()}>{t("update")}</button>
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                ) :
                (
                    <React.Fragment>
                        <div className="row mb-4" id="account-details">
                            {/* <div className="col-md-12">
                                <h2 className="formTitle">{t("my account details")}</h2>
                            </div> */}
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={120} height={30}  />
                                        </SkeletonTheme>
                                    </label>
                                    <div className="col-sm-8">
                                        <SkeletonTheme color="#ddd" highlightColor="#eaeaea">
                                            <Skeleton width={321} height={30}  />
                                        </SkeletonTheme>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
                
            }
            </form>
        )
    }
    
}

Profile.propTypes = {
    postPlayer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    player: state.auth.wallet.player,
    errors: state.player.errors,
    success: state.player.result,
    username: state.auth.wallet.username,
    currencies: state.register.currencies,
});

export default connect(
    mapStateToProps,
    { postPlayer, resetPlayerError, getCurrencies }
)(withTranslation()(withRouter(Profile)));
