import React, { useEffect, useState, useRef  } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Modal from "react-bootstrap/Modal";

const Notice = ({ show, handleClose, content }) => {
    const { t, i18n } = useTranslation();


    // console.log(content)
    // console.log('batman')
    return (
        <div
            style={{
                display: show && content !== '' ? "block" : "none"
            }}
            id="noticeModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="noticeModal"
            aria-hidden="true"
        >
            <Modal centered show={show}>
                <Modal.Header>
                    <p className="modal-title" id="noticeModal">
                        {t('notice title')}
                    </p>
                </Modal.Header>
                <Modal.Body className="mt-4 mb-5">
                    <div className="col text-left">
                        {ReactHtmlParser(content)}
                    </div>
                    
                        <div className="col text-center">
                            <button className="btn btn-success mt-1" style={{width: "250px"}} onClick={handleClose} >
                                 {t('agree')}
                            </button>
                        </div>
                    
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Notice;