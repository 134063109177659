import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useSelector } from 'react-redux';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    postBonus,
    getBonus,
    getBonusHistory,
    setPage,
    cancelSubscription,
    resetBonus,
    checkBonus
} from '../../../../actions/bonus';
import { Paginate, Loader } from '../../../common';
import { displayAmount } from '../../../../helpers';
import { player } from '../../../../selectors/auth';
import { getPlayer } from "../../../../actions/player";
import BtnLoader from '../../../common/BtnLoader';
import ReactHtmlParser from "react-html-parser";
import { selectProvider as selectProviderAction, } from "../../../../actions/modal";
import classnames from "classnames";
import { getProviders } from "../../../../actions/casino";
import ReactPaginate from "react-paginate";
import Providers from '../modals/Providers';

const Content = () => {
    const dispatch = useDispatch();
    const { bonuses, errors, success, cancel } = useSelector(state => state.bonuses);
    const bonus = useSelector(state => state.bonuses);
    const { currency: { Abbreviation } } = useSelector(player);
    const provz = useSelector(state => state.casino.providers);
    const products = useSelector((state) => state.products.result);
    const options = { ...bonuses.meta, ...bonuses.links }
    const { t } = useTranslation();
    const [mounted, setDidMount] = useState(false);
    const provider = useSelector(state => state.payment.provider);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cancelloading, setCancelLoading] = useState(false);
    const [subscription, setSubscription] = useState({Amount: 0});
    const [target, setTarget] = useState(0);
    const [showDropDown, setShowDropDown] = useState(false);
    const [isSubscribed,setIsSubscribed] = useState(false);
    const [providerId,setProviderId] = useState(false);
    const [bol,setBol] = useState(false);
    const [activatedId, setActivatedId] = useState(0);
    const [showModal, toggleModal] = useState(false);
    const [playerBonusList, setPlayerBonusList] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const wallet = useSelector(state => state.player.wallet);
    const playerInfo = useSelector(state => state.auth.wallet.player);
    const amount = wallet[provider.ID] || { balance: 0 };

    useEffect(() => {
        dispatch(getBonus());
        setDidMount(true);
        dispatch(selectProviderAction({ID: 21, Name: 'JOA스포츠', Logo: 'icon js'}))
        return (() => {
            dispatch(resetBonus());
            dispatch(getBonus());
            dispatch(getPlayer());
            dispatch(getBonusHistory());
        })
    }, []);

    const onClick = useCallback((params) => {
        
        // if (params.provider === 0) {
        //     setTarget(params.subscription)
        // } else {
            setActivatedId(params.subscription);
            setLoading(true);
            dispatch(postBonus({subscription: params.subscription, amount: params.bonusAmount}));
            setBol(true);
        // }
   });

    const proceedActivate = useCallback((params) => {
        setLoading(true);
        let productProvider = []
        products.map((product, k) => (
            product.Providers.map((provider,j) => {
              productProvider.push({provider:provider.ID,product:product.ID})
            })
        ))

       let product = productProvider.filter((val) => { return val.provider === params.provider })
       dispatch(postBonus({subscription: params.subscription, provider: params.provider, product: product[0].product}));
       setBol(true)
   });

    const onCancel = useCallback((subscription) => {
        setSubscription(subscription);
        dispatch(checkBonus({ provider: subscription.ProviderID }));
        setCancelLoading(true);
        setShow(true);
    });

    const resetSubscription = useCallback(() => {
        setShow(false);
        //setLoading(false);
        setCancelLoading(false);
        setSubscription({Amount: 0});
    });

    const handlePageClick = (e) => {
        dispatch(setPage({page: e.selected + 1}))
    }

    useEffect(() => {
        if (errors.hasOwnProperty('cancel') && mounted){
            const msg = errors.cancel.message;
            if(msg.includes('pending bets')){
                toast.error(t(msg.toLowerCase()), {
                    containerId: 'A',
                    className: "toast toast-error",
                    onClose: () => {
                        resetSubscription();
                    }
                });
            }else{
                toast.error(t("bonus failed message"),{
                    containerId: 'D',
                    className: "toast toast-error",
                    onClose: () => {
                        resetSubscription();
                    }
                });
            }
        }else if(cancel){
            toast.success(t('promotion success cancel'),{
                containerId: 'C',
                className: "toast toast-success"
            });
            dispatch(resetBonus());
            dispatch(getBonus());
            dispatch(getPlayer());
            dispatch(getBonusHistory());
            setShow(false);
            //setLoading(false);
        }

        if(!errors.success && errors.status == 400){
            if (errors.errors.player_suspended) {
                toast.error(t('please contact cs'),{
                    className: "toast toast-error"
                });
                setLoading(false)
            }
        }


        if(errors.hasOwnProperty('success')){
            setCancelLoading(false);
        }


        if (provz.length > 0 ) {
            let logo = "";
            switch(provider.ID) {
              case 21:
                logo = 'icon js';
                break;
              case 22:
                logo = 'icon es';
                break;
              case 23:
                logo = 'icon pb';
                break;
              default:
            }

            dispatch(selectProviderAction({ID: provz[0].ID, Name: provz[0].ProviderName, Logo: logo}))
            setIsSubscribed(provz[0].HasPendingBets.isSubscribed)
            setProviderId(provz[0].ID)
            dispatch(getProviders({transfer:true}));
        }
    }, [errors, cancel, success])

    useEffect(() => {
        if(success.message && success.status == 200){
            toast.success(t("bonus success message"), {
                containerId: 'B',
                className: "toast toast-success"
            });
            setShow(false);
            //setLoading(false);
        }
    }, [success]);


    useEffect(() => {
        // console.log(errors)
        // console.log(bol)
      // if (errors.errors !== undefined ) {
        if(!errors.success && bol && errors && errors.errors && !errors.errors.cashback){
          toast.error(t('bonus failed message'), {
              containerId: 'F',
              className: "toast toast-error"
          });
          setLoading(false)
        // }
      }

      if(errors.errors && errors.errors.ongoing_subscription) {
        toast.error(t('pending subscription'), {
            className: "toast toast-error"
        });
        setLoading(false)
      }

      if(errors.errors && errors.errors.cashback) {
        toast.error(t('pending subscription'), {
            className: "toast toast-error"
        });
        setLoading(false)
      }

    }, [errors]);


    useEffect(() => {
        setLoading(false);
    }, [bonuses]);

    const selectProvider = useCallback((provider) => {
        dispatch(selectProviderAction(provider));
        setShowDropDown(!showDropDown);
        setIsSubscribed(false);
        setProviderId(provider.ID);
    });


    const dropDown = useCallback((provider) => {
        setShowDropDown(!showDropDown);
    });

    const handleToggleModal = (provider) => {
        toggleModal(!showModal);
        setProviderList(provider);
    }

    const handleClose = () => {
        toggleModal(!showModal);
        setProviderList([]);
    }

    useEffect(() => {
        if(bonuses.data.length !== 0){
            bonuses.data.map( (promotion, i) =>  {  
                let products = [...new Map(promotion.Products.map(item =>
                  [item.Data['ID'], item])).values()];
                  bonuses.data[i].FilteredProduct = products;
              });
              setPlayerBonusList(bonuses.data)
        } else {
            setPlayerBonusList(bonuses.data)
        }
    },[bonuses])

    return (
        <React.Fragment>
        <div className=" mt-4">
            <Providers show={showModal} handleClose={handleClose} provider={providerList}/>
          <ToastContainer autoClose={8000} />
            <table className="table table-striped table-dark">
                <thead>
                    <tr>
                        <th scope="col">{t('date')}</th>
                        <th scope="col">{t('subscription id')}</th>
                        <th scope="col">{t('bonus')} / {t('coupon details')}</th>
                        {/* <th scope="col">{t('provider')}</th> */}
                        <th scope="col" className="txt-align-right">{t('amount')}</th>
                        <th scope="col">{t('status')}</th>
                        <th scope="col" width="30%">{t('goal')}</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        playerBonusList.map((bonus, key) => {
                            if(bonus.Status === 'Processing') {
                                bonus.Status = 'In Progress';
                            } else if(bonus.Status === 'Cancel') {
                                bonus.Status = 'Cancelled';
                            } else if(bonus.Status === 'Complete') {
                                bonus.Status = 'Claimed';
                            }


                            return (
                                 <React.Fragment key={key}>
                                    <tr>
                                        <td className="no-border">{bonus.Date}</td>
                                        <td className="no-border">{bonus.SubscriptionID}</td>
                                        <td className="no-border">
                                            <div>{bonus.Code}</div>
                                            <div className="subscription-type mr-2">{t(bonus.Type.toLowerCase())}</div>
                                            { bonus.MinimumOdds ? <div className="min-odds mr-2"><span>{t('min odds')+': '}</span><span className='value'>{bonus.MinimumOdds}</span></div> : <></>}
                                            { bonus.Type.toLowerCase() === "all in cashback" ? <></> : <div className="bonus-providers" onClick={() => handleToggleModal(bonus)}>{t('providers')+ReactHtmlParser('&nbsp;&nbsp;')+'>'}</div>}
                                        </td>
                                        {/* <td>{bonus.ProviderName}</td> */}
                                        <td className="txt-align-right no-border">{Abbreviation} {displayAmount(bonus.Amount)}</td>
                                        <td className="no-border">
                                            { bonus.Status === "Pending" ?
                                                loading && activatedId === bonus.ID ?
                                                <button className="btn btn-success btn-coupon " type="button" style={{ width: "87.03px"}}>
                                                    <Loader hide={!loading} style={{ width: "16px"}}/>
                                                </button>
                                                :
                                                playerInfo.Status === 5 || playerInfo.Status === 4 || playerInfo.Status === 2 ?
                                                <button className="btn btn-secondary btn-coupon " type="button"
                                                   disabled={playerInfo.Status === 5 || playerInfo.Status === 4 || playerInfo.Status === 2? true:false}>{t('activate')}
                                                </button>
                                                :
                                                <button className="btn btn-secondary btn-coupon " type="button"
                                                    onClick={ ()=>{ onClick({ subscription: bonus.ID,  bonusAmount: bonus.Amount }) }} disabled={playerInfo.Status === 5 || playerInfo.Status === 4 || playerInfo.Status === 2? true:false}>{t('activate')}
                                                </button>
                                            :
                                                <span className={classname("",{
                                                    "d-none": bonus.Status === "Pending",
                                                    "green": bonus.Status === "Claimed",
                                                    "red": bonus.Status === "Cancelled"
                                                })}>
                                                    {t(bonus.Status.toLowerCase())}
                                                </span>
                                            }
                                        </td>
                                        <td className="no-border">
                                            { bonus.Type.toLowerCase() === "all in cashback" ?
                                            <div style={{ width: '400px', padding: '5px', position: 'relative' }}>
                                                <img src="/assets/img/15bet/icons/info-rebate-icon.svg" style={{ width: '20px', position: 'absolute', top: '50%', marginTop: '-11px' }}/> 
                                                <span style={{
                                                    fontSize: '12px',
                                                    marginLeft: '30px',
                                                    display: 'block'
                                                }}>
                                                All in requirements will be issued on Cash tab <br/>upon cashback balance transfer
                                                </span>
                                            </div>
                                            :
                                            <div className="progress progress-striped active">
                                                <p className="raised">{t('current')}: {displayAmount(bonus.WageringAmount)}</p>
                                                <p className="goal">{t('goal')}: {displayAmount(bonus.WageringGoal)}</p>
                                                <div className="progress-bar barColor progress-bar progress-bar-animated" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: `${bonus.WageringAmount/bonus.WageringGoal*100}%`}}>
                                                    <span className="sr-only">0% {t('complete')}</span>
                                                </div>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                 </React.Fragment>
                            )
                        })
                    }
                    <tr className={classname("",{
                        "d-none": bonuses.data.length > 0
                    })}>
                        <td colSpan="12" className="text-center">
                            <h4>{t('no results found')}</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
            {/* <Paginate options={options} selectPage={(page) => {
                dispatch(setPage({page}));
            }
            }/> */}
            <div className="col-md-12 pagination-col mt-3">
                <div className="pagination-box">
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        initialPage={0}
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={3}
                        pageCount={bonuses.meta.last_page}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        containerClassName="pagination-w365"
                        pageLinkClassName="page-num"
                        previousLinkClassName="page-num"
                        nextLinkClassName="page-num"
                        activeLinkClassName="active pagination-a"
                    />
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default Content;
