import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'redux-react-hook';
import { useSelector } from 'react-redux';
import { getCash } from '../../../../actions/cash';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import { Paginate } from '../../../common';
import { displayAmount } from '../../../../helpers';
import { player } from '../../../../selectors/auth';
import { getPlayer } from "../../../../actions/player";
import ReactPaginate from "react-paginate";
import {
  setPage,
} from "../../../../actions/cash";


const Cash = () => {
    const dispatch = useDispatch();
    const { cashs, success } = useSelector(state => state.cashs);
    const { currency: { Abbreviation } } = useSelector(player);
    const options = { ...cashs.meta, ...cashs.links }
    const transactionTypes = ["Deposit","","","Rebate","Manual","All In Cashback"];

    useEffect(() => {
        dispatch(getCash());

        return () => {
            dispatch(setPage({page: 1}));
        }
    }, []);

    const handlePageClick = (e) => {
        dispatch(setPage({page: e.selected + 1}))
    }

    const onClick = useCallback((params) => {
        //dispatch(postBonus(params));
    });
    const { t } = useTranslation();

    return (
        <>
        <table className="table table-striped table-dark">
            <thead>
                <tr>
                    <th scope="col">{t('date')}</th>
                    <th scope="col" width="17%">Type</th>
                    <th scope="col">{t('reference id')}</th>
                    <th scope="col" className="txt-align-right">{t('amount')}</th>
                    <th scope="col" width="30%">{t('goal')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    cashs.data.map((cash, key) => {
                        if(cash.Status === 'Processing') {
                            cash.Status = 'In Progress';
                        } else if(cash.Status === 'Cancel') {
                            cash.Status = 'Cancelled';
                        } else if(cash.Status === 'Complete') {
                            cash.Status = 'Claimed';
                        }

                        return (
                            <React.Fragment key={key}>
                                <tr>
                                    <td className="no-border">{cash.DateCreated}</td>
                                    <td className="no-border">{transactionTypes[cash.Type - 1]}</td>
                                    <td className="no-border">{cash.ReferenceID === 'manual' ? t(cash.ReferenceID) : cash.ReferenceID}</td>
                                    <td className="txt-align-right no-border">{Abbreviation} {displayAmount(cash.GoalAmount)}</td>
                                    <td className="no-border">
                                        <div className="progress  active">
                                            <p className="raised"><strong>{t('current')}: {displayAmount(cash.TotalWagerAmount)}</strong></p>
                                            <p className="goal"><strong>{t('goal')}: {displayAmount(cash.GoalAmount)}</strong></p>
                                            <div className="rogress-bar  barColor progress-bar progress-bar-animated" role="progressbar" aria-valuenow={60} aria-valuemin={0} aria-valuemax={100} style={{width: `${cash.TotalWagerAmount/cash.GoalAmount*100}%`}}>
                                                <span className="sr-only">0% {t('complete')}</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        )
                    })
                }
                <tr className={classname("",{
                        "d-none": cashs.data.length > 0
                    })}>
                    <td colSpan="12" className="text-center">
                        <h4>{t('no results found')}</h4>
                    </td>
                </tr>
            </tbody>
        </table>
        {/* <Paginate options={options} selectPage={(page) => {
            dispatch(setPage({page}));
        }
        }/> */}
        <div className="col-md-12 pagination-col mt-3">
            <div className="pagination-box">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    initialPage={0}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={3}
                    pageCount={cashs.meta.last_page}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination-w365"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="active pagination-a"
                />
            </div>
        </div>
        </>
    );
};

export default Cash;
