import React, { useEffect, useState, useCallback  } from "react";
import { Row } from 'reactstrap';
import { withTranslation } from "react-i18next";
import Notice from "../../layouts/Header/pages/modals/powerball";
import { useDispatch } from "redux-react-hook";
import { useSelector } from "react-redux";
// import { getTemplateContent } from "../../../actions/page";
import { Link, useHistory } from "react-router-dom";
import { findToken } from "../../../config/api";
import allowedPokerDomain from "../Poker/PokerDomain";
import { getPlayer } from "../../../actions/player";
import { postPlay } from "../../../api/sports";
import { resetPoker } from "../../../actions/poker";
import { openPoker } from "../Poker/pokerHelper";

const buttons = [
	// {
	// 	translationCode: 'promotion text 1',
	// 	className: 'pr-2',
	// 	auto: true,
	// 	link: '/'
	// },
	{
		translationCode: 'promotion text 3',
		className: '',
		auto: false,
		link: '/'
	},
	{
		translationCode: 'promotion text 4',
		className: '',
		auto: false,
		link: '/'
	}
];

const icons = [
	{
		className: '',
		data: [
			{
				link: '/bti-sports',
				translationCode: 'joa sports',
				className: 'sports'
			},
			{
				link: '/bt1-sports',
				translationCode: 'bt1',
				className: 'bt1'
			},
			// {
			// 	link: '/live-sports',
			// 	translationCode: 'live betting',
			// 	className: 'betradar'
			// },
			{
				link: '/betconstruct',
				translationCode: 'betcon_home',
				className: 'bet-construct'
			},
			{
				link: '/live-casino',
				translationCode: 'live casino',
				className: 'live-casino'
			},
			{
				link: '/slots',
				translationCode: 'slot',
				className: 'slots'
			},
			// {
			// 	link: '/poker',
			// 	translationCode: 'live holdem',
			// 	className: 'poker'
			// },
			{
				link: '/promotions',
				translationCode: 'promos',
				className: 'promotion'
			},
			{
				link: '/vip',
				translationCode: 'vip club',
				className: 'vip'
			},
			{
				link: '/sports-betting-game-rules',
				translationCode: 'rules',
				className: 'rules'
			},
		]
	}
];

const Menu = props => {
	const { t,i18n } = props;
	const [show, setShow] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const language = localStorage.getItem('i18nextLng');
	const [isPokerAllowed, setIsPokerAllowed] = useState(false);
	const player = useSelector(state => state.auth.wallet);
         
	if (allowedPokerDomain.includes(window.location.hostname) && !isPokerAllowed) {
		setIsPokerAllowed(true);
	}

	const showNotice = () => {
        setShow(true)
    }

    const closeNotice = () => {
        setShow(false)
    }

	const checkShowLoginPopupHandler = useCallback((link) => {
		if (findToken() === null) {
			props.handleOpen();
		} else {
			if (link === '/poker') {
				openPoker(player);
			} else {
				window.location.href = link;
			}
		}
		// window.location.href = link;
	});

	let lang = '';

	if(language !== null) {
		lang = language.split("-")[0];
	} else {
		lang = 'ko';
	}

    const { content } = useSelector((state) => state.page);

    useEffect(() => {
        // dispatch(getTemplateContent({slug:'notice', lang: lang}));
		dispatch(getPlayer());
		dispatch(resetPoker());
    }, []);

    let text = '';
    if (content && content.content !== undefined) {
    	text = Object.keys(content).length > 0 || content.content !== '' ? content.content : '';
    }

    return (
        <div className="slider-menus-box">
            <div className="home-menus">
				{icons.map((row, index) => <Row className={row.className + 'm-0'} key={`row#${index}`}>{row.data.map(icon => {
					return (
						<div className={icon.translationCode === 'poker' ? isPokerAllowed ? "col-auto" : "" : "col-auto"} key={icon.translationCode}>
							{
								// icon.translationCode === 'powerball' ?

								// <a onClick={() => showNotice() }>
								// 	<div className={`icon ${icon.className}`}></div>
								// 	<div className="text">{t(icon.translationCode)}</div>
								// </a> :

								icon.className === 'poker' ?
									// isPokerAllowed ?
										<Link href="" onClick={() => checkShowLoginPopupHandler(icon.link)} >
											<div className={`icon ${icon.className}`}></div>
											<div className="text">{t(icon.translationCode)}</div>
										</Link>
									// : <></> 
								: 
								<Link href="" onClick={() => checkShowLoginPopupHandler(icon.link)}>
									<div className={`icon ${icon.className}`}></div>
									<div className="text">{t(icon.translationCode)}</div>
								</Link>
								
							}
						</div>
					);
				})}</Row>)}
            </div>
            <Notice  show={show} handleClose={closeNotice} content={text} />
        </div>
    );
}

export default withTranslation()(Menu);