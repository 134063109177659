import React from 'react';
import { useSelector } from 'react-redux';
import classname from 'classnames';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import { player } from '../../../../selectors/auth';
import { displayAmount, realAmount } from '../../../../helpers';
import BtnLoader from '../../../common/BtnLoader';
import SuccessAlert from "../../../common/SuccessAlert";
import DangerAlert from "../../../common/DangerAlert";


const ConfirmWithdrawal = ({show, amountReceived, bankName, accountNumber, bankAccountName, handleClose, onSubmit, success, errors, propsSuccess, submitDisabled, isBlacklisted, isSuspended, isClosedAccount, errorStatusCode }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div
                style={{
                    display: show ? "block" : "none"
                }}
                id="DepositModal" 
                tabIndex={-1} 
                role="dialog" 
                aria-labelledby="DepositModal" 
                aria-modal="true">
                <Modal
                    centered
                    show={show}
                    onHide={handleClose}
                    
                className={classname("modal-confirm",{
                })}
                    >
                    <Modal.Header>
                        <h5 className="modal-title" id="LoginModal">
                            {
                                success ? 
                                    t('withdrawal sent')
                                : errors || isBlacklisted ?
                                    t('withdrawal request failed')
                                :
                                    t('check the withdrawal information')
                            }
                        </h5>
                        <button type="button" className="close" onClick={() => handleClose()} data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <div className='row'>
                        {
                            success ?

                                <div className="col-12 accountInfo  mb-12" id="deposit-message">
                                    <div className="depSentContent">
                                        <div className="depSent">
                                            <SuccessAlert translationCode="withdrawal sent" />
                                        </div>

                                        <div className="p-1 col-12">
                                            <div className='row'>
                                                <div className="mb-2 col-4 colgray" style={{fontWeight:'bold',textTransform: 'uppercase'}} >{t('transaction id')}:</div> 
                                                <div className="mb-2" style={{fontWeight:'700'}}>{propsSuccess.TransactionID}</div> 
                                            </div>

                                            <div className='row' style={{marginTop:'20px'}}>
                                                <div className="mb-2 col-12" >{t('withdrawal sent description')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : errors || isBlacklisted || isSuspended || isClosedAccount ?
                            <div class="col-md-12 accountInfo mb-12" id="deposit-message">
                            <div class="depSentContent">
                                {/* <DangerAlert translationCode='withdrawal request failed' /> */}
                                <div className="p-1 col-12">
                                    <div id='containerForm'>
                                        <div className='row' style={{marginTop:'0px'}}>
                                            <div className="mb-2 col-12" >{t('withdrawal request was unsuccessful')}</div> 
                                        </div>
                                        <div className='row' style={{marginTop:'7px'}}>
                                            <div className="mb-2 col-3 colgray" style={{fontWeight:'bold'}} >{t('error code')}:</div> 
                                            <div className="mb-2 ">{errorStatusCode}</div> 
                                        </div>
                                        <h3 class="requestFailedTitle" style={{textAlign:'center'}}>{t('need help')}</h3>
                                        <p class="requestFailedSub"><span >{t('our cs support is available 24 hours')}</span></p>
                                    </div>
                                </div>
                                
                                <div>
                                    <div class="row no-gutters" style={{marginTop:'30px'}}>
                                        <div class="col-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='iconxxx' >
                                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'23px'}}>
                                                        <path d="M34.8 22.8C34.5 22 33.6 21.7 32.9 22C32.1 22.3 31.8 23.2 32.1 23.9L33.9 28.2L28.7 25.9C28.3 25.7 27.9 25.7 27.5 25.9C25.7 26.7 23.8 27 21.9 27C14 27 8.6 20.8 8.6 15C8.6 8.4 14.6 3 21.9 3C29.2 3 35 8.4 35 15C35 15.5 35 16.1 34.9 16.7C34.8 17.5 35.3 18.3 36.1 18.4C36.9 18.5 37.7 18 37.8 17.2C37.9 16.5 38 15.7 38 15.1C38 11.1 36.3 7.3 33.2 4.4C30.1 1.5 26.1 0 21.7 0C17.3 0 13.3 1.5 10.3 4.3C7.5 6.8 5.9 10.1 5.5 13.7C2.1 16 0 19.7 0 23.7C0 26.2 0.8 28.5 2.3 30.6L0.1 36C-0.1 36.6 0 37.2 0.4 37.6C0.7 37.9 1.1 38 1.4 38C1.6 38 1.8 38 2 37.9L8.2 35.1C9.8 35.7 11.4 36 13.2 36H13.3C15.8 36 18.2 35.3 20.3 34.1C22.2 33 23.7 31.5 24.8 29.7C25.9 29.5 26.9 29.2 27.9 28.8L35.9 32.3C36.1 32.4 36.3 32.4 36.5 32.4C36.9 32.4 37.2 32.3 37.5 32C37.9 31.6 38.1 30.9 37.8 30.4L34.8 22.8ZM13.3 33C11.7 33 10.3 32.7 8.9 32.1C8.5 31.9 8.1 31.9 7.7 32.1L4.2 33.6L5.3 30.8C5.5 30.3 5.4 29.7 5.1 29.3C3.7 27.8 3 25.8 3 23.7C3 21.3 4 19.1 5.7 17.4C6.2 20.4 7.8 23.2 10.1 25.4C13 28.2 16.8 29.8 20.9 30C19 31.9 16.2 33 13.3 33Z" fill="#33C16C"/>
                                                        <path d="M21.7 16.6C22.5 16.6 23.2 15.9 23.2 15.1C23.2 14.3 22.5 13.6 21.7 13.6C20.9 13.6 20.2 14.3 20.2 15.1C20.2 15.9 20.9 16.6 21.7 16.6Z" fill="#33C16C"/>
                                                        <path d="M27.6 16.6C28.4 16.6 29.1 15.9 29.1 15.1C29.1 14.3 28.4 13.6 27.6 13.6C26.8 13.6 26.1 14.3 26.1 15.1C26.1 15.9 26.8 16.6 27.6 16.6Z" fill="#33C16C"/>
                                                        <path d="M15.7 16.6C16.5 16.6 17.2 15.9 17.2 15.1C17.2 14.3 16.5 13.6 15.7 13.6C14.9 13.6 14.2 14.3 14.2 15.1C14.3 15.9 14.9 16.6 15.7 16.6Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel'>{t('live chat')}</p>
                                                <p style={{fontSize:'10px'}}>{t("cs support")}</p>
                                                <a href="#chat" target="_blank"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='iconxxx'>
                                                    <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'23px'}}>
                                                        <path d="M16.383 14.3463L15.1821 17.7143H17.5758L16.383 14.3463Z" fill="#33C16C"/>
                                                        <path d="M20.7729 0C9.29913 0 0 7.2703 0 16.2327C0 22.0295 3.88681 27.1139 9.73731 29.988C7.48961 37.6712 7.43281 37.7117 7.81419 37.9303C8.29294 38.2055 8.90964 37.9222 17.089 32.2144C18.2899 32.3844 19.5233 32.4735 20.781 32.4735C32.2548 32.4735 41.554 25.2032 41.554 16.2408C41.554 7.2784 32.2467 0 20.7729 0ZM10.4838 20.7665C10.4838 21.3899 9.94828 21.9 9.2829 21.9C8.61752 21.9 8.08196 21.3899 8.08196 20.7665V13.7067H6.21565C5.56649 13.7067 5.03906 13.1886 5.03906 12.5409C5.03906 11.8932 5.56649 11.375 6.21565 11.375H12.3502C12.9993 11.375 13.5267 11.8932 13.5267 12.5409C13.5267 13.1886 12.9993 13.7067 12.3502 13.7067H10.4838V20.7665ZM19.7992 21.8838C18.5496 21.8838 18.7849 20.9041 18.2088 19.8193H14.5573C13.9893 20.896 14.2084 21.8838 12.9668 21.8838C11.7659 21.8838 11.5549 21.1551 11.9201 20.0379L14.7845 12.5571C14.9873 11.9903 15.5959 11.3993 16.383 11.3831C17.162 11.3993 17.7787 11.9822 17.9816 12.5571C19.8641 18.4105 22.5662 21.8919 19.7992 21.8838ZM26.6153 21.7299H22.7691C20.7648 21.7299 21.982 19.0258 21.6249 12.5733C21.6249 11.9175 22.1767 11.3831 22.8502 11.3831C23.5237 11.3831 24.0755 11.9175 24.0755 12.5733V19.5521H26.6153C27.2482 19.5521 27.7594 20.0379 27.7594 20.637C27.7594 21.2361 27.2482 21.7299 26.6153 21.7299ZM35.9956 20.8313C35.955 21.147 35.7846 21.4223 35.5331 21.6166C33.8777 22.8634 32.6605 19.2283 31.0458 17.6981L30.6319 18.111V20.7017C30.6319 21.3575 30.0964 21.8919 29.431 21.8919C28.7737 21.8919 28.2301 21.3575 28.2301 20.7017V12.5733C28.2301 11.9175 28.7656 11.3831 29.431 11.3831C30.0964 11.3831 30.6319 11.9175 30.6319 12.5733V15.1235C32.8391 13.4719 34.0643 10.5087 35.4681 11.8932C36.8395 13.2533 33.9994 14.4354 32.8228 16.0789C35.5899 19.9002 36.1173 19.9974 35.9956 20.8313Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel'>{t("kakao talk")}</p>
                                                <p style={{fontSize:'10px'}}>{t('kakao')}</p>
                                                <a href="#chat"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='iconxxx'>
                                                    <svg width="38" height="33" viewBox="0 0 38 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'21px',marginTop:'5px'}}>
                                                        <path d="M36.9943 0H2.00571C0.891429 0 0 0.900433 0 2.02597V23.974C0 25.0996 0.891429 26 2.00571 26H36.9943C38.1086 26 39 25.0996 39 23.974V2.02597C39 0.900433 38.1086 0 36.9943 0ZM36.2143 1.4632L20.6143 13.0563C20.28 13.2814 19.9457 13.3939 19.5 13.3939C19.0543 13.3939 18.6086 13.2814 18.3857 13.0563L2.78571 1.4632H36.2143ZM27.8571 13.9567L36.3257 24.4242H2.56286L11.0314 13.9567C11.2543 13.619 11.2543 13.1688 10.92 12.9437C10.5857 12.7186 10.14 12.7186 9.91714 13.0563L1.44857 23.4113V2.47619L17.3829 14.4069C17.94 14.8571 18.72 15.0823 19.3886 15.0823C20.0571 15.0823 20.8371 14.8571 21.3943 14.4069L37.44 2.47619V23.4113L28.9714 13.0563C28.7486 12.7186 28.1914 12.7186 27.9686 12.9437C27.7457 13.1688 27.6343 13.619 27.8571 13.9567Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel'>{t("email")}</p>
                                                <p style={{fontSize:'10px'}}>{t('cs support email')}</p>
                                                <a href="mailto:cskr@w365.com" target="_blank"></a>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div style={{textAlign:'center'}}>
                                                <div className='iconxxx'>
                                                    <svg width="31" height="38" viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width:'21px',marginTop:'5px'}}>
                                                        <path d="M0.871997 13.0452C0.308856 11.6425 -0.113499 10.0995 0.0272862 8.55656C0.168071 7.57466 0.449641 6.73303 1.15357 6.03167C1.99828 5.33032 2.7022 4.48869 3.40613 3.78733C4.53241 2.80543 5.79947 2.80543 6.78497 3.78733C7.4889 4.34842 8.05204 4.9095 8.61518 5.61086C9.3191 6.17195 9.88224 6.73303 10.4454 7.43439C11.4309 8.55656 11.5717 9.67873 10.4454 10.8009C9.74146 11.5023 8.89675 12.2036 8.19282 12.905C8.05204 13.1855 7.91125 13.3258 8.05204 13.6063C8.61518 14.8688 9.3191 15.8507 10.1638 16.8326C11.8532 18.7964 13.6834 20.6199 15.936 22.0226C16.3584 22.3032 16.9215 22.5837 17.4846 22.8643C17.7662 23.0045 17.907 23.0045 18.1886 22.724C18.8925 22.0226 19.7372 21.181 20.4411 20.4796C21.4266 19.4977 22.6937 19.4977 23.6792 20.4796C24.9462 21.7421 26.0725 22.8643 27.3396 24.1267C28.3251 25.1086 28.3251 26.371 27.3396 27.4932C26.6357 28.1946 25.9317 28.8959 25.2278 29.5973C24.2423 30.7195 23.116 31 21.7082 31C19.7372 30.8597 17.907 30.2986 16.0768 29.3167C12.1348 27.3529 8.75596 24.6878 5.94026 21.4615C3.6877 18.7964 1.99828 16.1312 0.871997 13.0452ZM31 15.2896C31 6.8733 24.1015 0 15.6544 0V2.9457C22.5529 2.9457 28.1843 8.55656 28.1843 15.2896H31ZM22.5529 15.2896H25.5094C25.5094 9.819 21.0043 5.47059 15.6544 5.47059V8.41629C17.4846 8.41629 19.3148 9.11765 20.5819 10.3801C21.849 11.7828 22.5529 13.4661 22.5529 15.2896Z" fill="#33C16C"/>
                                                    </svg>
                                                </div>
                                                <br/>
                                                <p className='iconLabel'>{t("hotline")}</p>
                                                <p style={{fontSize:'10px'}}>{t('hotline number')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                            :
                            <div className="col-12">
                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('amount received')}:</div> 
                                    <div className="mb-2 col-4" >{displayAmount(amountReceived)}</div> 
                                </div>

                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('bank name')}:</div> 
                                    <div className="mb-2 col-4" >{bankName}</div> 
                                </div>

                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('account name')}:</div> 
                                    <div className="mb-2 col-4" >{bankAccountName}</div> 
                                </div>
                                <div className='row'>
                                    <div className="mb-2 col-4 colgray" style={{textTransform:'uppercase'}}>{t('account number')}:</div> 
                                    <div className="mb-2 col-4" >{accountNumber}</div> 
                                </div>
                            </div>
                        }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={handleClose} className={classname("btn btn-success")}>
                            {
                                success || (errors || isBlacklisted || isSuspended || isClosedAccount) ? 
                                    t('close')
                                : t('cancel')
                            }
                        </button>
                        {
                            success || (errors || isBlacklisted || isSuspended || isClosedAccount) ? '' : <button onClick={onSubmit} disabled={submitDisabled} className={classname("btn btn-secondary btn-loader")}>{ t('confirm') }</button>

                        }
                    </Modal.Footer>
                </Modal>
            </div>
        </React.Fragment>
    );
}

export default ConfirmWithdrawal;
