import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import { getPromotions, getPromotionContentCategory } from "../../../actions/promotion";

import PromotionModal from "./modal/Promotion";

const Promotion = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const { promotionCategory, loadingCategory, promotions } = useSelector((state) => state.promotions);
    const { slug } = useParams();
    const [category, setCategory] = useState(1)
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [promos, setPromos] = useState([]);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [show, toggleShow] = useState(false);
    const [promoNotFound, setPromoNotFound] = useState(false);

    const Nav = ({ text, path }) => {
        return (
            <div className="d-flex align-items-center justify-content-center nav">
                <Link className={classNames({active: path === category})} onClick={() => setCategory(path)}>
                    {t(text.toLowerCase())}
                </Link>
            </div>
        );
    }

    const NotFound = () => {
        return (
            <div className="container-fluid not-found">
                <div className="not-found-text">
                    <h1 className="display-4">
                        <span className="text-danger">404</span> Page Not Found
                    </h1>
                    <p className="lead"> Sorry, that page does not exist </p>
                </div>
            </div>
        );
    }

    const handleClose = () => {
        toggleShow(false);
        setSelectedPromo(null);
        history.push('/promotions')
    }

    const handleOpen = (promotion) => {
        toggleShow(true);
        setSelectedPromo(promotion);
    }

    useEffect(() => {
        // if(localStorage.getItem('appToken') === null) {
        //     history.push('/');
        // } else {
        dispatch(getPromotionContentCategory());
        dispatch(getPromotions({}));
        // }
    }, []);

    useEffect(() => {
        if(promotionCategory.length > 0) {
            let x = 0;
            let categoriesOption = [];
        
            for(x in promotionCategory) {
                categoriesOption[x] = {name: promotionCategory[x].Category, path: promotionCategory[x].ID};
            }
            setCategories(categoriesOption);
        }
    }, [promotionCategory]);

    useEffect(() => {
        if(promotionCategory.length > 0) {
            const selected = promotionCategory.filter((data) => {
                return data.ID === parseInt(category);
            });

            setSelectedCategory(selected);
        }
    }, [promotionCategory, category]);

    useEffect(() => {
        if(slug) {
            if(promotions.length !== 0){
                const openPromo = promotions.filter(data => data.Slug === slug)
                if(openPromo.length !== 0) {
                    setPromoNotFound(false);
                    handleOpen(openPromo[0]);
                } else {
                    setPromoNotFound(true);
                }
            }
        }
    }, [promotions])
    
    useEffect(() => {
        let filteredPromos = promotions.filter((data) => {
            return data.categories.find(x => x.CategoryID === parseInt(category));
        })

        if (filteredPromos.length > 0) {
            filteredPromos.sort((a, b) => a.SortOrder - b.SortOrder)
        }
   
        setPromos(filteredPromos);
    },[category, promotions]);

    return (
        <React.Fragment>
        {
            !promoNotFound ?
            <div className="promotions-container">
                <div className="container-fluid" style={{paddingTop: '0'}}>
                    <div className="d-flex justify-content-between promotions-nav">
                        {categories.map(route => <Nav text={route.name} path={route.path}/>)}
                    </div>
                    {
                        !loadingCategory && promos.length !== 0 && selectedCategory.length !== 0 ?
                        <div className="promotions-content">
                            <h2 className="form-title">{selectedCategory[0].Category === 'All' ? t('all promotions') : t(selectedCategory[0].Category.toLowerCase() + ' ' + 'promotions')} </h2>
                            <div className="row">
                                {
                                    promos.map((data) => {
                                        return (
                                            <div className="col-4 pb-4">
                                                <div className="content-box p-3">
                                                    <img src={process.env.REACT_APP_BO_STORAGE + data.ThumbnailPath}/>
                                                    <p className="title">{data.Title}</p>
                                                    <p className="short-description">{data.ShortDescription}</p>
                                                    <div className="button-container">
                                                        <button className="btn btn-secondary btn-sm" onClick={() => handleOpen(data)}>{t('details')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        : !loadingCategory && promos.length === 0 && selectedCategory.length !== 0?
                        <div>
                            <h2 className="form-title">{selectedCategory[0].Category === 'All' ? t('promotions') : t(selectedCategory[0].Category.toLowerCase() + ' ' + 'promotions')} </h2>
                            <div className="row">
                                <div className="col-12">
                                    <div className="no-promotion-container justify-content-center align-items-center">
                                        <img src="/assets/img/w365/promotions/no-promo-icon.svg"></img>
                                        <p>{t('no new promotions at this time')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }
                    <div className="general-tnc-container">
                        <h2 className="form-title">{t('general terms and conditions')}</h2>
                        {ReactHtmlParser(t('promotion gtnc'))}
                    </div>
                </div>
                {
                    !loadingCategory && promos.length !== 0 && selectedCategory.length !== 0 ?
                    <PromotionModal show={show} handleClose={handleClose} promotion={selectedPromo} selectedCategory={selectedCategory}/>
                    :<></>
                }
            </div>
            : <NotFound />
        }
        </React.Fragment>
    );
}

export default Promotion;
