import { takeLatest, put, call, select } from 'redux-saga/effects';
import { realAmount, realFloatAmount } from '../helpers';
import {
    getCashbackBalance,
    postCashbackBalance,
} from "../api/cashback"
import {
    CASHBACK_GET,
    CASHBACK_POST,
    CASHBACK_SUCCESS,
    CASHBACK_ERRORS,
} from "../actions/types";

import { player } from "../selectors/auth";
import { auth } from "../selectors/auth";
import playerState from "../selectors/player";

export { cashbackWatcher };

const getItems = state => state.cashback;

function* cashbackWatcher() {
    yield takeLatest(CASHBACK_GET, fetchCashback)
    // yield takeLatest(CASHBACK_POST, fetchPostcashback)
}

function* fetchCashback() {
    try {
        const result = yield call(getCashbackBalance);
        yield put({
            type: CASHBACK_SUCCESS,
            payload: result.data,
        });
    }catch(error){
        yield put({
            type: CASHBACK_ERRORS,
            payload: error.response.data
        });
    }
}

// function* fetchPostcashback(action) {

//     const { currency: { Abbreviation } } = yield select(player);
//     const { wallet: { username }} = yield select(auth);
//     const _player = yield select(playerState);

//     const params = {
//         amount: action.payload.amount,
//         brand: action.payload.brand,
//         provider: action.payload.provider,
//         username,
//         currency:Abbreviation
//     }

//     try {
//         const result = yield call(postCashbackBalance, params);
//         yield put({
//             type: CASHBACK_POST_SUCCESS,
//             payload: result.data
//         });
//         yield put({
//             type: WALLET_SUCCESS,
//             payload: {
//                 [action.payload.provider] :
//                 {
//                     Status: _player.wallet[action.payload.provider].Status,
//                     UnderMaintenance: _player.wallet[action.payload.provider].UnderMaintenance,
//                     balance: realAmount(_player.wallet[action.payload.provider].balance) ,
//                     currencyCode: _player.wallet[action.payload.provider].currencyCode,
//                     hasError: _player.wallet[action.payload.provider].hasError,
//                     status: _player.wallet[action.payload.provider].status,
//                     success: _player.wallet[action.payload.provider].success
//                 }
//             },
//             payload2: {
//                 [action.payload.provider]: realFloatAmount(_player.wallet[action.payload.provider].balance) 
//             }
//         });
//     }catch(error){
//         yield put({
//             type: CASHBACK_POST_ERRORS,
//             payload: error.response.data
//         });
//     }
// }
