import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "redux-react-hook";
import { useTranslation } from "react-i18next";

import { getPopupNotice } from "../../../../actions/landingpage";
import Main from './main';
import Center from './center';
import Left from './left';
import Right from './right';

const Popup = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { language } = i18n;
    const { popupNotice } = useSelector(state => state.landingpage);
    const [mainContent, setMainContent] = useState([]);
    const [leftContent, setLeftContentt] = useState([]);
    const [centerContent, setCenterContent] = useState([]);
    const [rightContent, setRightContent] = useState([]);
    const [showSecondaryOverlay, setShowSecondaryOverlay] = useState(true);
    const [showMain, setShowMain] = useState(true);
    const [showLeft, setShowLeft] = useState(true);
    const [showCenter, setShowCenter] = useState(true);
    const [showRight, setShowRight] = useState(true);
    const [lang, setLang] = useState("ko")

    useEffect(() => {
        if(language !== undefined) {
            if(popupNotice.length === 0){
                const x = language.split("-")[0];
                dispatch(getPopupNotice(x));
            }
        }
    }, [language]);

    // useEffect(() => {
    //     dispatch(getPopupNotice());
    // }, []);

    useEffect(() => {
        if(popupNotice.length !== 0) {
            let holder = popupNotice;
            if(localStorage.getItem("appToken")){
                holder = popupNotice.filter((popup) => {
                    return popup.Display === 2;
                });
            } else {
                holder = popupNotice.filter((popup) => {
                    return popup.Display === 1;
                })
            }
            setMainContent(holder.filter((popup) => {
                let x = [];
                if(x.length === 0) {
                    if(popup.ParentID === 0) {
                        return popup;
                    }
                }
            }));
            setLeftContentt(holder.filter((popup) => {
                let x = [];
                if(x.length === 0) {
                    if(popup.Order === 0) {
                        return popup;
                    }
                }
            }));
            setCenterContent(holder.filter((popup) => {
                let x = [];
                if(x.length === 0) {
                    if(popup.Order === 1) {
                        return popup;
                    }
                }
            }));
            setRightContent(holder.filter((popup) => {
                let x = [];
                if(x.length === 0) {
                    if(popup.Order === 2) {
                        return popup;
                    }
                }
            }));
        }
    }, [popupNotice]);

    useEffect(() => {
        if(mainContent.length === 0) {
            setShowMain(false)
        } else {
            setShowMain(true)
        }
    }, [mainContent]);

    useEffect(() => {
        if(rightContent.length === 0) {
            setShowRight(false)
        } else {
            setShowRight(true)
        }
    }, [rightContent]);

    useEffect(() => {
        if(centerContent.length === 0) {
            setShowCenter(false)
        } else {
            setShowCenter(true)
        }
    }, [centerContent]);

    useEffect(() => {
        if(leftContent.length === 0) {
            setShowLeft(false)
        } else {
            setShowLeft(true)
        }
    }, [leftContent]);

    useEffect(() => {
        if(showLeft + showCenter + showRight === 0) {
            setShowSecondaryOverlay(false);
        } else {
            setShowSecondaryOverlay(true);
        }
    }, [showLeft, showCenter, showRight]);

    const closeLeft = () => {
        setShowLeft(false)
    }

    const closeCenter = () => {
        setShowCenter(false)
    }

    const closeRight = () => {
        setShowRight(false)
    }

    const closeMain = () => {
        setShowMain(false)
    }

    return (

        <React.Fragment>
            <div className='popup-display'>
                <div className='secondary-popup-overlay' style={{display: showSecondaryOverlay ? 'block' : 'none'}}>
                    <div className='secondary-popup-display'>
                        <div className='container'>
                            <div className='get-length modal-dialog modal-dialog-centered row align-items-center justify-content-center'>
                                { leftContent.length === 0 ? <></> : <Left content={leftContent} show={showLeft} closePopup={closeLeft}/> }
                                { centerContent.length === 0 ? <></> : <Center content={centerContent} show={showCenter} closePopup={closeCenter} /> }
                                { rightContent.length === 0 ? <></> : <Right content={rightContent} show={showRight} closePopup={closeRight} /> }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='main-popup-overlay' style={{display: showMain ? 'block' : 'none'}}>
                    <div className='main-popup-display'>
                        { mainContent.length === 0 ? <></> : <Main content={mainContent} closePopup={closeMain}/> }
                    </div>
                </div>
            </div>
            :
            <React.Fragment>
            {/* {!isItBday ?
                <div className='popup-display'>
                    <div className='main-popup-overlay' style={{display: showCountdown ? 'block' : 'none'}}>
                        <div className='countdown-popup-display'>
                            <CountDown content={mainContent} closePopup={closeCountdown} isItBday={isItBday} currentYear={currentYear} month={month} day={day}/>
                        </div>
                    </div>
                </div>
            :
            <></>
            } */}

            </React.Fragment>
        </React.Fragment>
    )
}

export default Popup;