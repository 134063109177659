import { OPEN_POKER, OPENING_POKER_SUCCESS, OPENING_POKER_ERROR, RESET_POKER } from './types';
  
export const openPoker = (isLogin) => ({
    type: OPEN_POKER,
    isLogin
});
  
export const openingPokerSuccess = (url) => ({
    type: OPENING_POKER_SUCCESS,
    url
});
  
export const openingPokerError = response => ({
    type: OPENING_POKER_ERROR,
    sucess: false,
    errors: response,
    loading: false
});

export const resetPoker = () => ({
    type: RESET_POKER
});
