import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { OPEN_POKER } from '../actions/types';
import { openingPokerSuccess, openingPokerError } from '../actions/poker';
import { findToken } from '../config/api';
import { postSports, postPlay } from "../api/sports";

function* openPoker() {

    try {
        let params = {};
        let result;
        const providerID = 40;
        const integrationID = 58;
        const productID = 12;
        const language = localStorage.getItem('i18nextLng') === null ? 'ko' : localStorage.getItem('i18nextLng').slice(0, 2);

        if (findToken()) {
            const playerState = yield select(state => state.auth.wallet);

            params = {
                brandID: 27,
                gameID: 0,
                isMobile: false,
                gameType: 'live',
                launchType: 'html5',
                hashid: '',
                lang: language,
                currency: playerState.player.currency.Abbreviation,
                mode: 1,
                launchType: 'html5',
                playerID: playerState.player.PlayerID,
                username: playerState.username,
                productID: productID,
                providerID: providerID,
                integrationID: integrationID
            };

            result = yield call(postPlay, params);
        } else {
            params = {
                IsLive: 0,
                gameID: 0,
                isMobile: false,
                mode: 0,
                lang: language,
                gameType: 'live',
                integrationID: integrationID,
                providerID: providerID,
                productID: productID,
                domain: window.location.hostname === 'localhost' ? 'frontend-test.15gaming.com' : window.location.hostname
            };

            result = yield call(postSports, params);
        }

        yield put(openingPokerSuccess(result.data.game_url));
    } catch(err) {
        yield put(openingPokerError(err.response.data.errors));
    }
}

function* pokerWatcher() {
    yield all([takeLatest(OPEN_POKER, openPoker)]);
}

export { pokerWatcher };
